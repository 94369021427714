import styled from '@emotion/styled'
import { TableCell, tableCellClasses, TablePagination, TableRow } from '@mui/material'
import { CONSTANTS } from '../../../js/res/CONSTANTS'

export const StyledTableCell = styled(TableCell)(() => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: CONSTANTS.Style.NewOrangeClinicorp,
    color: CONSTANTS.Style.White,
    wordBreak: 'initial',
    padding: 0,
    height: '40px',
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    fontSize: 14,
    color: CONSTANTS.Style.White,
    wordBreak: 'break-all',
    borderBottom: '1px solid #333333',
  },
}))

export const StyledTableRow = styled(TableRow)(() => ({
  border: 0,
  '&:nth-of-type(odd)': {
    // backgroundColor: CONSTANTS.Style.GreyBlackColor,
    borderBottom: '2px solid #fff',
    // backdropFilter: ' blur(10px)'
  },
  '&:nth-of-type(even)': {
    // backgroundColor: CONSTANTS.Style.GreyColor,
    border: 0,
    // backdropFilter: ' blur(10px)'
  },
  '&:hover': {
    backgroundColor: CONSTANTS.Style.SelectGrayColor,
    border: 0,
    boxShadow: 'inset 0 10px 10px 0 rgb(0 0 0 / 0.05)',
    transition: '0.5s'
  },
  '&.MuiTableRow-hover': {
    border: 0,
    boxShadow: 'inset 0 10px 10px 0 rgb(0 0 0 / 0.05)',
    transition: '0.5s',
    '&:hover': {
      backgroundColor: CONSTANTS.Style.SelectCollapseGrayColor,
      border: 0,
    }
  },
  '&.Mui-selected': {
    backgroundColor: CONSTANTS.Style.NewOrangeClinicorp,
    border: 0,
    '&:hover': {
      backgroundColor: CONSTANTS.Style.BlackColor,
      border: 0,
    }
  }
}))

export const StyledTableRowPagination = styled(TableRow)(() => ({
  backgroundColor: CONSTANTS.Style.NewOrangeClinicorp,
  color: CONSTANTS.Style.White,
}))


export const StyledTablePagination = styled(TablePagination)(() => ({
  color: CONSTANTS.Style.White,
  border: 0,
  '.MuiTablePagination-spacer': {
    '-webkit-flex': 0,
    '-ms-flex': 0,
    flex: 0,
  },
  '.MuiTablePagination-toolbar': {
    backgroundColor: CONSTANTS.Style.GreyColor,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '40px',
    width: '100%',
  },
}))