import React, { useEffect, useState } from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import FormDataProject, { IProject, ISaveFormData } from './FormData'
import { KeyedMutator } from 'swr'
import { IProjects } from '..'
import useApi from '../../../../context/hooks/useApi'

interface IEditProject {
  id: string
  onCloseModal: () => void
  mutate: KeyedMutator<IProjects>
}

const EditProject = (props: IEditProject) => {
  const {
    id,
    onCloseModal,
    mutate,
  } = props

  const [project, setProject] = useState<IProject>()

  const { data: dataProject } = useApi<IProject>({ url: `project/${id}` })

  useEffect(() => {
    if (dataProject) {
      setProject(dataProject)
    }
  }, [dataProject])

  const handleSave = async (data: ISaveFormData) => {
    await api({
      method: 'PUT',
      url: `project/${id}`,
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
  }

  return (
    <FormDataProject
      project={project}
      onCloseModal={onCloseModal}
      onSaveForm={handleSave}
    />
  )
}

export default EditProject