import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import Icon from '@mui/material/Icon'
import WList from '../WList'
import { Chip } from '@react-md/chip'
import { Button } from '@clinicorp/designsystem.components.basic.buttons.button'
import { CONSTANTS } from '../../js/res/CONSTANTS'

const idButton = 'icon-filters-table'

const WFilters = (props, ref) => {
  const {
    listFilters,
    doNotDuplicate,
    onChangeFilters
  } = props

  const [visibleFilters, setVisibleFilters] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [list, setList] = useState(listFilters)
  const listRef = useRef()

  useEffect(() => {
    setList(listFilters)
  }, [listFilters])

  useEffect(() => {
    const collapseItems = ev => {
      if (!listRef.current.element.contains(ev.target) && ev.target?.parentNode?.parentNode?.id !== idButton) {
        setVisibleFilters(false)
      }
    }

    window.addEventListener('scroll', collapseItems, true)

    window.addEventListener('click', collapseItems)

    return () => {
      window.removeEventListener('click', collapseItems)
      window.removeEventListener('scroll', collapseItems, true)
    }
  }, [])

  useEffect(() => {
    if (selectedItems.find(i => i.clearAllOnselected)) {
      setList([])
    } else if (doNotDuplicate) {
      const newList = listFilters.filter(i => !selectedItems.find(j => j.property === i.property))

      setList(newList)
    }

    if (typeof onChangeFilters === 'function') {
      onChangeFilters(selectedItems)
    }
  }, [selectedItems])

  const isVisibleFilters = useCallback(() => visibleFilters, [visibleFilters])

  const getFilters = useCallback(() => selectedItems, [selectedItems])

  const onAddSelectedItem = item => {
    const newSelecteds = item.clearAllOnselected ? [item] : [...selectedItems].concat(item)
    setSelectedItems(newSelecteds)
    setVisibleFilters(false)
  }

  const onRemoveItem = item => {
    const newSelecteds = [...selectedItems].filter(i => i.label !== item.label)
    setSelectedItems(newSelecteds)
  }

  useImperativeHandle(ref, () => ({
    isVisibleFilters,
    getFilters
  }))

  return (
    <div>
      <div className='d-flex align-items-center'>
        <Button
          id={idButton}
          type='icon'
          iconChildren={selectedItems.length > 0 ? 'add' : 'filter_alt'}
          onClick={() => setVisibleFilters(!visibleFilters)}
          disabled={!list?.length}
        />

        {
          selectedItems.length > 0 &&
          selectedItems.map((item, index) => (
            <Chip
              style={{
                backgroundColor: CONSTANTS.Style.GreyBlackColor,
                color: CONSTANTS.Style.White
              }}
              key={`${item.label}_${index}`}
              rightIcon={<Icon onClick={() => onRemoveItem(item)}>close</Icon>}
            >
              {item.label} {item.operator ?? '='} {item.value}
            </Chip>
          ))
        }
      </div>
      <WList
        ref={listRef}
        visible={visibleFilters}
        list={list}
        onAddSelectedItem={onAddSelectedItem}
      />
    </div>
  )
}

export default forwardRef(WFilters)