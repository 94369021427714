import React, { useState } from 'react'
import { IPermission } from './FormData'
import api from '../../../../context/apiContext'
import { KeyedMutator } from 'swr'
import { IPermissions } from '..'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'

interface IDelPermission {
  onCloseModal: () => void
  permissions: IPermission[]
  mutate: KeyedMutator<IPermissions>
}

const DelPermission = (props: IDelPermission) => {
  const {
    onCloseModal,
    permissions,
    mutate
  } = props

  const [isLoading, setIsloading] = useState(false)

  const handleSave = async () => {
    setIsloading(true)
    await api({
      method: 'DELETE',
      url: 'roles/permission',
      params: {
        ids: permissions.map(i => i.id).join(',')
      }
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
      .finally(() => setIsloading(false))
  }

  return (
    <div>
      <WCustomModal
        buttonLabel={<I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.DELETE.BTN_SAVE' />}
        title={<I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.DELETE.TITLE' variables={{ count: permissions.length }}/>}
        open={true}
        handleClose={onCloseModal}
        buttonOnConfirm={handleSave}
        icon={'delete'}
        loading={isLoading}
        disabled={false}
        element={
          <div className='flex flex-col w-96 h-52 p-2 justify-center items-center text-white'>
            <span>
              {
                permissions?.map((i, index) => (
                  <p key={index}>{i.permission}</p>
                ))
              }
            </span>
          </div>
        }
      />

    </div>

  )
}

export default DelPermission