import React from 'react'
import WDialog from '../../WDialog/V2'


const WModal = (props) => {
  const {
    openModal,
    onCloseModal,
    children,
  } = props

  

  return (
    <WDialog
      open={openModal}
      onCloseModal={onCloseModal}
    >
      {children}
    </WDialog>
  )
}

export default WModal