import React from 'react'
import { useTranslation } from 'react-i18next'

interface I18nProps {
  path: string
  variables?: object
}

const I18n = ({ path, variables }: I18nProps) => {
  const { t } = useTranslation()

  return <>{t(path, variables  )}</>
}

export default I18n