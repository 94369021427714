import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import I18n from '../I18n'
import WListkeyValue, { IListItems, WListKeyValueForwardRef } from '../WListKeyValue'

export interface WEnvironmentVariableForwardRefProps {
  getEnvs: () => void
}

export interface IEnv {
  id: string
  env?: string
  value?: string
  secrets?: boolean
}

interface IWEnvironmentVariableProps {
  loadEnvs: IEnv[]
}

const WEnvironmentVariable = forwardRef<WEnvironmentVariableForwardRefProps, IWEnvironmentVariableProps>((props, ref) => {
  const {
    loadEnvs,
  } = props

  const [listEnvs, setListEnvs] = useState<IListItems[]>([])

  const refListKeyValue = useRef<WListKeyValueForwardRef>(null)

  useEffect(() => {
    setListEnvs(loadEnvs.map(i => ({ ...i, key: i.env })))
  }, [loadEnvs])

  const getEnvs = () => {
    if (refListKeyValue.current) {
      const list = refListKeyValue.current.getListItems()
      return list.map(i => ({ ...i, env: i.key }))
    }

    return []
  }

  useImperativeHandle(ref, () => ({
    getEnvs
  }), [])

  return (
    <WListkeyValue
      ref={refListKeyValue}
      title={<I18n path='COMPONENTS.WENVIRONMENTVARIABLE.TITLE'/>}
      btnLabelMore={<I18n path='COMPONENTS.WENVIRONMENTVARIABLE.ADD'/>}
      loadKeyValue={listEnvs}
      keyLabel='Env'
      valueLabel='Value'
    />
  )
})

WEnvironmentVariable.displayName = 'WEnvironmentVariable'
export default WEnvironmentVariable