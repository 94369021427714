import React, { useState, useEffect, useRef } from 'react'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import WSelectFieldMulti from '../../../components/WSelectFieldMulti'
import api from '../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../helper'
import WTable from '../../../components/WTable'
import I18n from '../../../components/I18n'
import useApi from '../../../context/hooks/useApi'
import WCustomModal from '../../../components/WModal/V3'
import WTextFieldV2 from '../../../components/WTextField/V2'
import WSelectField from '../../../components/WSelectField/V2'

const listRuntime = [
  { label: 'Nodejs14', value: 'nodejs14' },
  { label: 'Nodejs16', value: 'nodejs16' },
  { label: 'Nodejs18', value: 'nodejs18' },
  { label: 'Nodejs20', value: 'nodejs20' },
]

const Environments = () => {
  const [newEnviroment, setNewEnviroment] = useState('')
  const [newRuntime, setNewRuntime] = useState('')
  const [newEnviromentError, setNewEnviromentError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [showAddEnvironmentModal, setShowAddEnvironmentModal] = useState(false)
  const [showEditEnvironmentModal, setShowEditEnvironmentModal] = useState(false)
  const [showDelEnvironmentModal, setShowDelEnvironmentModal] = useState(false)
  const [environments, setEnvironments] = useState([])
  const [environmentsSelected, setEnvironmentsSelected] = useState([])
  const [cloudFunctions, setCloudFunctions] = useState([])
  const [cloudRuns, setCloudRuns] = useState([])
  const [selectedMicroservices, setSelectedMicroservices] = useState([])

  const WtableRef = useRef()

  const { data: dataEnvironments, mutate: mutateEnvironments } = useApi({ url: 'environments' })
  const { data: dataCloudFunction } = useApi({ url: 'cloud-function' })
  const { data: dataCloudRun } = useApi({ url: 'cloud-run' })

  useEffect(() => {
    if (dataEnvironments) {
      const newEnvironments = dataEnvironments.data?.map(i => ({
        cells: [
          { id: i.id, hidden: true },
          { label: i.name },
          { label: [ ...i.cloudFunctions?.map(j => j.name ).join(', '), ...i.cloudRuns?.map(j => j.name ).join(', ')]}
        ]
      }))

      setEnvironments(newEnvironments)
    }
  }, [dataEnvironments])

  useEffect(() => {
    if (dataCloudFunction) {
      const newCloudFunctions = dataCloudFunction.data?.map(i => ({ value: i.id, label: i.name, cloudFunction: true }))
      setCloudFunctions(newCloudFunctions)
    }
  }, [dataCloudFunction])

  useEffect(() => {
    if (dataCloudRun) {
      const newCloudRuns = dataCloudRun.data?.map(i => ({ value: i.id, label: i.name, cloudRun: true }))
      setCloudRuns(newCloudRuns)
    }
  }, [dataCloudRun])

  const getEnvironmentById = async id => {
    setIsLoading(true)
    await api({
      method: 'GET',
      url: `environments/${id}`,
    }).then(({ data }) => {
      const {
        name,
        cloudFunctions,
        cloudRuns,
        runtime
      } = data

      setNewRuntime(listRuntime.find(i => i.value === runtime))
      setNewEnviroment(name)
      const selectedCloudFunctions = cloudFunctions?.map(i => ({ value: i.id, label: i.name, cloudFunction: true })) ?? []
      const selectedCloudRuns = cloudRuns?.map(i => ({ value: i.id, label: i.name, cloudRun: true })) ?? []
      setSelectedMicroservices([...selectedCloudFunctions, ...selectedCloudRuns])

    }).catch(error => showResponseError(error))
      .finally(() => setIsLoading(false))
  }

  const getEnvironmentsSelected = value => {
    setEnvironmentsSelected(value)
  }

  const handlesEditCloudFunction = async () => {
    const id = WtableRef.current.getIdCheckedValues()[0]

    await getEnvironmentById(id)
    handleOpenModal('edit')
  }

  const handleOpenModal = type => {
    switch (type) {
    case 'add':
      setShowEditEnvironmentModal(false)
      setShowDelEnvironmentModal(false)
      setShowAddEnvironmentModal(true)
      break
    case 'edit':
      setShowDelEnvironmentModal(false)
      setShowAddEnvironmentModal(false)
      setShowEditEnvironmentModal(true)
      break
    case 'delete':
      setShowEditEnvironmentModal(false)
      setShowAddEnvironmentModal(false)
      setShowDelEnvironmentModal(true)
      break
    }
  }

  const handleCloseModal = () => {
    setNewEnviroment('')
    WtableRef.current?.clearCheckedValues()
    setShowAddEnvironmentModal(false)
    setShowEditEnvironmentModal(false)
    setShowDelEnvironmentModal(false)
  }

  const validateNewEnvironment = () => {
    setNewEnviromentError('')

    let errorEnvironment
    if (!newEnviroment?.length)
      errorEnvironment = 'Required Name'

    setNewEnviromentError(errorEnvironment)
    return !errorEnvironment?.length
  }

  const handleSubmitEnvironment = async () => {

    if (!validateNewEnvironment())
      return

    const ids = WtableRef.current.getIdCheckedValues().join(',')


    const data = {
      name: newEnviroment,
      runtime: newRuntime,
      cloudFunctions: selectedMicroservices.filter(i => i.cloudFunction).map(i => ({ id: i.value })),
      cloudRuns: selectedMicroservices.filter(i => i.cloudRun).map(i => ({ id: i.value })),
    }

    const config = {
      method: ids ? 'PUT' : 'POST',
      url: `environments${ids ? `/${ids}` : ''}`
    }

    setIsLoadingModal(true)
    await api({
      method: config.method,
      url: config.url,
      data: { ...data }
    }).then(async ({ data }) => {
      mutateEnvironments()
      showResponseSuccess(data?.message)
    })
      .catch(error => showResponseError(error?.response?.data?.message))
      .finally(() => {
        handleCloseModal()
        setIsLoadingModal(false)
      })
  }

  const handleInputEnviroment = ev => {
    const newValue = ev.target?.value?.toUpperCase()
    setNewEnviroment(newValue)
  }

  const handleDelEnvironment = async () => {
    const ids = WtableRef.current.getIdCheckedValues().join(',')

    setIsLoadingModal(true)
    await api({
      method: 'DELETE',
      url: 'environments',
      params: { ids }
    }).then(async ({ data }) => {
      mutateEnvironments()
      showResponseSuccess(data?.message)
    }).catch(error => showResponseError(error))
      .finally(() => {
        handleCloseModal()
        setIsLoadingModal(false)
      })
  }

  const headerTable = [
    { label: <I18n path='Admin.ENVIRONMENTS.TABLE.ENVIRONMENTS'/> },
    { label: <I18n path='Admin.ENVIRONMENTS.TABLE.CLOUD_FUNCTIONS'/> },
  ]

  const buttons = [
    {
      type: 'icon',
      icon: 'add',
      onClick: () => handleOpenModal('add'),
      tooltipLabel: <I18n path='Admin.ENVIRONMENTS.TABLE.ADD' />
    },
    {
      type: 'icon',
      icon: 'edit',
      onClick: () => handlesEditCloudFunction(),
      disabled: environmentsSelected.length !== 1,
      tooltipLabel: <I18n path='Admin.ENVIRONMENTS.TABLE.EDIT' />
    },
    {
      type: 'icon',
      icon: 'delete',
      onClick: () => handleOpenModal('delete'),
      disabled: !environmentsSelected.length,
      tooltipLabel: <I18n path='Admin.ENVIRONMENTS.TABLE.DELETE' />
    }
  ]

  const disabledSelectCloudFunctions = () => environmentsSelected.length === 1 && newEnviroment.toLowerCase().endsWith('-random')

  return (
    <div className='container' >
      {isLoading && <Loading />}

      {(showAddEnvironmentModal || showEditEnvironmentModal) &&
      <>
        <WCustomModal
          buttonLabel={showAddEnvironmentModal ? 'Confirm' : 'Save'}
          title={<>{showEditEnvironmentModal ? <I18n path='Admin.ENVIRONMENTS.TABLE.EDIT'/> : <I18n path='Admin.ENVIRONMENTS.TABLE.ADD'/>} <I18n path='Admin.ENVIRONMENTS.ENVIRONMENT'/></>}
          open={(showAddEnvironmentModal || showEditEnvironmentModal)}
          handleClose={handleCloseModal}
          buttonOnConfirm={handleSubmitEnvironment}
          icon={showAddEnvironmentModal ? 'check_circle' : 'edit'}
          loading={isLoadingModal}
          disabled={!newEnviroment?.length || newEnviroment?.length > 30}
          element={
            <div className='flex flex-col w-96 h-80 items-center gap-y-10 text-white overflow-auto'>
              <WTextFieldV2
                label={<I18n path='Admin.ENVIRONMENTS.ENVIRONMENT'/>}
                required
                error={newEnviromentError?.length || newEnviroment?.length > 30}
                helperText={`${newEnviroment?.length}/30` || newEnviromentError}
                value={newEnviroment}
                onChange={handleInputEnviroment}
                variant='standard'
                disabled={environmentsSelected?.length > 1}
              />
              <WSelectField
                label={<I18n path='Admin.ENVIRONMENTS.RUNTIME' />}
                className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                theme={{ colors: 'neutral0' }}
                required
                placeholder=''
                value={newRuntime}
                onChange={ev => setNewRuntime(ev)}
                options={listRuntime}
              />
              <WSelectFieldMulti
                label={'Cloud functions'}
                placeholder=''
                value={selectedMicroservices}
                className='w-full'
                onChange={ev => setSelectedMicroservices(ev)}
                options={[
                  {
                    label: 'Cloud functions',
                    options: cloudFunctions
                  },
                  {
                    label: 'Cloud run',
                    options: cloudRuns
                  }
                ]}
                isDisabled={disabledSelectCloudFunctions()}
              />
            </div>
          }
        />
      </>
      }
      {showDelEnvironmentModal &&
      <>
        <WCustomModal
          buttonLabel={<I18n path='Admin.ENVIRONMENTS.REMOVE'/>}
          title={<I18n path='Admin.ENVIRONMENTS.REMOVE_ENVIRONMENT'/>}
          open={showDelEnvironmentModal}
          handleClose={handleCloseModal}
          buttonOnConfirm={handleDelEnvironment}
          icon={'delete'}
          loading={isLoadingModal}
          element={
            <div className='container w-64 min-h-20 text-white overflow-auto'>
              <span>Environments selecteds:</span>
              {environmentsSelected?.flatMap(i => i.cells).filter(i => i.label).map(i => (
                <p key={i.value}>{i.label}</p>
              ))}
            </div>
          }
        />
      </>
      }

      <div className='row' style={{ paddingTop: '10px' }}>
        <WTable
          ref={WtableRef}
          fullWidth={true}
          tableHeader={true}
          checkboxAllRows={true}
          checkboxRows
          headerCells={headerTable}
          tableFooter={false}
          footerPagination={false}
          rows={environments}
          getCheckedValues={getEnvironmentsSelected}
          topMenu
          buttonsTopMenu={buttons}
        />
      </div>
    </div>
  )
}

export default Environments