import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import I18n from '../../I18n'
import WRadioGroupV2 from '../../WRadioGroup/V2'

interface IWRadioGroupAuthentication {
  defaultValue: boolean
}

type forwardRefProps = {
  getValue: () => boolean
}

const optionsAuthentication = [
  {
    label: <I18n path='Admin.CLOUD_RUN.RADIO_GROUP.AUTHENTICATION.NOT_REQUIRED'/>,
    value: false,
  },
  {
    label: <I18n path='Admin.CLOUD_RUN.RADIO_GROUP.AUTHENTICATION.REQUIRED'/>,
    value: true,
  },
]

const WRadioGroupAuthenticationV2 = forwardRef<forwardRefProps, IWRadioGroupAuthentication>((props, ref) => {

  const [value, setValue] = useState<boolean>(props.defaultValue)

  useEffect(() => {
    setValue(props.defaultValue)
  }, [props.defaultValue])

  const handleChange = (data: string ) => {
    setValue(data === 'true')
  }

  const getValue = () => value

  useImperativeHandle(ref, () => ({
    getValue
  }), [value])

  return (
    <WRadioGroupV2
      label={<I18n path='Admin.CLOUD_RUN.TABLE.AUTHENTICATION' />}
      options={optionsAuthentication}
      defaultValue={value}
      onChangeRadioGroup={handleChange}
    />
  )
})

WRadioGroupAuthenticationV2.displayName = 'WRadioGroupAuthenticationV2'
export default WRadioGroupAuthenticationV2