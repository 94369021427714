import React, { useState } from 'react'
import I18n from '../../../../components/I18n'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import WCustomModal from '../../../../components/WModal/V3'

const DeleteSecret = props => {
  const {
    selectedRows,
    onCloseModal,
    openModal
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [listErrors, setListErros] = useState([])

  const getValuesByKey = key => selectedRows?.flatMap(i => i.cells)?.filter(i => i.key === key)?.map(i => i.value)?.join(', ')

  const ids = getValuesByKey('id')

  const handleConfirm = async () => {
    setIsLoading(true)

    await api({
      method: 'DELETE',
      url: 'secrets',
      params: {
        ids
      }
    }).then(({ data }) => {
      showResponseSuccess(data?.message)
      onCloseModal({ needReloadSecrets: true })
    }).catch(error => {
      if (error.response.status === 428) {
        const { data } = error.response
        setListErros(data.details)
      }

      showResponseError(error)
    })

    setIsLoading(false)
  }

  return (
    <div>
      <>
        <WCustomModal
          buttonLabel={'Delete'}
          title={<I18n path='Admin.SECRETS.MODAL.DELETE.TITLE'/>}
          open={openModal}
          handleClose={onCloseModal}
          buttonOnConfirm={handleConfirm}
          icon={'delete'}
          loading={isLoading}
          disabled={false}
          element={
            <div className='flex flex-col w-60 h-36 justify-center items-center gap-y-5 p-4'>

              <span className='text-white'>
                { !listErrors.length && getValuesByKey('name')}
              </span>

              <div>
                <ul>
                  {
                    listErrors.map(i => (
                      <li key={i.id}>
                        <div className='flex space-x-2'>
                          {
                            !!i.cloudFunctions.length &&
                    <I18n
                      path='Admin.SECRETS.MODAL.DELETE.RESPONSE_SECRETS_IN_USE_CF'
                      variables={{ secretName: i.secret?.name, cfName: i.cloudFunctions?.map(cf => cf.name).join(', '), envName: i.name }}
                    />
                          }
                          {
                            !!i.cloudRuns.length &&
                    <I18n
                      path='Admin.SECRETS.MODAL.DELETE.RESPONSE_SECRETS_IN_USE_CR'
                      variables={{ secretName: i.secret?.name, crName: i.cloudRuns?.map(cf => cf.name).join(', '), envName: i.name }}
                    />
                          }
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          }
        />
      </>

    </div>
  )
}

export default DeleteSecret