import React, { useCallback, useState, useEffect } from 'react'
import I18n from '../../../components/I18n'
import { showResponseError, showResponseSuccess } from '../../../helper'
import api from '../../../context/apiContext'
import WCheckbox from '../../../components/WCheckbox'
import './style.scss'
import WCustomModal from '../../../components/WModal/V3'

const ModalCancelBuild = (props) => {
  const {
    openModal,
    onCloseModal,
    status,
    reloadBuilds,
    isLoading,
    loading,
    pipelineId
  } = props

  const [pipeline, setPipeline] = useState({})
  const [isChecked, setIsChecked] = useState([])

  useEffect(() => {
    handleGetDataToCancelBuild()
  }, [])

  useEffect(() => {
    if (!openModal) {
      setIsChecked(isChecked.map(() => false))
    }
  }, [openModal])

  useEffect(() => {
    const checkbox = pipeline.cloudBuilds?.filter(i => (i.status !== 'QUEUED' || i.status !== 'WORKING'))?.map(() => false) ?? []

    setIsChecked(checkbox)
  }, [pipeline])

  const isCheckboxChecked = (index, checked) => {
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked
        return c
      })
    })
  }

  const disabledConfirm = useCallback(() => {
    return !isChecked.some(i => i)
  })

  const handleGetDataToCancelBuild = async () => {
    if (status.toLocaleLowerCase() !== 'working')
      return

    loading(true)
    await api({
      method: 'GET',
      url: `pipelines/${pipelineId}`,
    }).then(({ data }) => {
      setPipeline(data)
    }).catch(error => showResponseError(error))
      .finally(() => loading(false))
  }

  const handleCancelBuild = async () => {
    loading(true)

    const buildId = pipeline.cloudBuilds?.filter((i, index) => ((i.status !== 'QUEUED' || i.status !== 'WORKING') && isChecked[index]))?.map(i => i.id)

    await api({
      method: 'PATCH',
      url: 'cloud-build/cancel',
      data: {
        buildId
      }
    }).then(({ data }) => {
      showResponseSuccess(data?.message)
      reloadBuilds()
    }).catch(error => showResponseError(error))
      .finally(() => {
        loading(false)
        onCloseModal()
      })
  }

  return (
    <WCustomModal
      buttonLabel='Confirm'
      open={openModal}
      handleClose={onCloseModal}
      buttonOnConfirm={handleCancelBuild}
      icon={'check_circle'}
      alignModal={'start'}
      loading={isLoading}
      disabled={disabledConfirm()}
      element={
        <div>
          <div className='container m-0 p-0'>
            <div className='flex flex-row w-full justify-content-center text-white text-base'>
              <p><I18n path='Build.CANCEL_BUILD'/></p>
            </div>
            <div className='p-4'>
              {
                pipeline.cloudBuilds?.filter(i => (i.status === 'QUEUED' || i.status === 'WORKING'))?.map((i, index) => (
                  <ul
                    className=''
                    key={`${i.id}-${index}`}
                  >
                    <li>
                      <WCheckbox
                        id='checkbox-appEngine'
                        i18n={`${i.appEngine ? 'App Engine' : i.cloudFunction?.name ?? i.cloudRun?.name}`}
                        checked={isChecked[index]}
                        onChange={(e) => isCheckboxChecked(index, e.target.checked)}
                      />
                    </li>
                  </ul>
                ))
              }
            </div>
          </div>
        </div>
      }
    />
  )
}

export default ModalCancelBuild