import api from '../context/apiContext'

export const subcriberNotification = async () => await navigator.serviceWorker.getRegistration()
  .then(async (serviceWorker) => {
    let subscription = await serviceWorker?.pushManager.getSubscription()

    if (!subscription) {
      await api({
        url: '/notifications/public-key',
      }).then(async ({ data }) => {
        subscription = await serviceWorker?.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: data.public_key
        })

        if (subscription) {
          await api({
            method: 'PUT',
            url: '/notifications/subscriber',
            data: subscription
          })
        }
      })
    }

    return subscription
  })

