import React from 'react'
import { components } from 'react-select'
import I18n from '../I18n'

const defaultTheme = {
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused,
      color: isDisabled
        ? '#ccc'
        : '#f00'
    }
  },
  colors: {
    primary: 'var(--NewOrangeClinicorp)',
    primary75: '#4C9AFF',
    primary50: 'var(--OrangeClinicorp)',
    primary25: 'var(--NewOrangeClinicorp)',
    danger: '#f44336',
    dangerLight: '#FFBDAD',
    neutral0: 'hsl(0, 0%, 100%)',
    neutral5: 'transparent',
    neutral10: 'hsl(0, 0%, 90%)',
    neutral20: 'hsl(0, 0%, 80%)',
    neutral30: 'hsl(0, 0%, 70%)',
    neutral40: 'hsl(0, 0%, 60%)',
    neutral50: 'hsl(0, 0%, 50%)',
    neutral60: 'hsl(0, 0%, 40%)',
    neutral70: 'hsl(0, 0%, 30%)',
    neutral80: 'hsl(0, 0%, 20%)',
    neutral90: 'hsl(0, 0%, 10%)'
  },
  spacing: {
    baseUnit: 4,
    controlHeight: 40,
    menuGutter: 8
  }
}

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      borderBottom: `1px solid ${state.selectProps.errorText ? 'red' : 'rgba(0, 0, 0, 0.12)'}`,
      borderRadius: 0,
      boxShadow: 'none',
      color: `${state.selectProps.errorText ? 'red' : 'white'}`,
      backgroundColor: '#111111'
    }
  },

  container: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    color:'var(--White)',
    borderBottom:'1px solid #a73804'
  }),
  
  option: (base) => ({
    ...base,
    color: 'var(--White)'
  }
  ),
  
  menuList: (base) => ({
    ...base,
    backgroundColor: '#151515'
  }),
  
  singleValue: (base) => ({
    ...base,
    color: 'var(--White)',
    margin: '0',
    padding: '0',
  }),
  input: (base) => ({
    ...base,
    color: 'white',
    marginTop: '1rem',
    margin: '0',
    padding: '0'
  }),

  selectContainer: (base) => ({
    ...base,
    margin: '0',
    padding: '0'
  }),

  valueContainer: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    marginLeft: '10px'
  }),
  menu: (base) => ({
    ...base,
    margin: '0',
    padding: '0'
  }),
}

const Control = props => (
  <div>
    {
      props.selectProps.label &&
    <span
      style={{
        opacity: props.selectProps.isDisabled ? 0.4 : 1,
        color: 'var(--White)'
      }}
    >{props.selectProps.label} {props.selectProps.required ? '*' : null}</span>
    }
    <components.Control {...props} />
    {
      props.selectProps.errorText &&
    <span
      style={{
        color: 'red',
        fontSize: '14px'
      }}
    >{props.selectProps.errorText}</span>
    }
  </div>
)

const SelectContainer = ({
  children,
  ...props
}) => {
  return (
    <components.SelectContainer {...props}>
      {children}
    </components.SelectContainer>
  )
}

const NoOptionsMessage = ({ ...props }) => {
  const {
    messageNoOptions
  } = props.selectProps

  return (
    <components.NoOptionsMessage {...props}>
      {messageNoOptions ?? <I18n path='Global.NO_OPTIONS'/>}
    </components.NoOptionsMessage>
  )
}

const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
  const {
    limitChips
  } = props.selectProps

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    )
  }

  const CHIPS_LIMIT = limitChips ?? 0
  const [chips, otherChildren] = children
  const overflowCounter = chips.slice(CHIPS_LIMIT).length
  const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT)

  return (
    <components.ValueContainer {...props}>
      <div>
        {displayChips}
        {overflowCounter > 0 && `${overflowCounter} | `}
        {otherChildren}
      </div>
    </components.ValueContainer>
  )
}

const LoadingMessage = props => {
  return (
    <div
      {...props.innerProps}
      style={props.getStyles('loadingMessage', props)}
    >
      <I18n path={props.children}/>
    </div>
  )
}

export {
  defaultTheme,
  customStyles,
  NoOptionsMessage,
  LimitedChipsContainer,
  Control,
  SelectContainer,
  LoadingMessage,
}