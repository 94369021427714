import React, { useEffect, useRef, useState } from 'react'
import WTable from '../../../components/WTable/v2'
import useApi from '../../../context/hooks/useApi'
import AddNewSecret from './modal/addNewSecret'
import DeleteSecret from './modal/deleteSecret'
import EditSecret from './modal/editSecret'

const Secrets = () => {
  const [rows, setRows] = useState([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filters, setFilters] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [currentProject, setCurrentProject] = useState()

  const { data: dataSecrets, isLoading: isloadingSecrets, mutate: mutateSecrets } = useApi({ url: `secrets?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })

  const WTableRef = useRef()

  const headers = [
    {
      key: 'id',
      value: 'Id',
      align: 'center',
      hidden: true
    },
    {
      key: 'name',
      value: 'Nome',
      align: 'center',
      hidden: false,
      sortable: true,
      searchable: true,
    },
  ]

  useEffect(() => {
    if (dataSecrets) {
      const newRows = dataSecrets.data?.map(i => ({
        isCollapse: false,
        isSelectable: true,
        cells: [
          {
            key: 'id',
            value: i.id,
          },
          {
            key: 'name',
            value: i.name,
          },
        ]
      }))

      setRows(newRows)
      setCount(dataSecrets.count)
    }
  }, [dataSecrets])

  useEffect(() => {
    if (!openModal) {
      WTableRef.current?.clearCheckedValues()
    }
  }, [openModal])

  useEffect(() => {
    WTableRef.current?.updateFilters({ key: 'project', operator: 'EQUAL', value: currentProject})
  }, [currentProject])

  const onChangePage = (newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder, newOrderBy) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = filters => {
    setFilters(filters)
  }

  const onAddNewRow = () => onOpenModal('add')

  const onDeleteRow = ev => {
    const selectedRowsOnDelete = rows.filter((_, index) => ev.includes(index))
    setSelectedRows(selectedRowsOnDelete)
    onOpenModal('delete')
  }

  const onEditRow = ev => {
    const selectedRow = rows.find((_, index) => ev.includes(index))
    setSelectedRows(selectedRow)
    onOpenModal('edit')
  }

  const onOpenModal = type => {
    setTypeModal(type)
    setOpenModal(true)
  }

  const onCloseModal = ev => {
    if (ev?.needReloadSecrets)
      mutateSecrets()

    setOpenModal(false)
  }

  const permissionsTableHeader = {
    add: 'secrets.post',
    edit: 'secrets.patch',
    remove: 'secrets.delete',
  }

  return (
    <div className='flex w-[99%] max-h-full'>
      {
        openModal &&
        <>
          {
            typeModal === 'add' &&
            <AddNewSecret
              projectId={currentProject}
              onCloseModal={(ev) => onCloseModal(ev)}
              openModal={openModal}
            />
          }
          {
            typeModal === 'edit' &&
            <EditSecret
              selectedRow={selectedRows}
              onCloseModal={(ev) => onCloseModal(ev)}
              openModal={openModal}
            />
          }
          {
            typeModal === 'delete' &&
            <DeleteSecret
              selectedRows={selectedRows}
              onCloseModal={(ev) => onCloseModal(ev)}
              openModal={openModal}
            />
          }
        </>
      }
      <WTable
        ref={WTableRef}
        isLoadingData={isloadingSecrets}
        headers={headers}
        rows={rows}
        isCollapse={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        countRows={count}
        limit={10}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        onChangeFilters={handleChangeFilters}
        tableHeader
        permissionsTableHeader={permissionsTableHeader}
        onAddNewRow={onAddNewRow}
        onEditRow={ev => onEditRow(ev)}
        onDeleteRow={ev => onDeleteRow(ev)}
      />

    </div>
  )
}

export default Secrets