import React from 'react'
import { createRoot } from 'react-dom/client'
import { Alert } from '@clinicorp/designsystem.components.basic.alerts.alert'
import I18n from './components/I18n'
import api from './context/apiContext'

export const showError = (message, title, dimissMiliseconds) => {
  showMessage(message, title || 'Global.ERROR', dimissMiliseconds, 'danger')
}

export const showSuccess = (message, title, dimissMiliseconds) => {
  showMessage(message, title || 'Global.SUCCESS', dimissMiliseconds, 'success')
}

export const showInfo = (message, title, dimissMiliseconds) => {
  showMessage(message, title || 'Global.INFO', dimissMiliseconds, 'info')
}

export const showAlert = (message, title, dimissMiliseconds) => {
  showMessage(message, title || 'Global.ALERT', dimissMiliseconds, 'warning')
}

export const showResponseError = (message, title, dimissMiliseconds) => {
  showMessage(`Response.${message?.response?.data?.message ?? message}`, title || 'Global.ERROR', dimissMiliseconds, 'danger')
}

export const showResponseSuccess = (message, title, dimissMiliseconds) => {
  showMessage(`Response.${message?.response?.data?.message ?? message}`, title || 'Global.SUCCESS', dimissMiliseconds, 'success')
}

export const showResponseInfo = (message, title, dimissMiliseconds) => {
  showMessage(`Response.${message?.response?.data?.message ?? message}`, title || 'Global.INFO', dimissMiliseconds, 'info')
}

export const showResponseAlert = (message, title, dimissMiliseconds) => {
  showMessage(`Response.${message?.response?.data?.message ?? message}`, title || 'Global.ALERT', dimissMiliseconds, 'warning')
}

export const showResponse = (message, title, dimissMiliseconds, type) => {
  switch (type) {
  case 'success':
    showResponseSuccess(message, title, dimissMiliseconds)
    break
  case 'info':
    showResponseInfo(message, title, dimissMiliseconds)
    break
  case 'warning':
    showAlert(message, title, dimissMiliseconds)
    break
  case 'danger':
  default:
    showResponseError(message, title, dimissMiliseconds)
    break
  }
}

export const showMessage = async (message, title, dimissMiliseconds = 3000, type = 'info') => {
  const root = createRoot(document.getElementById('alert'))

  root.render(
    <Alert
      title={<I18n path={[title]}/>}
      type={type}
      message={<I18n path={[message]}/>}
      dimissMiliseconds={dimissMiliseconds}
    />
  )

  await new Promise((resolve) => {
    setTimeout(resolve, dimissMiliseconds)
  })

  // root.unmount()
}

const updateVersion = (data) => {
  const explode = data?.version.replace(/[^0-9-]/g, '').split(/[-]+/g).reverse()
  const version = explode[0] ? explode.map((i, index) => {
    if (index === 0) {
      return (parseInt(i) >= 99 ? 0 : parseInt(i) + 1).toString().padStart(2, '0')
    } else if (index === 1 && parseInt(explode[0]) === 99) {
      return (parseInt(i) >= 99 ? 0 : parseInt(i) + 1).toString().padStart(2, '0')
    } else if (index === 2 && parseInt(explode[1]) === 99) {
      return (parseInt(i) >= 99 ? 0 : parseInt(i) + 1).toString().padStart(2, '0')
    }
    else return (parseInt(i)).toString().padStart((index === 2 ? 1 : 2), '0')
  }).reverse().join('-') : '00-00-00'

  return version
}

export const getVersionByAppEngine = async () => new Promise((resolve, reject) => {
  api({
    method: 'GET',
    url: 'app-engine/version',
  }).then(({ data }) => {
    const version = updateVersion(data)
    resolve(version)
  }).catch(error => reject(error))
})

export const getVersionByCloudRun = async (id) => new Promise((resolve, reject) => {
  api({
    method: 'GET',
    url: `cloud-run/version/${id}`,
  }).then(({ data }) => {
    const version = updateVersion(data)
    resolve(version)
  }).catch(error => reject(error))
})