import React from 'react'
import Icon from '@mui/material/Icon'
import I18n from '../../components/I18n'

const DetailsTimestamp = props => {
  const {
    startTime,
    textHidden
  } = props

  const getTimestampAgo = (start, end) => {
    const initDate = new Date(start)
    const endDate = new Date((end)).toString() !== 'Invalid Date' ? new Date((end)) : new Date()

    const miliseconds = endDate.getTime() - initDate.getTime()
    
    const seconds = miliseconds / 1000
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = hours / 24
    
    if (days >= 1)
      return <>{Math.round(days)} {Math.round(days) > 1 ? <I18n path='Timestamp.DAYS'/> : <I18n path='Timestamp.DAY'/> }</>
    else if (hours >= 1)
      return <>{Math.round(hours)} <I18n path='Timestamp.HOURS'/></>
    else if (minutes >= 1)
      return <>{Math.round(minutes)} {Math.round(minutes) > 1 ? <I18n path='Timestamp.MINUTES'/> : <I18n path='Timestamp.MINUTE'/>}</>
    else if (seconds >= 1)
      return <>{Math.round(seconds)} {Math.round(seconds) > 1 ? <I18n path='Timestamp.SECONDS'/> : <I18n path='Timestamp.SECOND'/>}</>

    return ''
  }

  return (
    <div className='d-flex align-items-center'>
      <Icon fontSize='small' className='cursor-help'>schedule</Icon>
      {textHidden ?
        <p style={{ marginLeft: '10px' }}>{getTimestampAgo(startTime)} <I18n path='Global.AGO'/></p>
        :
        <></>
      }
    </div>
  )
}

export default DetailsTimestamp