export default {
  translations: {
    'Loading...': 'Loading...',
    TOKEN_NOT_INFORMED: 'Token not informed',
    UPDATED_TOKEN: 'Updated token',
    Response: {
      SOMETHING_WENT_WRONG: 'Something went wrong',
      WITHOUT_PERMISSION_CONTACT_ADMINISTRATOR: 'Without permission contact administrator',
      WITHOUT_PERMISSION_BUILD_CONTACT_ADMINISTRATOR: 'No permission to build, please contact the admin',
      WITHOUT_PERMISSION_BUILD_ENVIRONMENT_PRODUCTION: 'no permission to compile in production environment',
      WITHOUT_PERMISSION_BUILD_CORE: 'no permission to build in core',
      ENVIRONMENT_NOT_FOUND: 'Environment not found',
      ENVIRONMENT_IS_WORKING_WITH_SELECTED_CLOUD_FUNCTIONS: 'Environment build is already running with some cloud role selected',
      ENVIRONMENT_IS_WORKING_WITH_APP_ENGINE: 'Environment has a build running for App Engine',
      FAILED_TO_START_BUILD: 'Fail to start build',
      NOT_FOUND_BUILD_INITIALIZATION: 'Not found build initialization',
      DATA_STORAGE_FAILURE: 'Data storage failure',
      NEW_CLOUD_FUNCTION_CREATED: 'New cloud function created',
      BUILD_STARTED: 'Build started',
      NEW_ENVIRONMENT_CREATED: 'new environment created',
      CLOUD_FUNCTION_UPDATED: 'Cloud function update',
      ENVIRONMENT_UPDATED: 'Environment updated',
      EXCLUDED_CLOUD_FUNCTIONS: 'Excluded cloud functions',
      ENTITY_FOR_CANCEL_BUILD_NOT_FOUND: 'Build for cancel not found',
      STATUS_TO_CANCEL_NOT_ALLOWED: 'Status to cancel not allowed',
      YOU_ARE_NOT_ALLOWED_TO_CANCEL_THIS_BUILD: 'You are not allowed to cancel this build',
      BUILD_CANCELLED: 'Build cancelled',
      CLINICORP_EXCLUSIVE_SYSTEM: 'This system is exclusive to clinicorp',
      NEED_TO_RELOG: 'Unidentified user, need to relog',
      USER_DOES_NOT_HAVE_ADMIN_ACCESS: 'User does not have admin access',
      APPLICATION_IN_MAINTENANCE: 'Application in maintenance',
      NO_USER_FOUND: 'No user found',
      ENVIRONMENTS_NOT_INFORMED: 'Environments not informed',
      CLOUD_FUNCTIONS_NOT_INFORMED: 'Cloud functions not informed',
      BUILD_ID_NOT_INFORMED: 'Build id not informed',
      UID_NOT_INFORMED: 'Uid not informed',
      TOKEN_NOT_INFORMED: 'Token not informed',
      ENVIRONMENT_NOT_INFORMED: 'Environment not informed',
      CLOUD_FUNCTION_NOT_INFORMED: 'Cloud function not informed',
      DELETED_ENVIRONMENTS: 'Deleted environments',
      INVALID_PROJECT: 'Invalid project',
      NAME_IN_USE: 'Name in use',
      NAME_NOT_INFORMED: 'Name not informed',
      SYNTHETIC_TEST_STARTED: 'Synthetic test started',
      SYNTHETIC_TESTING_STATUS_NOT_ALLOWED: 'Synthetic testing status not allowed',
      NOT_FOUND_BUILD_ID: 'Not found build id',
      PROMOTING_VERSION: 'Promoting version',
      REVERTING_VERSION: 'Reverting version',
      VERSION_ALREADY_USED: 'Version already used',
      SELECTED_VERSION_IS_NOT_CURRENT: 'Selectec version is not current',
      EDITED_USERS: 'Edited users',
      TOKEN_EXPIRED: 'Token expired',
      UPDATED_STATUS: 'Update Status',
      SERVICE_STARTED: 'Service started',
      RUNNING_PROCESS: 'Running process',
      FAILED_TO_RUN_SERVICE: 'Failed to run service',
      GENERATED_FILE: 'Generated file',
      FORBIDEN: 'Forbiden',
      USER_DOES_NOT_HAVE_ACCESS: 'User does not have access',
      SECRET_CREATED: 'Secret created',
      SECRET_UPDATED: 'Secret updated',
      SECRET_DELETED: 'Secret deleted',
      CLOUD_RUN_CREATED: 'Cloud run created',
      CLOUD_RUN_EDITED: 'Cloud run edited',
      EXCLUDED_CLOUD_RUN: 'Excluded cloud run',
    },
    Global: {
      WARNING: 'Warning',
      ERROR: 'Error',
      FAILURE: 'Failure',
      SUCCESS: 'Success',
      INFO: 'Informative',
      ALERT: 'Alert',
      COPIED_LINK: 'Copied link',
      LOGOUT: 'Logout',
      CANCELLED: 'Cancelled',
      WORKING: 'Working',
      CANCEL: 'Cancel',
      CONFIRM: 'Confirm',
      TOKEN_EXPIRED: 'Token expired',
      TOKEN_NOT_FOUND: 'Token not found, please relog',
      USER: 'User',
      ENVIRONMENT: 'Environment',
      TESTING: 'Testing',
      WAITING_TO_PROMOTE: 'Waiting to promote',
      TEST_SYNTHETIC_FAILED: 'Test synthetic failed',
      PROMOTING: 'Promoting',
      TRAFFIC: 'Traffic',
      NO_OPTIONS: 'No options',
      REQUIRED_FIELD: 'Required field',
      AGO: 'ago',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      BUILD: 'Builds',
      CREATE: 'Ready to create ',
      QUEUED: 'Queued',
      ACTIONS: 'Actions',
      TIMEOUT: 'Timeout',
    },
    Header: {
      HELLO: 'Hi'
    },
    Home: {
      MENU_CARD: {
        BUILD: {
          TITLE: 'Builds',
          SUB_TITLE: 'Make your own staging version, update your microservices'
        },
        APP_CARE: {
          TITLE: 'App care',
          SUB_TITLE: 'Take an overview of our applications'
        },
        ADMIN: {
          TITLE: 'Admin',
          SUB_TITLE: 'Manange workspace'
        },
        APPLICATION: {
          TITLE: 'Application',
          SUB_TITLE: 'Manange application'
        },
        SERVICES: {
          TITLE: 'Services',
          SUB_TITLE: 'Execution of common  or update some parts of the systems'
        },
        APPROVE_CENTER: {
          TITLE: 'Approve Center',
          SUB_TITLE: 'Approve requests'
        }
      }
    },
    Build: {
      CLEAR_OPTIONS: 'Clear options',
      DEFAULT_OPTIONS: 'Default options',
      ENVIRONMENT: 'Environment',
      BRANCH: 'Branch',
      BRANCH_FROM_CORE: 'Branch from core',
      CANCEL_BUILD: 'Do you want to cancel the build(s)?',
      CONFIRM_RETRY_SYNTHETIC_TESTS: 'Want to repeat the synthetic tests?',
      CONFIRM_PROMOTE_VERSION: 'Want to upgrade the version?',
      CONFIRM_ROLLBACK_VERSION: 'Want to roll back the version?',
      STARTED_PROMOTED_AT: 'Started promoted at',
      ENDED_PROMOTED_AT: 'Ended promoted at',
      VERSION: 'Version',
      USERS: 'Users',
    },
    AppCare: {
      CHECK_ROUTES: {
        NAME: 'Check routes',
        TABLE: {
          HEADERS: {
            CREATED_AT: 'Created at',
            PROMOTED_AT: 'Promoted at',
            MODIFICATIONS: 'Modifications',
            FILTERS: {
              MODIFICATIONS: {
                TRUE: 'True',
                FALSE: 'False',
              }
            },
          },
          ROWS: {
            MODIFICATIONS: {
              TRUE: 'Has modifications',
              FALSE: 'Has no modifications'
            }
          },
          COLLAPSE_ROWS: {
            COMPARE:{
              MORE: 'Add',
              LESS: 'Removed',
              MODIFIED: 'Modified',
              EQUAL: 'Equal',
            }
          },
          COLLAPSE_HEADERS: {
            REQUIRE_AUTHENTICATION: 'Require authentication',
            MODIFICATIONS: 'Modifications',
          }
        }
      },
      STATUS_PAGE: {
        NAME: 'Status Page',
        SHOWCASE: 'Showcase',
        DEBUG: 'Open GCP Logs',
        STATUS: {
          OPERATIONAL: 'Operational',
          UNDER_MAINTENANCE: 'Under Maintenance',
          DEGRADED_PERFORMANCE: 'Degraded Performance',
          PARTIAL_OUTAGE: 'Partial Outage',
          MAJOR_OUTAGE: 'Major Outage'
        },
        MESSAGE: {
          SUCCESS: 'Component Created Successfully',
          EDIT: 'Successfully Edited',
          DELETE: 'Successfully Deleted'
        },
        TABLE: {
          ENDPOINT: 'Endpoint',
          SUBCOMPONENTS: 'Subcomponents',
          SLO: 'SLO',
          STATUS: 'Status',
          GROUP: 'Groups',
          DEBUG: 'Debug',
        },
        FORMULARIO: {
          NAME_COMPONENT: 'Component Name'
        },
        COMPONENT: 'Component',
        NEW_NAME: 'New Name',
        EDIT_GROUP: 'Edit Group'
      }
    },
    Admin: {
      MENU_CARD: {
        USERS: {
          TITLE: 'Users',
          SUB_TITLE: 'Manage users'
        },
        ENVIRONMENTS: {
          TITLE: 'Environments',
          SUB_TITLE: 'Manage environments'
        },
        CLOUD_FUNCTIONS: {
          TITLE: 'Cloud Functions',
          SUB_TITLE: 'Manage cloud functions'
        },
        SECRETS: {
          TITLE: 'Secrets',
          SUB_TITLE: 'Manage secrets'
        },
        APP_ENGINE: {
          TITLE: 'App Engine',
          SUB_TITLE: 'Manage GAE',
        },
      },
      IAM: {
        TITLE: 'IAM',
        USERS: 'Users',
        ROLES: 'Roles',
        MODAL: {
          NAME: 'Name',
          ASSIGNED_PERMISSIONS: 'Assigned permissions',
          ADD_ROLE: 'Add role',
          EDIT_ROLE: 'Edit role',
          DELETE_ROLE: 'Delete role',
        },
        TABLE: {
          ROLES: {
            NAME: 'Name',
            PERMISSIONS: 'Permissions'
          }
        }
      },
      MICROSERVICES: {
        TITLE: 'Microservices',
        CLOUD_FUNCTIONS: 'Cloud functions',
        CLOUD_RUN: 'Cloud run'
      },
      SECRETS: {
        MODAL: {
          ADD: {
            BTN_CONFIM: 'Confirm',
            TITLE: 'Add secret',
            NAME: 'Name',
            VALUE: 'Value',
            PROJECT: 'Project',
            HELPER_TEXT_NAME: 'Name is required',
            HELPER_TEXT_VALUE: 'Value is required',
            HELPER_TEXT_PROJECT: 'Project is required',
          },
          EDIT: {
            BTN_CONFIM: 'Confirm',
            TITLE: 'Update secret',
            NAME: 'Name',
            VALUE: 'Value',
            PROJECT: 'Project',
            HELPER_TEXT_VALUE: 'Value is required',
          },
          DELETE: {
            BTN_CONFIM: 'Confirm',
            TITLE: 'Delete secret',
            RESPONSE_SECRETS_IN_USE_CF: 'Secret {{secretName}} is in use in cloud function {{cfName}} with env {{envName}}',
            RESPONSE_SECRETS_IN_USE_CR: 'Secret {{secretName}} is in use in cloud run {{crName}} with env {{envName}}',
          },
        }
      },
      APP_ENGINE: {
        NAME: 'App Engine',
        PREFIX: 'Prefix',
        CORE_REQUIRED: 'Core required',
        BTN_SAVE: 'Save',
        REPOSITORY: 'Repository',
      },
      ENVIRONMENTS: {
        TABLE: {
          ENVIRONMENTS: 'Environments',
          CLOUD_FUNCTIONS: 'Cloud functions',
          ADD: 'Add',
          EDIT: 'Edit',
          DELETE: 'Delete',
        },
        SECRETS: {

        },
        ENVIRONMENT: 'Environment',
        RUNTIME: 'Runtime',
        CANCEL: 'Cancel',
        REMOVE: 'Remove',
        REMOVE_ENVIRONMENT: 'Remove environment',
        CLOUD_FUNCTION_NOT_SELECTED: 'Cloud function not selected'
      },
      CLOUD_FUNCTIONS: {
        TABLE: {
          CLOUD_FUNCTION: 'Cloud function',
          NAME: 'Name',
          MEMORY: 'Memory',
          RUNTIME: 'Runtime',
          TIMEOUT: 'Timeout',
          ADD: 'Add',
          EDIT: 'Edit',
          DELETE: 'Delete',
          GENERATION: 'Generation',
          REPOSITORY: 'Repository',
          REGION: 'Region',
          INSTANCES: 'Instances',
          PREFIX: 'Prefix',
          CORE_REQUIRED: 'Core required',
          DEFAULT_DEPLOYMENT: 'Default deployment',
        },
        REMOVE: 'Remove',
        REMOVE_CLOUD_FUNCTION: 'Remove cloud function',
        CREATE: 'Create',
        CREATE_CLOUD_FUNCTION: 'Create cloud function',
        CANCEL: 'Cancel',
        MAX_INSTANCES: 'Max Instances',
        PROJECT: 'Project',
        SAVE: 'Save',
      },
      CLOUD_RUN: {
        TABLE: {
          NAME: 'Name',
          MEMORY: 'Memory',
          RUNTIME: 'Runtime',
          TIMEOUT: 'Timeout',
          ADD: 'Add',
          EDIT: 'Edit',
          DELETE: 'Delete',
          GENERATION: 'Generation',
          REPOSITORY: 'REPOSITORY',
          REGION: 'Region',
          MIN_INSTANCES: 'Minimun number of instances',
          MAX_INSTANCES: 'Maximum number of instances',
          AUTHENTICATION: 'Authentication',
          CONCURRENCY: 'Maximum requests per container',
          CPU: 'CPU',
          PREFIX: 'Prefix',
          CLOUD_SQL: 'Cloud SQL instances',
          CORE_REQUIRED: 'Core required',
          DEFAULT_DEPLOYMENT: 'Default deployment',
          VPC_CONNECTOR: 'VPC connector',
        },
        RADIO_GROUP: {
          AUTHENTICATION: {
            REQUIRED: 'Require authentication',
            NOT_REQUIRED: 'Allow unauthenticated invocations',
          }
        },
        NOT_SELECTED: 'Cloud run not selected',
        REMOVE: 'Remove',
        REMOVE_CLOUD_RUN: 'Remove cloud run',
        CANCEL: 'Cancel',
        MAX_INSTANCES: 'Max Instances',
        PROJECT: 'Project',
        SAVE: 'Save',
      },
      USERS: {
        NO_USER_IDENTIFIED: 'No user identified',
        EDIT_USER: 'Edit user',
        EDIT_USERS: 'Edit users',
        DELETE_USER: 'Delete user',
        DELETE_USERS: 'Delete users',
        ENVIRONMENTS: 'Environments',
        ADD_USER: 'Add user',
        ADD: {
          SUCCESS: 'User successfully added',
        },
      }
    },
    Application: {
      MESSAGE: 'Message',
      CONFIGURATIONS: 'Configurations',
      VARIABLES: 'Variables',
      PROJECTS: {
        NAME: 'Projects',
        NEW_NAME: 'New Project',
        TABLE: {
          NAME: 'Name',
          PROJECT_ID: 'Project ID',
          PROJECT_NUMBER: 'Project number',
          IS_PRODUCTION: 'Production',
          ICON: 'Icon',
          SERVICE: 'Service',
          GIT: 'Git',
          USER: 'User',
          PIPELINE: 'Pipeline',
          STATUS: 'Status',
          TEMPLATE_URL_APP_ENGINE: 'Template url app engine',
          TEMPLATE_URL_CLOUD_RUN: 'Template url cloud run',
          TEMPLATE_URL_CLOUD_FUNCTION: 'Template url cloud function',
          APP_ENGINE: 'App Engine',
          ENVIRONMENT: 'Environment',
          DETAILS: 'Details'
        },
        MODAL: {
          ICON: 'Icon',
          NAME: 'Name',
          PROJECT_ID: 'Project ID',
          PROJECT_NUMBER: 'Project number',
          IS_PRODUCTION: 'Production',
          TEMPLATE_URL_APP_ENGINE: 'Template url app engine',
          TEMPLATE_URL_CLOUD_RUN: 'Template url cloud run',
          TEMPLATE_URL_CLOUD_FUNCTION: 'Template url cloud function',
          ADD: {
            TITLE: 'Add project',
            BTN_SAVE: 'Confirm',
          },
          EDIT: {
            TITLE: 'Edit project',
            BTN_SAVE: 'Save',
          },
          DELETE: {
            TITLE_one: 'Remove project',
            TITLE_other: 'Remove projects',
            BTN_SAVE: 'Delete',
          }
        }
      },
      ASSIGNED_PERMISSIONS: {
        NAME: 'Assigned permissions',
        TABLE: {
          PERMISSION: 'Permission',
        },
        MODAL: {
          PERMISSION: 'Permission',
          ADD: {
            TITLE: 'Add assigned permission',
            BTN_SAVE: 'Confirm',
          },
          EDIT: {
            TITLE: 'Edit assigned permission',
            BTN_SAVE: 'Save',
          },
          DELETE: {
            TITLE_one: 'Remove assigned permission',
            TITLE_other: 'Remove assigned permissions',
            BTN_SAVE: 'Delete',
          }
        }
      },
      REPOSITORIES: {
        NAME: 'Repositories',
        TABLE: {
          NAME: 'Name',
          GCP: 'GCP',
          BITBUCKET: 'Bitbucket',
          URL: 'Url',
        },
        MODAL: {
          NAME: 'Name',
          GCP: 'GCP',
          BITBUCKET: 'Bitbucket',
          URL: 'Url',
          ADD: {
            TITLE: 'Add repository',
            BTN_SAVE: 'Confirm',
          },
          EDIT: {
            TITLE: 'Edit repository',
            BTN_SAVE: 'Save',
          },
          DELETE: {
            TITLE_one: 'Remove repository',
            TITLE_other: 'Remove repositories',
            BTN_SAVE: 'Delete',
            RESPONSE_REPOSITORIES_IN_USE_CF: 'Repository {{repositoryName}} is in use in cloud function {{cfName}}',
            RESPONSE_REPOSITORIES_IN_USE_CR: 'Reposiory {{repositoryName}} is in use in cloud run {{crName}}',
          }
        },
      },
      SAVE: 'Save',
      STATUS: {
        RUNNING: 'Running',
        MAINTENANCE: 'Maintenance'
      }
    },
    APPROVE_CENTER: {
      PROJECTS: {
        TABLE: {
          USERS: 'Users',
          OWNER: 'Owner',
          TYPE: 'Type',
          STATUS: 'Status',
          ENVIRONMENT: 'Environment',
          APPROVED_BY: 'Approved By',
          ACTION_BY: 'Action By',
          WAIT_APPROVE: 'Waiting for Approval',
          ACTION: 'Action',
          SERVICE_DESK: {
            MESSAGE: 'Do you want to enable the service desk for the email(s):'
          }
        },
        MODAL: {
          TITLE: 'Request'
        },
        BUTTON: {
          OPEN_REQUEST: 'Open Request',
          REJECT: 'Reject',
          APPROVE: 'Approve'
        }
      }
    },
    Services: {
      CLOUD_RUN: {
        INFO_GENERATE_CODE: 'Update generate-code file',
        BUTTON_UPDATE: 'Update'
      },
      INFO_METADATA: 'Update metadata.js file',
      BUTTON_UPDATE: 'Update'
    },
    Routes: {
      admin: 'Admin',
      home: 'Home',
      build: 'Build',
      appCare: 'App care',
      users: 'Users',
      environments: 'Environments',
      cloudFunctions: 'Cloud functions',
      application: 'Application',
      services: 'Services',
      approveCenter: 'Approve Center',
    },
    Timestamp: {
      SECOND: 'second',
      SECONDS: 'seconds',
      MINUTE: 'minute',
      MINUTES: 'minutes',
      HOUR: 'hour',
      HOURS: 'hours',
      SECONDS_one: 'second',
      SECONDS_other: 'seconds',
      MINUTES_one: 'minute',
      MINUTES_other: 'minutes',
      HOURS_one: 'hour',
      HOURS_other: 'hours',
      STARTED: 'Started',
      FINISHED: 'Finished',
      DAYS: 'days',
      DAY: 'day',
      DAYS_other: 'days',
      DAYS_one: 'day',
    },
    COMPONENTS: {
      WTABLE: {
        HEADER: {
          FILTERS:{
            LIKE: 'Like',
            NOT_LIKE: 'Not like',
            EQUAL: 'Equal',
            NOT_EQUAL: 'Not equal',
            LESS_THAN_EQUAL: 'Less than equal',
            LESS: 'Less',
            MORE: 'More',
            MORE_THAN_EQUAL: 'More than equal',
            CLEAN: 'Clean',
          }
        },
        FOOTER:{
          ROWS_PER_PAGE: 'Rows per page'
        }
      },
      WSECRETS: {
        TITLE: 'Secrets',
        ADD: 'Add',
        NOT_SAVED: 'Not saved',
        LABEL: {
          SECRET: 'Secret',
          NAME: 'Name',
          VERSION: 'Version',
          DONE: 'Done',
        },
        ACCORDION_EXPANDED: {
          NEW: 'Reference a Secret',
          EDIT: 'Edit a Secret reference',
        },
        ERRORS: {
          ENV_REQUIRED: 'env required',
          VERSION_REQUIRED: 'value required',
          SECRET_REQUIRED: 'secret required'
        },
      },
      WENVIRONMENTVARIABLE: {
        TITLE: 'Environment variables',
        ADD: 'Add',
      },
      WKEYVALUE: {
        LABEL: {
          ENV: 'Env',
          VALUE: 'Value',
        }
      },
      WGSUTIL: {
        TITLE: 'Copy files',
        ADD: 'Add',
        SOURCE: 'Source',
        DEST: 'Dest',
      },
    }
  }
}