import React, { useState, useEffect, useContext } from 'react'
import ItemList from './ItemList'
import {
  List,
  Stack,
} from '@mui/material'
import { Context } from '../../context/authContext'

const WPageSideBar = (props) => {
  const {
    items,
  } = props

  const { permissions } = useContext(Context)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize, false)

    return () => window.removeEventListener('resize', handleResize, false)
  }, [])

  return (
    <List
      component={Stack}
      direction={width < 640 ? 'row' : 'column'}
      className={'flex bg-greyBlack max-w-[250px] text-white'}

    >
      {
        Object.keys(items)?.map((key, index) => (
          (!items[key].permissions || items[key].permissions.find(perm => permissions?.includes(perm))) ?
            <ItemList
              key={index}
              label={items[key]?.title}
              icon={items[key]?.icon}
              subItems={items[key]?.subPaths}
              path={items[key]?.getRoute()}
            />
            :
            <></>
        ))
      }
    </List>
  )
}

export default WPageSideBar