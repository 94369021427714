import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../pages/login'
import Home from '../pages/home'
import Build from '../pages/build'
import Services from '../pages/services'
import AppCare from '../pages/app-care'
import Application from '../pages/application'
import ApproveCenter from '../pages/approve-center'
import { routes } from '../pages/routes'
import Users from '../pages/admin/iam/users'
import Environments from '../pages/admin/environments'
import CloudFunctions from '../pages/admin/microservices/cloud-functions'
import PrivateRoute from './PrivateRoute'
import WLayout from '../components/WLayout'
import WPageLayout from '../components/WPageLayout'
import Configurations from '../pages/application/Configurations'
import Variables from '../pages/application/Variables'
import GenerateCode from '../pages/services/CloudRun/Generate-code'
import Roles from '../pages/admin/iam/roles'
import { Context } from '../context/authContext'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import CheckRoutes from '../pages/app-care/check-routes'
import StatusPage from '../pages/app-care/status-page'
import Secrets from '../pages/admin/secrets'
import CloudRun from '../pages/admin/microservices/cloud-run'
import ProjectsPage from '../pages/application/Projects'
import AssignedPermissionsPage from '../pages/application/AssignedPermissions'
import RepositoriesPage from '../pages/application/Repositories'
import AppEnginePage from '../pages/admin/app-engine'
import MetadataPage from '../pages/services/metadata'

const CWRoutes = () => {
  const { globalLoading, authenticated } = useContext(Context)

  if (globalLoading)
    return <Loading />

  return (
    <>
      <Routes>
        {
          authenticated ?
            <Route element={<WLayout />} >
              <Route path='/home' element={<Home />} />
              <Route element={<PrivateRoute permission={routes.home.subPaths.appCare.permissions} />}>
                <Route
                  path='/app-care'
                  element={
                    <WPageLayout
                      itemsMenu={routes.home.subPaths.appCare.subPaths}>
                      <AppCare />
                    </WPageLayout>
                  }>
                  <Route path='/app-care/check-routes'
                    element={<CheckRoutes />}
                  >
                  </Route>
                  <Route path='/app-care/status-page'
                    element={<StatusPage />}
                  >
                  </Route>
                </Route>
              </Route>
              <Route element={<PrivateRoute permission={routes.home.subPaths.build.permissions} />}>
                <Route path='/build' element={<Build />}/>
              </Route>
              <Route element={<PrivateRoute permission={routes.home.subPaths.admin.permissions} />}>
                <Route
                  path='/admin'
                  element={
                    <WPageLayout
                      itemsMenu={routes.home.subPaths.admin.subPaths}
                    >
                    </WPageLayout>
                  }>

                  <Route path='/admin/iam'>
                    <Route
                      path='/admin/iam/users'
                      element={<Users />}
                    />
                    <Route
                      path='/admin/iam/roles'
                      element={<Roles />}
                    />
                  </Route>

                  <Route path='/admin/microservices'>
                    <Route
                      path='/admin/microservices/cloud-functions'
                      element={<CloudFunctions />}
                    />
                    <Route
                      path='/admin/microservices/cloud-run'
                      element={<CloudRun />}
                    />
                  </Route>

                  <Route
                    path='/admin/environments'
                    element={<Environments />}
                  />

                  <Route
                    path='/admin/secrets'
                    element={<Secrets />}
                  />

                  <Route
                    path='/admin/app-engine'
                    element={<AppEnginePage />}
                  />
                </Route>
              </Route>

              <Route element={<PrivateRoute permission={routes.home.subPaths.application.permissions} />}>
                <Route
                  path='/application'
                  element={
                    <WPageLayout
                      itemsMenu={routes.home.subPaths.application.subPaths}>
                      <Application />
                    </WPageLayout>
                  }>
                  <Route
                    path='/application/configurations'
                    element={<Configurations />}
                  />
                  <Route
                    path='/application/variables'
                    element={<Variables />}
                  />
                  <Route
                    path='/application/projects'
                    element={<ProjectsPage />}
                  />
                  <Route
                    path='/application/assigned-permissions'
                    element={<AssignedPermissionsPage />}
                  />
                  <Route
                    path='/application/repositories'
                    element={<RepositoriesPage />}
                  />
                </Route>
              </Route>

              <Route element={<PrivateRoute permission={routes.home.subPaths.services.permissions} />}>
                <Route
                  path='/services'
                  element={
                    <WPageLayout
                      itemsMenu={routes.home.subPaths.services.subPaths}>
                      <Services />
                    </WPageLayout>
                  }>
                  <Route
                    path='/services/metadata'
                    element={<PrivateRoute permission={routes.home.subPaths.services.subPaths.cloudRun.subPaths.generateCode.permissions} />}
                  >
                    <Route
                      path='/services/metadata'
                      element={<MetadataPage />}
                    />
                  </Route>
                  <Route
                    path='/services/cloud-run'
                  >
                    <Route
                      path='/services/cloud-run/generate-code'
                      element={<PrivateRoute permission={routes.home.subPaths.services.subPaths.cloudRun.subPaths.generateCode.permissions} />}
                    >
                      <Route
                        path='/services/cloud-run/generate-code'
                        element={<GenerateCode />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route element={<PrivateRoute permission={routes.home.subPaths.approveCenter.permissions} />}>
                <Route
                  path='/approve-center'
                  element={
                    <ApproveCenter />
                  }>
                </Route>
              </Route>

              <Route path='*' element={<Navigate to='/home'/>} />
            </Route>
            :
            <>
              <Route path='/login' element={<Login />} />
              <Route path='*' element={<Navigate to='/login'/>} />
            </>
        }
      </Routes>
    </>
  )
}

export default CWRoutes