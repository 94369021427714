import React from 'react'
import DebugUrl from './DebugUrl'
import ButtonUrlBuild from './ButtonUrlBuild'
import DetailsTimestamp from './DetailsTimestamp'

const Details = props => {
  const {
    debugUrlAppEngine,
    isAppEngine,
    status,
    url,
    createdTime,
    startTime,
    finishTime,
    project,
    disabled
  } = props

  return (
    <div className='flex justify-center items-center h-full'>

      <div className='flex justify-center items-center space-x-4'>
        <DebugUrl tooltip={project} url={debugUrlAppEngine} disabled={disabled}/>
        <ButtonUrlBuild
          isAppEngine={isAppEngine}
          status={status}
          url={url}
          disabled={disabled}
        />
        <DetailsTimestamp
          createdTime={createdTime}
          startTime={startTime}
          finishTime={finishTime}
          status={status}
        />
      </div>
    </div>
  )
}

export default Details