import React, { useState } from 'react'
import I18n from '../../../../components/I18n'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

const EditSecret = props => {
  const {
    onCloseModal,
    selectedRow,
    openModal
  } = props

  const [value, setValue] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [valueError, setValueError] = useState(false)

  let obj = {}
  selectedRow?.cells?.map(i => ({ [i.key]: i.value })).forEach(element => {
    Object.assign(obj, element)
  })

  const handleConfirm = async () => {
    setValueError(!value?.length)

    if (!value?.length)
      return

    setIsLoading(true)

    await api({
      method: 'PATCH',
      url: `secrets/${obj.id}`,
      data: {
        value,
      }
    }).then(({ data }) => {
      showResponseSuccess(data?.message)
      onCloseModal({ needReloadSecrets: true })
    }).catch(error => showResponseError(error))

    setIsLoading(false)
  }

  return (
    <WCustomModal
      buttonLabel={'Save'}
      title={<I18n path='Admin.SECRETS.MODAL.EDIT.TITLE'/>}
      open={openModal}
      handleClose={onCloseModal}
      buttonOnConfirm={handleConfirm}
      icon={'edit'}
      loading={isLoading}
      disabled={false}
      element={
        <div className='flex flex-col w-80 h-80 justify-center items-center gap-y-5 p-4'>
          <div className='w-full'>
            <WTextFieldV2
              label={<I18n path='Admin.SECRETS.MODAL.EDIT.NAME'/>}
              value={obj.name}
              className='opacity-50'
              variant='standard'
            />
          </div>
          <div className='w-full'>
            <WTextFieldV2
              label={<I18n path='Admin.SECRETS.MODAL.EDIT.VALUE'/>}
              value={value}
              error={valueError}
              helperText={<I18n path='Admin.SECRETS.MODAL.EDIT.HELPER_TEXT_VALUE'/>}
              required
              onChange={ev => setValue(ev.target.value)}
              variant='standard'
            />
          </div>
        </div>
      }
    />
  )
}

export default EditSecret