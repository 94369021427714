import React, { useEffect, useRef, useState } from 'react'
import WTable from '../../../components/WTable/v2'
import useApi from '../../../context/hooks/useApi'
import { IForwardRefV2 } from '../../../components/WTable/v2/table'
import { IRepository } from './modal/FormData'
import I18n from '../../../components/I18n'
import AddRepository from './modal/addRepository'
import EditRepository from './modal/editRepository'
import DelRepository from './modal/delRepository'

interface IDataRepositories {
  id: string
  name: string
  gcp: string
  bitbucket: string
  url: string
}

export interface IRepositories {
  data: IDataRepositories[]
  count: number
}

interface IRows {
  isCollapse: boolean
  isSelectable: boolean
  cells: { key: string, value: string | JSX.Element }[]
}

const RepositoriesPage = () => {
  const [rows, setRows] = useState<IRows[]>([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filters, setFilters] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [typeModal, setTypeModal] = useState('')

  const { data: dataRepositories, isLoading: isloadingRepositories, mutate: mutateRepositories } = useApi<IRepositories>({ url: `repositories?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })

  const WTableRef = useRef<IForwardRefV2<IRepository>>(null)

  const headers = [
    {
      key: 'id',
      value: 'Id',
      align: 'center',
      hidden: true
    },
    {
      key: 'name',
      value: <I18n path='Application.REPOSITORIES.TABLE.NAME'/>,
      align: 'center',
    },
    {
      key: 'gcp',
      value: <I18n path='Application.REPOSITORIES.TABLE.GCP'/>,
      align: 'center'
    },
    {
      key: 'bitbucket',
      value: <I18n path='Application.REPOSITORIES.TABLE.BITBUCKET'/>,
      align: 'center'
    },
    {
      key: 'url',
      value: <I18n path='Application.REPOSITORIES.TABLE.URL'/>,
    }
  ]

  useEffect(() => {
    if (dataRepositories) {

      const newRows = dataRepositories.data?.map(i => ({
        isCollapse: false,
        isSelectable: true,
        cells: [
          {
            key: 'id',
            value: i.id,
          },
          {
            key: 'name',
            value: i.name,
          },
          {
            key: 'gcp',
            value: i.gcp
          },
          {
            key: 'bitbucket',
            value: i.bitbucket
          },
          {
            key: 'url',
            value: i.url
          },
        ]
      }))

      setRows(newRows)
      setCount(dataRepositories.count)
    }
  }, [dataRepositories])

  const onOpenModal = (type: string) => {
    setTypeModal(type)
    setOpenModal(true)
  }

  const onCloseModal = () => {
    WTableRef.current?.clearCheckedValues()
    setOpenModal(false)
  }

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder: string, newOrderBy: string) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = (filters: string) => {
    setFilters(filters)
  }

  const permissionsTableHeader = {
    add: 'application.repositories.post',
    edit: 'application.repositories.put',
    remove: 'application.repositories.delete',
  }

  return (
    <div className='flex w-[99%] max-h-full'>
      {
        openModal &&
        <>
          {
            typeModal === 'add' &&
            <AddRepository
              onCloseModal={onCloseModal}
              mutate={mutateRepositories}
            />
          }
          {
            typeModal === 'edit' &&
            <EditRepository
              mutate={mutateRepositories}
              onCloseModal={onCloseModal}
              id={WTableRef.current?.getIdCheckedValues()[0] ?? ''}
            />
          }
          {
            typeModal === 'delete' &&
            <DelRepository
              onCloseModal={onCloseModal}
              repositories={WTableRef.current?.getCheckedRows() ?? []}
              mutate={mutateRepositories}
            />
          }
        </>
      }
      <WTable
        ref={WTableRef}
        isLoadingData={isloadingRepositories}
        headers={headers}
        rows={rows}
        isCollapse={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        countRows={count}
        limit={10}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        onChangeFilters={handleChangeFilters}
        tableHeader
        permissionsTableHeader={permissionsTableHeader}
        onAddNewRow={() => onOpenModal('add')}
        onEditRow={() => onOpenModal('edit')}
        onDeleteRow={() => onOpenModal('delete')}
      />
    </div>
  )
}


export default RepositoriesPage