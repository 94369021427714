import React from 'react'
import Select from 'react-select'


import {
  defaultTheme,
  customStyles,
  Control,
  LoadingMessage,
  NoOptionsMessage,
} from '../../ReactSelect/components'

const WSelectField = (props) => {
  const {
    theme,
    components,
    className,
    id,
    clearable,
    disabled,
    ...selectSearchProps
  } = props

  const customTheme = {
    ...defaultTheme,
    ...theme
  }

  const customClassName = `wselect ${className} text-base`

  return (
    <Select
      id={`wselect-${id}`}
      theme={customTheme}
      className={customClassName}
      classNamePrefix='wselect'
      styles={{
        ...customStyles,
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999, backgroundColor: '#111111' }),
        input: provided => ({
          ...provided,
          color: 'white',
          paddingBottom: '2px'
        }),
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      isDisabled={disabled}
      isClearable={clearable}
      components={{
        Control,
        NoOptionsMessage,
        LoadingMessage,
        ...components
      }}
      {...selectSearchProps}
    />
  )
}


export default WSelectField