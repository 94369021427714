import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@clinicorp/designsystem.components.basic.buttons.button'
import { Context } from '../../context/authContext'

const Login = () => {
  const navigate = useNavigate()
  const { authenticated, handleLogin } = useContext(Context)
  const [loading, setLoading] = useState(false)

  if (authenticated)
    return navigate('/home')

  const handleSubmitLogin = () => {
    setLoading(true)
    handleLogin()
      .then(() => navigate('/'))
      .finally(() => setLoading(false))
  }

  return (
    <div className='h-full flex flex-col'>
      <div className='w-full h-[3%] bg-[var(--NewOrangeClinicorp)]'></div>
      <div className='w-full flex justify-center items-center h-full'>
        <div className='card-left h-[70vh] px-[60px] w-[600px] border-solid border border-[var(--NewOrangeClinicorp)]' style={{backdropFilter: ' blur(20px)'}}>
          <div className='flex h-[28%] w-[80%] justify-start my-0 mx-auto'>
            <img src='static/img/logotipo-branco.svg' style={{ width: '300px' }}></img>
          </div>
          <div className='h-[58%] my-0 mx-auto w-full flex justify-center'>
            <img src='static/img/simbolo-branco.svg' style={{ width: '73px', marginBottom: '110px' }}></img>
          </div>
          <div>
            <p style={{ color: '#8D8C8B', textAlign: 'center' }}>This software is for the exclusive use of clinicorp solutions.</p>
            <p style={{ textAlign: 'center', color: '#8D8C8B', marginBottom: '50px' }}>More infos on clinicorp.com</p>
          </div>
        </div>
        <div className='bg-[var(--NewOrangeClinicorp)] m-0 h-[70vh] flex flex-col p-[20px] items-center justify-between max-w-[420px]'>
          <div className='flex flex-wrap'>
            <div className='pt-[32px] w-full h-[15%]'>
              <h3 className='text-white text-[28px] font-bold pb-[20px]'>Hello, Welcome back developer!</h3>
            </div>
          </div>
          <div className='mb-[50px]'>
            <Button
              secondary
              className='hover:bg-[#424242]'
              label='Google'
              disabled={loading}
              iconChildren={<div style={{ display: 'flex' }}><img style={{ height: '20px', width: '20px' }} src='Google.png' alt='' /></div>}
              onClick={handleSubmitLogin}
              style={{ borderWidth: '0',backgroundColor: 'var(--GreyBlack)', borderRadius: '20px', color: 'white', width: '150px', height: '45px' }}
            >
              <div style={{ display: 'flex' }}>
                <img style={{ height: '20px', width: '20px' }} src='Google.png' alt='' />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
