import React, { useEffect, useRef, useState } from 'react'
import I18n from '../../../../components/I18n'
import WTable from '../../../../components/WTable/v2'
import api from '../../../../context/apiContext'
import useApi from '../../../../context/hooks/useApi'
import { showError, showResponseError, showResponseSuccess } from '../../../../helper'
import WModal from '../../../../components/WModal/V2'
import FormData from './FormData'
import WCustomModal from '../../../../components/WModal/V3'

const CloudRun = () => {


  const [rows, setRows] = useState([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filters, setFilters] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [showCreateCloudRunmodal, setShowCreateCloudRunmodal] = useState()
  const [showEditCloudRunmodal, setShowEditCloudRunmodal] = useState()
  const [showDelCloudRunModal, setShowDelCloudRunModal] = useState()
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [cloudRundByID, setCloudRundByID] = useState()

  const { data: dataCloudRun, isLoading: isloadingCloudRun, mutate: mutateCloudRun } = useApi({ url: `cloud-run?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })

  const WTableRef = useRef()

  const headers = [
    {
      key: 'id',
      value: 'Id',
      align: 'center',
      hidden: true
    },
    {
      key: 'name',
      value: 'Nome',
      align: 'center',
      hidden: false,
      sortable: true,
      searchable: true,
    },
    {
      key: 'memory',
      value: 'Memory',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'cpu',
      value: 'Cpu',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'region',
      value: 'Region',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'timeout',
      value: 'Timeout',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'repository',
      value: 'Repository',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'concurrency',
      value: 'Concurrency',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'authenticationRequired',
      value: 'Authentication required',
      align: 'center',
      sortable: true,
      searchable: true,
    },
    {
      key: 'accountService',
      value: 'Account service',
      align: 'center',
      sortable: true,
      searchable: true,
    },
  ]

  useEffect(() => {
    if (dataCloudRun) {
      const newRows = dataCloudRun.data?.map(i => ({
        isCollapse: false,
        isSelectable: true,
        cells: [
          {
            key: 'id',
            value: i.id,
          },
          {
            key: 'name',
            value: i.name,
          },
          {
            key: 'memory',
            value: i.memory,
          },
          {
            key: 'cpu',
            value: i.cpu,
          },
          {
            key: 'region',
            value: i.region,
          },
          {
            key: 'timeout',
            value: i.timeout,
          },
          {
            key: 'repository',
            value: i.repository?.name,
          },
          {
            key: 'concurrency',
            value: i.concurrency,
          }, {
            key: 'authenticationRequired',
            value: i.authenticationRequired?.toString(),
          },
          {
            key: 'accountService',
            value: i.accountService,
          }
        ]
      }))

      setRows(newRows)
      setCount(dataCloudRun.count)
    }
  }, [dataCloudRun])

  const onChangePage = (newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder, newOrderBy) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = filters => {
    setFilters(filters)
  }

  const onAddNewRow = () => {
    setShowCreateCloudRunmodal(true)
    setCloudRundByID()
  }

  const onDeleteRow = ev => {
    const selectedRowsOnDelete = rows.filter((_, index) => ev.includes(index))
    setSelectedRows(selectedRowsOnDelete)
    setShowDelCloudRunModal(true)
  }

  const onEditRow = () => {
    const id = WTableRef.current.getIdCheckedValues()[0]
    setCloudRundByID(id)
    setShowCreateCloudRunmodal(true)

  }

  const permissionsTableHeader = {
    add: 'microservices.cloud_run.post',
    edit: 'microservices.cloud_run.put',
    remove: 'microservices.cloud_run.delete',
  }

  const handleDelCloudRun = () => {
    const ids = WTableRef.current.getIdCheckedValues().join(',')

    if (!ids?.length) {
      showError('Admin.MICROSSERVICES.CLOUD_RUN.NOT_SELECTED')
      return
    }

    setIsLoadingModal(true)
    api({
      method: 'DELETE',
      url: 'cloud-run',
      params: {
        ids
      }
    }).then(async ({ data }) => {
      await mutateCloudRun()
      showResponseSuccess(data?.message)
    }).catch(error => showResponseError(error))
      .finally(() => {
        handleCloseModal()
        setIsLoadingModal(false)
      })
  }

  const handleCloseModal = () => {
    WTableRef.current?.clearCheckedValues()
    setShowDelCloudRunModal(false)
    setShowCreateCloudRunmodal(false)
    setShowEditCloudRunmodal(false)
  }

  return (
    <div
      className='flex w-[99%] max-h-full'
    >
      {
        showDelCloudRunModal &&
        <>
          <WCustomModal
            title={<I18n path='Admin.CLOUD_RUN.REMOVE_CLOUD_RUN'/>}
            loading={isLoadingModal}
            open={showDelCloudRunModal}
            buttonOnConfirm={handleDelCloudRun}
            handleClose={handleCloseModal}
            icon={'delete'}
            buttonLabel={'Remove'}
            element={
              <div className='flex text-white'>
                  Cloud Run:
                <p>
                  {selectedRows.map(i => (
                    <li key={i.cells[0]?.id} className='ml-2'>
                      {i.cells[1]?.value}
                    </li>
                  ))}
                </p>
              </div>
            }
          />
        </>
      }
      {
        (showCreateCloudRunmodal || showEditCloudRunmodal) &&
        <WModal
          openModal={(showCreateCloudRunmodal || showEditCloudRunmodal)}
          onCloseModal={handleCloseModal}
          style={{ backdropFilter: 'blur(5px)' }}
        >
          <FormData
            onSave={handleCloseModal}
            id={cloudRundByID}
            onClose={handleCloseModal}
            isloading={isLoadingModal}
          />
        </WModal>
      }

      <WTable
        ref={WTableRef}
        isLoadingData={isloadingCloudRun}
        headers={headers}
        rows={rows}
        isCollapse={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        countRows={count}
        limit={10}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        onChangeFilters={handleChangeFilters}
        tableHeader
        permissionsTableHeader={permissionsTableHeader}
        onAddNewRow={onAddNewRow}
        onEditRow={ev => onEditRow(ev)}
        onDeleteRow={ev => onDeleteRow(ev)}
      />

    </div>
  )
}

export default CloudRun