import React, { useState } from 'react'
import AccordionSecret from './AccordionSecret'
import WButton from '../WButton/Icon'
import I18n from '../I18n'

const WSecrets = (props) => {
  const {
    loadSecrets,
    secrets,
    onPushMoreSecret,
    onSliceSecret,
    onUpdate,
    oldListSecrets,
  } = props

  const [expanded, setExpanded] = useState()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleSliceSecret = (value) => {
    setExpanded(false)
    onSliceSecret(value)
  }

  const handleDone = (data) => {
    setExpanded(false)
    if (typeof onUpdate === 'function')
      onUpdate(data)
  }

  return (
    <div>
      <p><I18n path='COMPONENTS.WSECRETS.TITLE'/></p>
      {
        loadSecrets?.map((accordion, index) => (
          <AccordionSecret
            key={`${accordion.id}-${index}`}
            id={`${accordion.id}-${index}`}
            selected={accordion.secrets?.id}
            envId={accordion.id}
            envVar={accordion.env}
            envValue={accordion.value}
            expanded={expanded}
            secrets={secrets}
            handleChange={handleChange}
            onSliceSecret={() => handleSliceSecret(accordion)}
            oldListSecrets={oldListSecrets}
            onDone={handleDone}
          />
        ))
      }

      <WButton
        icon='add'
        iconColor='#fc673d'
        onClick={() => onPushMoreSecret()}
      />
    </div>
  )

}

export default WSecrets