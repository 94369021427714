import './style.scss'
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { List, ListItem } from '@react-md/list'
import WFiltersModal from './WFiltersModal'

const WList = (props, ref) => {
  const {
    list,
    visible,
    onAddSelectedItem
  } = props

  const [visibleFiltersModal, setVisibleFiltersModal] = useState(false)
  const [positionElement, setPositionElement] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const listRef = useRef()

  useEffect(() => {
    if (listRef.current) {
      if (visible)
        listRef.current.classList.add('active-list')
      else
        listRef.current.classList.remove('active-list')
    }

    if (!visible)
      setVisibleFiltersModal(false)

  }, [visible])

  useImperativeHandle(ref, () => ({
    element: listRef.current
  }))

  const openModal = (ev, item) => {
    if (item.children) {
      setVisibleFiltersModal(true)
      const positionElement = ev.target.nodeName !== 'LI'? ev.target.parentElement.getBoundingClientRect() : ev.target.getBoundingClientRect()
      setPositionElement(positionElement)
    } else if (typeof item.onClick === 'function') {
      item.onClick(ev)
      onAddItem(item)
    } else {
      onAddItem(item)
    }
    setSelectedItem(item)
  }

  const onAddItem = item => {

    setVisibleFiltersModal(false)
    if (typeof onAddSelectedItem === 'function')
      onAddSelectedItem(item)
  }

  return (
    <div className='list-main' ref={listRef}>
      <List>
        {list?.filter(item => item.label).map((item, index) => (
          <ListItem
            key={`${item.label}_${index}`}
            leftAddon={item.leftAddon}
            rightAddon={item.rightAddon}
            id={item.id ?? `${item.label}_${index}`}
            leftAddonPosition={item.leftAddonPosition ?? 'middle'}
            rightAddonPosition={item.rightAddonPosition ?? 'middle'}
            onClick={ev => openModal(ev, item)}
          >
            {item.label}
          </ListItem>
        ))}
      </List>
      {
        (visible && visibleFiltersModal) &&
        <WFiltersModal
          positionElement={positionElement}
          item={selectedItem}
          onAddItem={onAddItem}
        />
      }

    </div>
  )
}

export default forwardRef(WList)