import React from 'react'
import Icon from '@mui/material/Icon'
import I18n from '../../components/I18n'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import { useTranslation } from 'react-i18next'

const EnvironmentTooltip = props => {
  const { i18n } = useTranslation()
  const {
    environment,
    version,
    startedPromotedAt,
    endedPromotedAt,
  } = props

  return (
    <div>
      <div>
        {environment} {environment === 'production' ? version: null}
      </div>
      {
        (environment === 'production' && !isNaN(new Date(Number(startedPromotedAt)))) &&
        <>
          <div>
            <I18n path='Build.STARTED_PROMOTED_AT' />: {new Date(Number(startedPromotedAt)).toLocaleString(i18n.language)}
          </div>
          {
            !isNaN(new Date(Number(endedPromotedAt))) &&
          <div>
            <I18n path='Build.ENDED_PROMOTED_AT' />: {new Date(Number(endedPromotedAt)).toLocaleString(i18n.language)}
          </div>
          }
        </>
      }
    </div>
  )
}

const Environment = props => {
  const {
    environment,
    version,
    startedPromotedAt,
    endedPromotedAt,
  } = props

  return (
    <div className='flex flex-column w-52'>

      <Tooltip
        tooltip={
          <EnvironmentTooltip
            environment={environment[0]}
            startedPromotedAt={startedPromotedAt}
            endedPromotedAt={endedPromotedAt}
            version={version}
          />
        }
        tooltipStyle={{ fontSize: '12px' }}
      >
        <div className='flex ml-2 items-center'>
          <div className='flex justify-center align-middle'>
            <Icon fontSize='small'>cloud_upload</Icon>
          </div>
          <div className='flex w-full ml-2'>
            <span className='truncate xs:text-xs'>
              {environment[0]}
            </span>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default Environment