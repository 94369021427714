import React, { useEffect, useRef, useState } from 'react'
import I18n from '../../../../components/I18n'
import WSelectFieldV2 from '../../../../components/WSelectField/V2'
import useApi from '../../../../context/hooks/useApi'
import WSecrets from '../../../../components/WSecrets'
import { v4 as uuidv4 } from 'uuid'
import WEnvironmentVariable from '../../../../components/WEnvironmentVariable'
import WGsutil from '../../../../components/WGsutil'
import WRadioGroupAuthenticationV2 from '../../../../components/WRadioGroupAuthentication/V2'
import WHorizontalLinearStepper from '../../../../components/WHorinzontalLinearStepper'
import WTextFieldV2 from '../../../../components/WTextField/V2'
import WCheckbox from '../../../../components/WCheckbox'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'

const listMemory = [
  { label: '128Mb', value: '128MB' },
  { label: '256Mb', value: '256MB' },
  { label: '512Mb', value: '512MB' },
  { label: '1Gb', value: '1GB' },
  { label: '2Gb', value: '2GB' },
  { label: '4Gb', value: '4GB' },
  { label: '8Gb', value: '8GB' },
]

const listMemoryGen2 = [
  { label: '128Mi', value: '128Mi' },
  { label: '256Mi', value: '256Mi' },
  { label: '512Mi', value: '512Mi' },
  { label: '1Gi', value: '1Gi' },
  { label: '2Gi', value: '2Gi' },
  { label: '4Gi', value: '4Gi' },
  { label: '8Gi', value: '8Gi' },
  { label: '16Gi', value: '16Gi' },
  { label: '32Gi', value: '32Gi' },
]

const listRuntime = [
  { label: 'Nodejs14', value: 'nodejs14' },
  { label: 'Nodejs16', value: 'nodejs16' },
  { label: 'Nodejs18', value: 'nodejs18' },
  { label: 'Nodejs20', value: 'nodejs20' },
]

const listGeneration = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
]

const listRegions = [
  { label: 'us-east1', value: 'us-east1' },
  { label: 'us-central1', value: 'us-central1' }
]

const listMaxInstances = [
  { label: 100, value: 100 },
  { label: 300, value: 300 },
  { label: 700, value: 700 },
  { label: 1000, value: 1000 },
  { label: 1300, value: 1300 },
  { label: 1600, value: 1600 },
  { label: 2000, value: 2000 },
  { label: 3000, value: 3000 },
]

const FormData = (props) => {
  const {
    onSave,
    id
  } = props

  const [generation, setGeneration] = useState(listGeneration[0])
  const [newCloudFunction, setNewCloudFunction] = useState('')
  const [newMemory, setNewMemory] = useState()
  const [newRuntime, setNewRuntime] = useState(listRuntime[0])
  const [repository, setRepository] = useState({})
  const [repositories, setRepositories] = useState([])
  const [newTimeout, setNewTimeout] = useState(60)
  const [errorFieldCloudFunction, setErrorFieldCloudFunction] = useState('')
  const [errorFieldMemory, setErrorFieldMemory] = useState('')
  const [errorFieldRuntime, setErrorFieldRunTime] = useState('')
  const [errorFieldRegion, setErrorFieldRegion] = useState('')
  const [errorFieldGeneration, setErrorFieldGeneration] = useState('')
  const [errorFieldMaxInstances, setErrorFieldMaxInstances] = useState('')
  const [region, setRegion] = useState(listRegions[0])
  const [maxInstances, setMaxInstances] = useState(listMaxInstances[0])
  const [loadSecrets, setLoadSecrets] = useState([])
  const [oldListSecrets, setOldListSecrets] = useState([])
  const [loadEnvs, setLoadEnvs] = useState([])
  const [listGsutil, setListGsutil] = useState([])
  const [authenticationRequired, setAuthenticationRequired] = useState(false)
  const [vpcConnector, setVpcConnector] = useState('')

  const listMemoryByGeneration = () => generation?.value === 2 ? listMemoryGen2 : listMemory
  const [prefix, setPrefix] = useState('')
  const [coreRequired, setCoreRequired] = useState(false)
  const [defaultDeployment, setDefaultDeployment] = useState(false)

  const gsutilRef = useRef()
  const envsRef = useRef()
  const authenticationRef = useRef()

  const { data: dataSecrets } = useApi({ url: 'secrets' })
  const { data: dataRepositories } = useApi({ url: 'repositories' })
  const { data: dataCloudFunctionById, isLoading: isLoadingData } = useApi( {url: id ? `cloud-function/${id}` : ''})

  const handleSubmitCloudFunction = (data) => {
    const {
      name,
      memory,
      runtime,
      timeout,
      generation,
      repository,
      region,
      prefix,
      coreRequired,
      maxInstances,
      envs,
      gsutil,
      authenticationRequired,
      defaultDeployment,
      vpcConnector
    } = data
    const method = id ? 'PUT' : 'POST'

    api({
      method: method,
      url: `cloud-function${id ? `/${id}`: ''}`,
      data: {
        name,
        memory,
        runtime,
        timeout,
        gen: generation,
        repository,
        region,
        prefix,
        coreRequired,
        maxInstances,
        envs,
        gsutil,
        authenticationRequired,
        defaultDeployment,
        vpcConnector
      }
    }).then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => showResponseError(error))
      .finally(() => onSave())
  }

  const clearErrors = () => {
    setErrorFieldCloudFunction('')
    setErrorFieldMemory('')
    setErrorFieldRunTime('')
    setErrorFieldRegion('')
    setErrorFieldGeneration('')
    setErrorFieldMaxInstances('')
  }

  useEffect(() => {
    if (dataRepositories) {
      const newRepos = dataRepositories.data?.map(i => ({ value: i.id, label: i.name }))
      setRepositories(newRepos)
    }
  }, [dataRepositories])

  useEffect(() => {
    if (dataCloudFunctionById) {
      setGeneration(listGeneration.find(i => i.value === dataCloudFunctionById.gen))
      setNewCloudFunction(dataCloudFunctionById.name)
      setNewRuntime(listRuntime.find(i => i.value === dataCloudFunctionById.runtime))
      setMaxInstances(listMaxInstances.find(i => i.value === dataCloudFunctionById.maxInstances))
      setNewMemory([...dataCloudFunctionById.gen === 2 ? listMemoryGen2 : listMemory].find(i => i.value === dataCloudFunctionById.memory))
      setRegion(listRegions.find(i => i.value === dataCloudFunctionById.region))
      setLoadSecrets(dataCloudFunctionById.envs.filter(env => env.secrets))
      setOldListSecrets(dataCloudFunctionById.envs.filter(env => env.secrets))
      setLoadEnvs(dataCloudFunctionById.envs.filter(env => !env.secrets))
      setNewTimeout(dataCloudFunctionById.timeout)
      setCoreRequired(dataCloudFunctionById.coreRequired)
      setDefaultDeployment(dataCloudFunctionById.defaultDeployment)
      setListGsutil(dataCloudFunctionById.gsutil)
      setPrefix(dataCloudFunctionById.prefix)
      setAuthenticationRequired(dataCloudFunctionById.authenticationRequired)
      setVpcConnector(dataCloudFunctionById.vpcConnector)

      if (dataCloudFunctionById.repository)
        setRepository({ value: dataCloudFunctionById.repository.id, label: dataCloudFunctionById.repository.name })
    }
  }, [dataCloudFunctionById])

  useEffect(() => {
    if (!listMemoryByGeneration().some(i => i.value === newMemory?.value))
      setNewMemory(listMemoryByGeneration()[0])
  }, [generation.value])

  const validateFields = () => {
    clearErrors()

    let errorCloudFunction = ''
    let errorMemory = ''
    let errorRuntime = ''
    let errorTimeout = ''
    let errorProject = ''
    let errorRegion = ''
    let errorGeneration = ''
    let errorMaxInstances = ''

    if (!newCloudFunction?.length)
      errorCloudFunction = 'Required Name'

    if (!newMemory || !listMemoryByGeneration().find(i => i.value === newMemory.value))
      errorMemory = 'Memory invalid'

    if (!newRuntime || !listRuntime.find(i => i.value === newRuntime.value))
      errorRuntime = 'Runtime invalid'

    if (!newTimeout)
      errorTimeout = 'Timeout required'

    if (!region?.value)
      errorRegion = 'Region required'

    if (!generation?.value)
      errorGeneration = 'Region required'

    if (!maxInstances?.value)
      errorMaxInstances = 'Region required'

    setErrorFieldCloudFunction(errorCloudFunction)
    setErrorFieldMemory(errorMemory)
    setErrorFieldRunTime(errorRuntime)
    setErrorFieldRegion(errorRegion)
    setErrorFieldGeneration(errorGeneration)
    setErrorFieldMaxInstances(errorMaxInstances)
    return (!errorCloudFunction.length && !errorMemory.length && !errorRuntime.length && !errorTimeout.length && !errorProject.length && !errorRegion.length && !errorGeneration.length && !errorMaxInstances.length)
  }

  const handleInputNewCloudFunction = (ev) => {
    const target = ev.target
    const newValue = target?.value?.toLowerCase()
    setNewCloudFunction(newValue)
  }

  const handleInputTimeout = (ev) => {
    const target = ev.target
    const newValue = Number(target?.value)
    setNewTimeout(newValue)
  }

  const getMaxValue = () => {
    return generation?.value === 2 ? 3600 : 900
  }

  const handleSaveForm = () => {
    if (!validateFields())
      return

    const envs = envsRef.current?.getEnvs() ?? []

    const data = {
      name: newCloudFunction,
      memory: newMemory?.value,
      runtime: newRuntime?.value,
      timeout: newTimeout,
      generation: generation?.value,
      repository: { id: repository?.value },
      region: region?.value,
      prefix: prefix,
      coreRequired: coreRequired,
      maxInstances: maxInstances?.value,
      envs: [...loadSecrets, ...envs],
      gsutil: gsutilRef.current?.getGsutil() ?? [],
      authenticationRequired: !!authenticationRef.current?.getValue(),
      defaultDeployment: defaultDeployment,
      vpcConnector
    }

    handleSubmitCloudFunction(data)
    setOldListSecrets(data.envs)
  }

  const onUpdateSecrets = (data) => {
    const newLoadSecrets = loadSecrets.map(i => ({
      ...data.id === i.id ? {
        ...i, ...data
      }
        :
        i
    }))

    setLoadSecrets(newLoadSecrets)
  }

  return (
    <WHorizontalLinearStepper
      isLoading={isLoadingData}
      childrens={[
        {
          stepName: '',
          element:
            <div className='1st step'>
              <div>
                <WTextFieldV2
                  className='border-b border-transparent border-b-[#fc673d] text-white mt-[5%] w-full'
                  value={newCloudFunction}
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.CLOUD_FUNCTION' />}
                  required
                  error={errorFieldCloudFunction}
                  onChange={handleInputNewCloudFunction}
                  variant='standard'
                />
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.GENERATION' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  required
                  placeholder=''
                  errorText={errorFieldGeneration}
                  value={generation}
                  onChange={ev => setGeneration(ev)}
                  options={listGeneration}
                />
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.MEMORY' />}
                  className='border-b border-orange border-b-[#a73804] text-white mt-[5%]'
                  theme={{ colors: 'neutral0' }}
                  required
                  placeholder=''
                  errorText={errorFieldMemory}
                  value={newMemory}
                  onChange={ev => setNewMemory(ev)}
                  options={listMemoryByGeneration()}
                />
              </div>
              <div>
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.RUNTIME' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  theme={{ colors: 'neutral0' }}
                  required
                  placeholder=''
                  errorText={errorFieldRuntime}
                  value={newRuntime}
                  onChange={ev => setNewRuntime(ev)}
                  options={listRuntime}
                />
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.MAX_INSTANCES' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  theme={{ colors: 'neutral0' }}
                  required
                  placeholder=''
                  errorText={errorFieldMaxInstances}
                  value={maxInstances}
                  onChange={ev => setMaxInstances(ev)}
                  options={listMaxInstances}
                />
              </div>
            </div>
        },
        {
          stepName: '',
          element:
            <div className='2nd step'>
              <div>
     
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REPOSITORY' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  required
                  placeholder=''
                  value={repository}
                  onChange={ev => setRepository(ev)}
                  options={repositories}
                />
              </div>
              <div>
                <WRadioGroupAuthenticationV2
                  ref={authenticationRef}
                  defaultValue={authenticationRequired}
                />
                <WSelectFieldV2
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REGION' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  required
                  placeholder=''
                  errorText={errorFieldRegion}
                  value={region}
                  onChange={ev => setRegion(ev)}
                  options={listRegions}
                />
                <WTextFieldV2
                  className='border-b border-transparent border-b-[#fc673d] text-white mt-[5%] w-full'
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.TIMEOUT' />}
                  required
                  value={newTimeout}
                  onChange={handleInputTimeout}
                  type='number'
                  limit={getMaxValue()}
                  variant='standard'
                />
                <WTextFieldV2
                  className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                  value={vpcConnector}
                  label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.VPC_CONNECTOR'/>}
                  onChange={ev => setVpcConnector(ev.target.value)}
                  variant='standard'
                />
              </div>
            </div >
        },
        {
          stepName: '',
          element:
          <div className='3rd step'>
            <div className='text-white w-full'>
              <WTextFieldV2
                className='border-b border-transparent border-b-[#fc673d] text-white mt-[5%] w-full'
                label={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.PREFIX' />}
                value={prefix}
                onChange={ev => setPrefix(ev.target.value)}
                variant='standard'
              />
              <WCheckbox
                i18n={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.CORE_REQUIRED'/>}
                defaultChecked
                checked={coreRequired}
                onChange={ev => setCoreRequired(ev.target.checked)}
              />
              <WCheckbox
                i18n={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.DEFAULT_DEPLOYMENT'/>}
                checked={defaultDeployment}
                onChange={ev => setDefaultDeployment(ev.target.checked)}
              />
              <WSecrets
                secrets={dataSecrets?.data}
                loadSecrets={loadSecrets}
                oldListSecrets={oldListSecrets}
                onPushMoreSecret={() => setLoadSecrets([...loadSecrets, { id: uuidv4() }])}
                onSliceSecret={item => setLoadSecrets([...loadSecrets.filter(secret => item.id !== secret.id)])}
                onUpdate={onUpdateSecrets}
              />
              <WEnvironmentVariable
                ref={envsRef}
                loadEnvs={loadEnvs}
              />
              <WGsutil
                ref={gsutilRef}
                loadCopies={listGsutil}
                valuePrefix={prefix}
              />
            </div>
          </div >
        },
      ]}
      finishStep={
        <div>
          <h1 className='text-center text-lg subpixel-antialiased	'>
            <I18n path='Global.CREATE'/>{newCloudFunction}
          </h1>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.MEMORY'/>: {newMemory?.value}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.RUNTIME'/>: {newRuntime?.value}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.TIMEOUT'/>: {newTimeout}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.GENERATION'/>: {generation?.value}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REPOSITORY'/>: {repository?.value}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REGION'/>: {region?.value}
          </p>
          <p className='flex-col'>
            <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.INSTANCES'/>: Max {maxInstances?.value}
          </p>
          <p>
            {prefix}
            {coreRequired}
            {authenticationRef.value}
          </p ></div>
      }
      onSaveClick={handleSaveForm}
      type={id ? 'Update' : 'Create' }
    />
  )
}

export default FormData