import './style.scss'
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableCheckbox
} from '@react-md/table'
import { useIndeterminateChecked } from '@react-md/form'
import { Button } from '@clinicorp/designsystem.components.basic.buttons.button'
import Icon from '@mui/material/Icon'
import WFilters from './filters'
import BodyRows from './BodyRows'

const WTable = (props, ref) => {
  const {
    styleTableContainer,
    fullWidth,
    tableHeader,
    checkboxAllRows,
    checkboxRows,
    headerCells,
    tableFooter,
    footerPagination,
    pages,
    rows,
    getCheckedValues,
    onChangeCurrentPage,
    limitForPage = 0,
    topMenu,
    buttonsTopMenu,
    tabTopMenu,
    filters,
    doNotDuplicateFilters,
    onChangeFilters,
    colSpanOnHoverRow = 0
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const { rootProps, checkedValues, getProps, setCheckedValues } = useIndeterminateChecked(rows)
  const tableRef = useRef()
  const filtersRef = useRef()

  useEffect(() => {
    if (checkboxRows && typeof getCheckedValues === 'function')
      getCheckedValues(checkedValues)
  }, [checkedValues])

  useEffect(() => {
    if (footerPagination && rows?.length)
      onChangeCurrentPage()
  }, [currentPage])

  const previusButtons = () => {
    let buttons = []
    if (pages?.length > 1) {
      buttons.push({ step: 'first', icon: 'keyboard_double_arrow_left' })
      buttons.push({ step: 'previous', icon: 'chevron_left' })
    }

    return buttons
  }

  const nextButtons = () => {
    let buttons = []
    if (pages?.length > 1) {
      buttons.push({ step: 'next', icon: 'chevron_right' })
      if (pages[pages.length- 1].isLastPage)
        buttons.push({ step: 'last', icon: 'keyboard_double_arrow_right' })
    }

    return buttons
  }

  const changeCurrentPage = step => {

    const numberOfSteps = pages.length
    const nextStep =  step === 'last' ? numberOfSteps
      : step === 'previous' ? currentPage - 1
        : step === 'next' ? currentPage + 1
          : step === 'first' ? 1
            : step
    setCurrentPage(nextStep < 1 ? 1 : nextStep > numberOfSteps ? numberOfSteps : nextStep)
  }

  const clearCheckedValues = () => {
    setCheckedValues([])
  }

  const getIdCheckedValues = () => {
    return checkedValues?.flatMap(i => i.cells.filter(j => j.id))?.map(i => i.id)
  }

  useImperativeHandle(ref, () => ({
    clearCheckedValues,
    getIdCheckedValues,
    currentPage: () => currentPage,
    changeCurrentPage,
    filtersIsVisible: () => filtersRef.current?.isVisibleFilters(),
    getFilters: () => filters.current?.getFilters()
  }))

  return (
    <div className='container-fluid'>
      {
        topMenu &&
        <div className='row align-items-center workbench-menu justify-content-between shadow-2xl'>
          <div className='d-flex'>
            {
              buttonsTopMenu?.map((i, index)=> (
                <Button
                  key={`${i.icon}_${index}`}
                  type={i.type}
                  iconChildren={i.icon}
                  style={{ opacity: i.disabled ? 0.4 : 1, ...i.style }}
                  onClick={i.onClick}
                  disabled={i.disabled}
                  tooltipLabel={i.tooltipLabel}
                />
              ))
            }
          </div>
          {
            <div className='d-flex'>
              {
                tabTopMenu?.map((i, index)=> (
                  <Button
                    key={`${i.icon}_${index}`}
                    type={i.type}
                    label={i.label}
                    iconChildren={i.icon}
                    style={{ opacity: i.disabled ? 0.4 : 1, margin: '0 5px ',...i.style }}
                    onClick={i.onClick}
                    disabled={i.disabled}
                    tooltipLabel={i.tooltipLabel}
                    primary={i.primary}
                  />
                ))
              }
            </div>
          }
        </div>
      }
      <div className='row'>
        <TableContainer
          style={styleTableContainer}
          className='workbench-table-container'
        >
          <Table
            ref={tableRef}
            fullWidth={fullWidth}
          >
            {
              tableHeader &&
          <TableHeader
            sticky
            className='workbench-table-header'
          >
            {
              filters?.length &&
              <TableRow disableBorders={true}>
                <TableCell
                  sticky={false}
                  className='workbench-table-header-cell'
                  colSpan='100%'
                  lineWrap={true}
                >
                  <WFilters
                    ref={filtersRef}
                    listFilters={filters}
                    doNotDuplicate={doNotDuplicateFilters ?? true}
                    onChangeFilters={onChangeFilters}
                  />
                </TableCell>
              </TableRow>
            }
            {
              (checkboxAllRows || headerCells?.length) &&
              <TableRow>
                {
                  checkboxAllRows ?
                    <TableCell className='workbench-table-header-cell'>
                      <TableCheckbox
                        className='workbench-table-header-cell'
                        {...rootProps}
                        id='header-checkbox'
                      />
                    </TableCell>
                    :
                    checkboxRows ? <TableCell className='workbench-table-header-cell'/> : <></>
                }
                {
                  headerCells?.length ?
                    headerCells?.filter(i => !i.hidden).map((cell, cellIndex) => (
                      <TableCell
                        key={`${cell.label}_${cellIndex}`}
                        hAlign={cell.align}
                        style={cell.style}
                        lineWrap={cell.lineWrap}
                        className='workbench-table-header-cell'
                      > {cell.label}
                      </TableCell>
                    ))
                    : <TableCell className='workbench-table-header-cell' colSpan='100%'/>
                }
              </TableRow>
            }
          </TableHeader>
            }
            <TableBody
              className='workbench-table-body'
            >
              <BodyRows
                rows={rows}
                limitForPage={limitForPage}
                checkboxRows={checkboxRows}
                colSpanOnHoverRow={colSpanOnHoverRow}
                getProps={getProps}
              />
            </TableBody>
            {
              tableFooter &&
          <TableFooter sticky>
            <TableRow>
              <TableCell
                colSpan='100%'
                className='workbench-table-footer-cell'
              >
                {
                  footerPagination &&
                  <div className='d-flex justify-content-center align-items-center'>
                    {
                      previusButtons().map(i => (
                        <Button
                          key={`${i.step}_${i.icon}`}
                          className={`workbench-table-footer-cell-btn-${currentPage === i.page ? 'selected' : 'notSelected'}`}
                          iconChildren={i.icon}
                          type='icon'
                          label={i.icon ? '' : i.step}
                          onClick={() => changeCurrentPage(i.step)}
                        />
                      ))
                    }
                    {
                      pages?.length > 1 ? pages.map(i => {
                        const el = (i.page === 1 || i.page === pages.length) || (i.page < currentPage + 3 && i.page > currentPage - 3) ?
                          <Button
                            className={`workbench-table-footer-cell-btn-${currentPage === i.page ? 'selected' : 'notSelected'}`}

                            iconChildren={i.icon}
                            type='icon'
                            label={i.page}
                            onClick={() => changeCurrentPage(i.page)}
                          /> : (i.page === currentPage + 3 || i.page === currentPage - 3) ? <Icon className='workbench-table-footer-cell-icon-more-horiz' >more_horiz</Icon> : <></>
                        return (el)
                      }
                      ): ''
                    }
                    {
                      nextButtons().map(i => (
                        <Button
                          key={`${i.step}_${i.icon}`}
                          className={`workbench-table-footer-cell-btn-${currentPage === i.page ? 'selected' : 'notSelected'}`}

                          iconChildren={i.icon}
                          type='icon'
                          label={i.icon ? '' : i.step}
                          onClick={() => changeCurrentPage(i.step)}
                        />
                      ))
                    }
                  </div>
                }
              </TableCell>
            </TableRow>
          </TableFooter>
            }
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default forwardRef(WTable)