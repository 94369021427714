import React, { KeyboardEvent, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import {StandardTextFieldProps } from '@mui/material/TextField'
import { InputAdornment, TextField  } from '@mui/material'

interface ITextField extends Omit<StandardTextFieldProps, 'prefix'> {
  readOnly?: boolean
  className?: string
  minValue?: number
  maxValue?: number
  sufix?: string | JSX.Element
  prefix?: string | JSX.Element
  onKeyEnter?: () => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement> ) => void
}

interface IWTextField extends HTMLInputElement {
  getValue: () => unknown
}

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
    fontSize: '1.4rem',
    justifyContent: 'center'
  },
  '& input:-webkit-autofill': {
    '-webkit-text-fill-color': 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#a73804',
    borderWidth: '1px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#a73804'
    },
    '&:hover fieldset': {
      borderColor: '#a73804'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#a73804',
      color: 'white'
    }
  }
})


const WTextFieldV2 = forwardRef<IWTextField, ITextField>((props) => {
  
  const handleKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter'){
      props.onKeyEnter?.()
    } else {
      props.onKeyDown?.(event)
    }
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   props.onChange(event)
  // }

  // useEffect(() => {
  //   if (props.type === 'number' && props.onChange) {
  //     if (!isNaN(Number(props.minValue)) && Number(props.value) < (props.minValue ?? 0) && props.minValue) {
  //       props.onChange({ target: { value: props.minValue.toString() }})
  //       props.onChange((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //         if (event.target) {
  //           return
  //         }

  //         return event
  //       })
  //       // props.onChange(() =>  ({ target: { value: props.minValue?.toString() }}))
  //       // props.onChange((event: React.ChangeEvent<HTMLInputElement>) => (event.target.value = props.minValue))
  //     } else if (!isNaN(Number(props.maxValue)) && Number(props.value) > (props.maxValue  ?? 0) && props.maxValue ) (
  //       props.onChange({ target: { value: props.maxValue?.toString() }})
  //     )
  //   }
  // }, [props.value])

  // const getValue = () => props.value

  // useImperativeHandle(ref, () => ({
  //   getValue
  // }), [props.value])
  
  return (
    <div className='w-full'>
      <CssTextField
        fullWidth
        className={props.className}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        required={props.required}
        id={`w-text-field-${props.id}`}
        label={props.label}
        variant='standard'
        type={props.type}
        helperText={props.error ? props.helperText : ''}
        disabled={props.disabled}
        margin={props.margin}
        placeholder={props.placeholder}
        focused
        // onKeyDown={handleKeyDown}
        InputProps={{
          onKeyDown:handleKeyDown,
          inputProps: {style: {color: 'white', paddingTop: '1.5rem' , paddingLeft: '10px'}},
          readOnly: props.readOnly,
          ...props.prefix ? {
            startAdornment: <InputAdornment position="start" disableTypography={true} className='text-white'>{props.prefix}</InputAdornment>
          }
            :
            {},
          ...props.sufix ? {
            endAdornment: <InputAdornment position="end" disableTypography={true} className='text-white'>{props.sufix}</InputAdornment>
          }
            :
            {}
        }}
      />
    </div>
  )
})

WTextFieldV2.displayName = 'WTextFieldV2'
export default WTextFieldV2