import React, { useEffect, useState } from 'react'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

export interface ISaveFormData {
  name: string
  gcp: string
  bitbucket: string
  url: string
}

export interface IRepository extends ISaveFormData {
  id: string
}

interface IAppRepository {
  repository?: IRepository
  onCloseModal: () => void
  onSaveForm: (props: ISaveFormData) => Promise<void>
  loading?: boolean
}

const FormDataRepositories = (props: IAppRepository) => {
  const {
    repository,
    onCloseModal,
    onSaveForm,
    loading
  } = props

  const [name, setName] = useState('')
  const [gcp, setGcp] = useState('')
  const [bitbucket, setBitbucket] = useState('')
  const [url, setUrl] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [errorGcp, setErrorGcp] = useState(false)
  const [errorBitbucket, setErrorBitbucket] = useState(false)
  const [errorUrl, setErrorUrl] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!repository)
      return

    setName(repository?.name)
    setGcp(repository?.gcp)
    setBitbucket(repository?.bitbucket)
    setUrl(repository?.url)
  }, [repository])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setName(value)
  }

  const onChangeGcp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setGcp(value)
  }

  const onChangeBitbucket = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setBitbucket(value)
  }

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setUrl(value)
  }

  const handleSave = async () => {
    setErrorName(!name.length)
    setErrorGcp(!gcp.length)
    setErrorBitbucket(!bitbucket.length)
    setErrorUrl(!url.length)

    if (!name.length || !gcp.length || !bitbucket.length || !url.length)
      return

    setIsLoading(true)

    const data ={
      name,
      gcp,
      bitbucket,
      url,
    }

    onSaveForm(data)
      .finally(() => setIsLoading(false))
  }

  return (
    <WCustomModal
      title={repository ? <I18n path='Application.REPOSITORIES.MODAL.EDIT.TITLE' /> : <I18n path='Application.REPOSITORIES.MODAL.ADD.TITLE' />}
      open={true}
      handleClose={onCloseModal}
      loading={isLoading || loading}
      buttonLabel={repository ? <I18n path='Application.REPOSITORIES.MODAL.EDIT.BTN_SAVE' /> : <I18n path='Application.REPOSITORIES.MODAL.ADD.BTN_SAVE' />}
      buttonOnConfirm={handleSave}
      icon={repository ? 'edit' : 'check_circle'}
      element={
        <div className='flex flex-col w-96 h-96 gap-4 p-2'>
          <WTextFieldV2
            label={<I18n path='Application.REPOSITORIES.MODAL.NAME'/>}
            value={name}
            error={errorName}
            helperText={<I18n path='Global.REQUIRED_FIELD'/>}
            required
            onChange={onChangeName}
            variant='standard'
          />
          <WTextFieldV2
            label={<I18n path='Application.REPOSITORIES.MODAL.GCP'/>}
            value={gcp}
            error={errorGcp}
            helperText={<I18n path='Global.REQUIRED_FIELD'/>}
            required
            onChange={onChangeGcp}
            variant='standard'
          />
          <WTextFieldV2
            label={<I18n path='Application.REPOSITORIES.MODAL.BITBUCKET'/>}
            value={bitbucket}
            error={errorBitbucket}
            helperText={<I18n path='Global.REQUIRED_FIELD'/>}
            required
            onChange={onChangeBitbucket}
            variant='standard'
          />
          <WTextFieldV2
            label={<I18n path='Application.REPOSITORIES.MODAL.URL'/>}
            value={url}
            error={errorUrl}
            helperText={<I18n path='Global.REQUIRED_FIELD'/>}
            required
            onChange={onChangeUrl}
            variant='standard'
          />
        </div>
      }
    />
  )
}

export default FormDataRepositories