import React, { useCallback, useContext, useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import I18n from '../I18n'
import { Context } from '../../context/authContext'

const WPageSideBar = (props) => {
  const navigate = useNavigate()
  const { permissions } = useContext(Context)

  const {
    label,
    icon,
    subItems,
    path
  } = props

  const [open, setOpen] = useState(false)

  const containsSubItems = useCallback(() => (subItems && Object.keys(subItems)?.length > 0), [subItems])

  const natigateTo = (route) => navigate(route)

  const handleClick = (route) => {
    if (containsSubItems())
      setOpen(prev => !prev)
    else if (route)
      natigateTo(route)
  }

  return (
    <div className=''>
      <ListItemButton
        onClick={() => handleClick(path)}
      >
        {
          icon &&
        <ListItemIcon className='justify-start text-white'>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        }
        <ListItemText className='' primary={<I18n path={label}/>} />
        {
          containsSubItems() &&
        <>{open ? <ExpandLess /> : <ExpandMore />}</>
        }
      </ListItemButton>
      {
        containsSubItems() &&
      <Collapse in={open} timeout='auto' unmountOnExit>
        {
          Object.keys(subItems)?.map((key, index) => (
            (!subItems[key].permissions || subItems[key].permissions.find(perm => permissions?.includes(perm))) ?
              <List
                key={index}
                component='div'
                disablePadding
              >
                <ListItemButton onClick={() => natigateTo(subItems[key]?.getRoute())} sx={{ pl: 4 }}>
                  {
                    subItems[key]?.icon &&
                  <ListItemIcon className='text-white'>
                    <Icon>{subItems[key]?.icon}</Icon>
                  </ListItemIcon>
                  }
                  <ListItemText className='' primary={<I18n path={subItems[key]?.title}/>} />
                </ListItemButton>
              </List>
              :
              <></>
          ))
        }
      </Collapse>
      }
    </div>
  )
}

export default WPageSideBar