import React, { useEffect, useState } from 'react'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'
import WCheckbox from '../../../../components/WCheckbox'
import WHorizontalLinearStepper from '../../../../components/WHorinzontalLinearStepper'
import { Icon } from '@mui/material'

export interface ISaveFormData {
  name: string
  projectId: string
  projectNumber: string
  isProduction: boolean
  icon: string
  templateUrlAppEngine: string
  templateUrlCloudRun: string
  templateUrlCloudFunction: string
}

export interface IProject extends ISaveFormData {
  id: string
}

interface IAppProject {
  project?: IProject
  onCloseModal: () => void
  onSaveForm: (props: ISaveFormData) => Promise<void>
}

const FormDataProject = (props: IAppProject) => {
  const {
    project,
    onCloseModal,
    onSaveForm,
  } = props

  const [name, setName] = useState('')
  const [projectId, setProjectId] = useState('')
  const [projectNumber, setProjectnumber] = useState('')
  const [isProduction, setIsProduction] = useState(false)
  const [icon, setIcon] = useState('')
  const [templateUrlAppEngine, setTemplateUrlAppEngine] = useState('')
  const [templateUrlCloudRun, setTemplateUrlCloudRun] = useState('')
  const [templateUrlCloudFunction, setTemplateUrlCloudFunction] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [errorProjectId, setErrorProjectId] = useState(false)
  const [errorProjectNumber, setErrorProjectNumber] = useState(false)
  const [errorIcon, setErrorIcon] = useState(false)
  const [errorTemplateUrlAppEngine, setErrorTemplateUrlAppEngine] = useState(false)
  const [errorTemplateUrlCloudRun, setErrorTemplateUrlCloudRun] = useState(false)
  const [errorTemplateUrlCloudFunction, setErrorTemplateUrlCloudFunctionn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!project)
      return

    setName(project?.name)
    setProjectId(project?.projectId)
    setProjectnumber(project?.projectNumber)
    setIsProduction(project?.isProduction)
    setIcon(project?.icon)
    setTemplateUrlAppEngine(project?.templateUrlAppEngine)
    setTemplateUrlCloudRun(project?.templateUrlCloudRun)
    setTemplateUrlCloudFunction(project?.templateUrlCloudFunction)
  }, [project])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setName(value)
  }

  const onChangeProjectId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setProjectId(value)
  }

  const onChangeProjectNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setProjectnumber(value)
  }

  const onChangeIsProduction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setIsProduction(checked)
  }

  const onChangeIcon = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setIcon(value)
  }

  const onChangeTemplateUrlAppEngine = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTemplateUrlAppEngine(value)
  }

  const onChangeTemplateUrlCloudRun = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTemplateUrlCloudRun(value)
  }

  const onChangeTemplateUrlCloudFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTemplateUrlCloudFunction(value)
  }

  const handleSave = async () => {
    setErrorName(!name.length)
    setErrorProjectId(!projectId.length)
    setErrorProjectNumber(!projectNumber.length)
    setErrorIcon(!icon.length)
    setErrorTemplateUrlAppEngine(!templateUrlAppEngine.length)
    setErrorTemplateUrlCloudRun(!templateUrlCloudRun.length)
    setErrorTemplateUrlCloudFunctionn(!templateUrlCloudFunction.length)

    if (!name.length || !projectId.length || !projectNumber.length || !icon.length || !templateUrlAppEngine.length || !templateUrlCloudRun.length || !templateUrlCloudFunction.length)
      return

    setIsLoading(true)

    const data ={
      name,
      projectId,
      projectNumber,
      isProduction,
      icon,
      templateUrlAppEngine,
      templateUrlCloudRun,
      templateUrlCloudFunction
    }

    onSaveForm(data)
      .finally(() => setIsLoading(false))
  }
  
  const classNameReview = 'flex flex-col break-words'
  const classNameSpan = 'ml-2 bg-grey-900 bg-opacity-20'

  return (
    <div>
      <div>
        <WCustomModal
          title={project ? <I18n path='Application.PROJECTS.MODAL.EDIT.TITLE' /> : <I18n path='Application.PROJECTS.MODAL.ADD.TITLE' />}
          open={true}
          handleClose={onCloseModal}
          loading={isLoading}
          disabled={false}
          element={
            <WHorizontalLinearStepper
              isLoading={isLoading}
              childrens={[
                {
                  stepName: '',
                  element:
                  <div className='1st step'>
                    <div className='flex flex-col w-96 h-96 gap-4 p-2 '>
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.NAME'/>}
                        value={name}
                        error={errorName}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeName}
                        variant='standard'
                      />
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.PROJECT_ID'/>}
                        value={projectId}
                        error={errorProjectId}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeProjectId}
                        variant='standard'
                      />
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.PROJECT_NUMBER'/>}
                        value={projectNumber}
                        error={errorProjectNumber}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeProjectNumber}
                        variant='standard'
                      />
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.ICON'/>}
                        value={icon}
                        error={errorIcon}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeIcon}
                        variant='standard'
                      />
                      
                    </div>
                  </div>
                },
                {
                  stepName: '',
                  element:
                  <div className='2nd step'>
                    <div className='flex flex-col w-96 h-96 gap-4 p-2'>
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_APP_ENGINE'/>}
                        value={templateUrlAppEngine}
                        error={errorTemplateUrlAppEngine}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeTemplateUrlAppEngine}
                        variant='standard'
                      />
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_CLOUD_RUN'/>}
                        value={templateUrlCloudRun}
                        error={errorTemplateUrlCloudRun}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeTemplateUrlCloudRun}
                        variant='standard'
                      />
                      <WTextFieldV2
                        label={<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_CLOUD_FUNCTION'/>}
                        value={templateUrlCloudFunction}
                        error={errorTemplateUrlCloudFunction}
                        helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                        required
                        onChange={onChangeTemplateUrlCloudFunction}
                        variant='standard'
                      />
                      <div className='text-white'>
                        <WCheckbox
                          label={<I18n path='Application.PROJECTS.MODAL.IS_PRODUCTION'/>}
                          checked={isProduction}
                          onChange={onChangeIsProduction}
                          defaultChecked
                          id='appEngineRequired'
                          style={{
                          }}
                        />
                      </div>
                    </div>
                  </div >
                },
              ]}
              finishStep={
                <div className='flex flex-col w-96 h-96 gap-4 p-2 overflow-auto'>
                  <h1 className='text-center text-xl subpixel-antialiased'>
                    {<I18n path='Application.PROJECTS.NEW_NAME'/>}
                  </h1>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.NAME'/>}:
                    <span className={classNameSpan}>{name}</span>
                  </p>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.PROJECT_ID'/>}:
                    <span className={classNameSpan}>{projectId}</span>
                  </p>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.PROJECT_NUMBER'/>}:
                    <span className={classNameSpan}>{projectNumber}</span>
                  </p>
                  <p className='flex items-center'>
                    {<I18n path='Application.PROJECTS.MODAL.ICON'/>}:
                    <span className='flex items-center ml-2'>
                      <Icon className='text-blue-200' >{icon}</Icon>
                    </span>
                  </p>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_APP_ENGINE'/>}:
                    <span className={classNameSpan}>{templateUrlAppEngine}</span>
                  </p>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_CLOUD_RUN'/>}:
                    <span className={classNameSpan}>{templateUrlCloudFunction}</span>
                  </p>
                  <p className={classNameReview}>
                    {<I18n path='Application.PROJECTS.MODAL.TEMPLATE_URL_CLOUD_FUNCTION'/>}:
                    <span className={classNameSpan}>{templateUrlCloudFunction}</span>
                  </p>
                  <p className='flex items-center'>
                    {<I18n path='Application.PROJECTS.MODAL.IS_PRODUCTION'/>}:
                    <Icon
                      data-status={isProduction}
                      className={`
                        ml-2 
                      data-[status=true]:text-green
                      data-[status=false]:text-red
                    `}>
                      {isProduction ? 'check' : 'close'}</Icon>
                  </p>
                </div>
              }
              onSaveClick={handleSave}
              iconButton={project ? 'edit' : 'add_circle'}
              type={project ? <I18n path='Application.PROJECTS.MODAL.EDIT.BTN_SAVE' /> : <I18n path='Application.PROJECTS.MODAL.ADD.BTN_SAVE' /> }
            />
          }
        />
      </div>
      
    </div>
  )
}

export default FormDataProject