import React from 'react'
import CreatableSelect from 'react-select/creatable'

import {
  customStyles,
  Control,
  LoadingMessage,
  NoOptionsMessage,
} from '../ReactSelect/components'

const WCreatableSelect = (props) => {
  const {
    components,
    className,
    id,
    clearable,
    disabled,
    loading,
    ...selectSearchProps
  } = props

  const customClassName = `wcreatableSelect ${className} text-base`

  return (
    <CreatableSelect
      id={`wcreatableSelect-${id}`}
      className={customClassName}
      classNamePrefix='eliabe'
      styles={{
        ...customStyles,
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999, backgroundColor:'#111111'}),
        option: (provided, state) => ({
          ...provided,
          background: state.isSelected ? 'var(--NewOrangeClinicorp)' : state.isFocused ? 'var(--NewOrangeClinicorp)' : '#111111',
          color: 'white',
          ':active': {
            ...provided[':active'],
            backgroundColor: state.isSelected
              ? '#111111'
              : '#db500f'
          },
          ':hover': {
            ...provided[':hover'],
            borderColor: state.isSelected
              ? 'var(--NewOrangeClinicorp)'
              : '#00ff00'
          }
        }),
        input: provided => ({
          ...provided,
          color: 'white',
          paddingBottom: '12px',
          borderColor: '#00ff00'
        }),
        control: (provided, state) => ({
          ...provided,
          border: 'none',
          borderBottom: `1px solid ${state.selectProps.errorText ? 'red' : 'rgba(0, 0, 0, 0.12)'}`,
          borderRadius: 0,
          boxShadow: 'none',
          color: `${state.selectProps.errorText ? 'red' : 'white'}`,
          backgroundColor: '#111111',
          ':hover': {
            ...provided[':hover'],
            borderColor: state.isSelected
              ? 'var(--NewOrangeClinicorp)'
              : '#db500f'
          }
        })
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      isDisabled={disabled}
      isClearable={clearable}
      isLoading={loading}
      disableTypography= {true}
      components={{
        Control,
        NoOptionsMessage,
        LoadingMessage,
        ...components
      }}
      {...selectSearchProps}
    />
  )
}


export default WCreatableSelect