import React, { useEffect, useState } from 'react'
import Editor from '@monaco-editor/react'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'

const WEditor = (props) => {
  const {
    language,
    theme,
    code,
    onChange,
    options,
    width,
    heigth
  } = props

  const [value, setValue] = useState(code || '')

  const handleEditorChange = (value) => {
    setValue(value || '')
    if (typeof onChange === 'function')
      onChange(value)
  }

  useEffect(() => {
    handleEditorChange(code)
  }, [code])

  const defaultOptions = {
    ...options
  }

  return (
    <div className='overlay rounded-[10px] overflow-hidden w-full h-full shadow-4xl'>
      <Editor
        language={language ?? 'javascript'}
        value={value}
        theme={theme ?? 'vs-dark'}
        onChange={handleEditorChange}
        options={defaultOptions}
        loading={<Loading />}
        height={heigth}
        width={width}
      />
    </div>
  )
}

export default WEditor