import { TableSortLabel } from '@mui/material'
import React from 'react'
import { StyledTableCell } from './commons'
import { WCellValue } from './WCellValue'

export const WCell = props => {
  const { orderBy, order, cell, onRequestSort, animationClass } = props

  if (!cell)
    return

  if (cell.sortable) {
    const createSortHandler = property => event => {
      onRequestSort(event, property)
    }

    return (
      <StyledTableCell
        className={animationClass}
        align={cell?.align || 'center'}
        sortDirection={orderBy === cell?.key ? order : false}
      >
        <TableSortLabel
          active={orderBy === cell?.key}
          direction={orderBy === cell?.key ? order : 'asc'}
          onClick={createSortHandler(cell?.key)}
        >
          <WCellValue
            cell={cell}
          />
        </TableSortLabel>
      </StyledTableCell>
    )

  }

  return (
    <StyledTableCell
      className={animationClass}
      align={cell?.align || 'center'}
    >
      <WCellValue
        cell={cell}
      />
    </StyledTableCell>
  )
}
