import React, { useState, useEffect, useRef } from 'react'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import ModalEditUser from './modalEditUser'
import ModalDeleteUser from './modalDeleteUser'
import WTable from '../../../../components/WTable'
import I18n from '../../../../components/I18n'
import useApi from '../../../../context/hooks/useApi'
import ModalAddUser from './modalAddUser'

const headerTable = [
  { label: 'Name'},
  { label: 'E-mail'},
  { label: 'Roles'},
  { label: 'Environments'},
]

const Users = () => {
  const [rows, setRows] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [environments, setEnvironments] = useState([])
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [roles, setRoles] = useState([])
  const WtableRef = useRef()

  const { data: dataEnvironments } = useApi({ url: 'environments', displayError: true })
  const { data: usersData, isLoading: loadinUsers, mutate: mutateUsers } = useApi({ url: 'users', displayError: true })
  const { data: dataRoles } = useApi({ url: 'roles' })

  useEffect(() => {
    if (dataEnvironments) {
      const newEnvironments = dataEnvironments.data?.map(i => ({ label: i.name.toUpperCase(), value: i.id, project: i.Project }))
      setEnvironments(newEnvironments ?? [])
    }
  }, [dataEnvironments])

  useEffect(() => {
    if (dataRoles) {
      const newRoles = dataRoles.data?.map(i => ({ value: i.id, label: i.role }))
      setRoles(newRoles)
    }

  }, [dataRoles])

  useEffect(() => {
    if (usersData) {

      const newRows = usersData.data?.map(i => (
        {
          cells: [
            { id: i.id, hidden: true },
            { label: i.name },
            { label: i.email },
            { label: i.roles?.map(j => j.role)?.join(', ') },
            { label: i.environments?.map(j => j.name)?.join(', '), lineWrap: true },
          ]
        }))

      setRows(newRows ?? [])
    }

  }, [loadinUsers, usersData])

  const handleCloseModalEditUser = async isGetUsers => {
    setShowEditUserModal(false)

    WtableRef.current?.clearCheckedValues()
    if (isGetUsers) {
      mutateUsers()
    }
  }

  const handleCloseModalDeleteUser = async isGetUsers => {
    WtableRef.current?.clearCheckedValues()
    if (isGetUsers) {
      mutateUsers()
    }

    setShowDeleteUserModal(false)
  }

  const handleOpenModal = type => {
    if (type === 'add' )
      setShowAddUserModal(true)
    else if (type === 'edit' )
      setShowEditUserModal(true)
    else
      setShowDeleteUserModal(true)
  }

  const getUsersSelected = value => {
    setCheckedRows(value)
  }

  const getCellsSelected = () => {
    return checkedRows.flatMap(i => i.cells)
  }

  const getCellsSelectedToDelete = () => {
    return checkedRows.flatMap(i => ([i.cells]))
  }



  const buttons = [
    {
      type: 'icon',
      icon: 'add',
      onClick: () => handleOpenModal('add'),
    },
    {
      type: 'icon',
      icon: 'edit',
      disabled: !checkedRows.length,
      onClick: () => handleOpenModal('edit'),
    },
    {
      type: 'icon',
      icon: 'delete',
      disabled: !checkedRows.length,
      onClick: () => handleOpenModal('delete'),
    },
  ]

  return (
    <div className='container'>
      {(loadinUsers) && <Loading />}
      {showAddUserModal &&
        <ModalAddUser
          openModal={showAddUserModal}
          handleCloseModal={() => setShowAddUserModal(false)}
          title={<I18n path='Admin.USERS.ADD_USER'/> }
          environments={environments}
          roles={roles}
        />
      }

      {showEditUserModal &&
        <ModalEditUser
          openModal={showEditUserModal}
          handleCloseModal={handleCloseModalEditUser}
          users={getCellsSelected().map(i => i.name)}
          ids={getCellsSelected().filter(i => i.id).map(i => i.id)}
          title={checkedRows.length > 1 ? <I18n path='Admin.USERS.EDIT_USERS'/> : <I18n path='Admin.USERS.EDIT_USER'/> }
          environments={environments}
          roles={roles}
        />
      }
      {showDeleteUserModal &&
        <ModalDeleteUser
          openModal={showDeleteUserModal}
          handleCloseModal={handleCloseModalDeleteUser}
          ids={getCellsSelected().filter(i => i.id).map(i => i.id)}
          users={getCellsSelectedToDelete()}
          title={checkedRows.length > 1 ? <I18n path='Admin.USERS.DELETE_USERS'/> : <I18n path='Admin.USERS.DELETE_USER'/> }
        />
      }
      <div className='row' style={{ paddingTop: '10px' }}>
        <WTable
          ref={WtableRef}
          fullWidth={true}
          tableHeader={true}
          checkboxAllRows={true}
          checkboxRows={true}
          headerCells={headerTable}
          tableFooter={true}
          footerPagination={false}
          rows={rows}
          getCheckedValues={getUsersSelected}
          topMenu={true}
          buttonsTopMenu={buttons}
        />
      </div>
    </div>
  )
}

export default Users