import * as React from 'react'
import Chip from '@mui/material/Chip'

interface WChipProps {
  key: number;
  label: string;
  className?: string;
  onDelete: () => void
}

const WChip = (props: WChipProps) => {

  return (
    <Chip
      label={props.label}
      className={props.className}
      onDelete={props.onDelete}
    />
  )
}


export default WChip