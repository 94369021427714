import React from 'react'
import { Outlet } from 'react-router-dom'
import WPageSideBar from '../WPageSideBar'

const WPageLayout = (props) => {
  const {
    itemsMenu
  } = props

  return (
    <div className='flex h-full w-full flex-col sm:flex-row'>
      {
        itemsMenu &&
        <div className='drop-shadow-2xl p-2 min-w-[250px]'>
          <WPageSideBar
            items={itemsMenu}
          />
        </div>
      }
      <div className='h-full w-full p-2'>
        <Outlet />
      </div>
    </div>
  )
}

export default WPageLayout