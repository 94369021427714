import React, { useEffect, useRef, useState, useContext } from 'react'
import I18n from '../../../../components/I18n'
import WHorizontalLinearStepper from '../../../../components/WHorinzontalLinearStepper'
import api from '../../../../context/apiContext'
import { getVersionByAppEngine, getVersionByCloudRun, showResponseError, showResponseSuccess } from '../../../../helper'
import WSelectFieldMulti from '../../../../components/WSelectFieldMulti'
import WSelectField from '../../../../components/WSelectField/V2'
import useApi from '../../../../context/hooks/useApi'
import WRepository from './WRepository'
import { Context } from '../../../../context/authContext'
import WCheckbox from '../../../../components/WCheckbox'
import WTextFieldV2 from '../../../../components/WTextField/V2'

const listRuntime = [
  { label: 'Nodejs14', value: 'nodejs14' },
  { label: 'Nodejs16', value: 'nodejs16' },
  { label: 'Nodejs18', value: 'nodejs18' },
  { label: 'Nodejs20', value: 'nodejs20' },
]

const FormCardClinicorp = props => {

  const {
    handleSuccessBuild,
    onCloseModal
  } = props

  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [coreRequired, setCoreRequired] = useState(false)
  const [errorEnvironments, setErrorEnvironments] = useState('')
  const [errorVersion, setErrorVersion] = useState('')
  const [appEngineRequired, setAppEngineRequired] = useState(false)
  const [useServiceDesk, setUseServiceDesk] = useState(false)
  const [cloudFunctions, setCloudFunctions] = useState([])
  const [cloudRuns, setCloudRuns] = useState([])
  const [microserviceSelected, setMicroserviceSelectd] = useState([])
  const [usersSelected, setUsersSelected] = useState([])
  const [environment, setEnvironment] = useState({})
  const [version, setVersion] = useState('')
  const [environments, setEnvironments] = useState([])
  const [seletedRepositories, setSeletedRepositories] = useState([])
  const [users, setUsers] = useState([])
  const [project, setProject] = useState({})
  const [runtime, setRuntime] = useState(listRuntime.find(i => i.value === "nodejs20"))

  const { currentProject, allowedProjects, permissions } = useContext(Context)

  const WRepositoryRef = useRef(null)

  const { data: dataUsers } = useApi({ url: 'users/approve-center' })

  useEffect(() => {
    if(!useServiceDesk) {
      setUsersSelected([])
    }
  }, [useServiceDesk])

  useEffect(() => {
    if (dataUsers) {
      const users = dataUsers.data?.map(i => (
        {
          label: i.name,
          value: i.id,
          email: i.email
        })
      )

      setUsers(users)
    }
  }, [dataUsers])

  const {data: dataEnvironments} = useApi({url: 'environments/by-user'})
  const {data: dataAppEngine} = useApi({url: 'app-engine'})

  useEffect(() => {
    setProject(allowedProjects.find(i => i.id === currentProject ))
  }, [currentProject, allowedProjects])

  useEffect(() => {
    if (dataEnvironments) {
      const environments = dataEnvironments.data?.map(i => (
        {
          label: i.name,
          value: i.id,
          cloudFunctions: i.cloudFunctions,
          cloudRuns: i.cloudRuns,
        }
      ))
      setEnvironments(environments)
    }
  }, [dataEnvironments])

  useEffect(() => {
    if (dataAppEngine) {
      setCoreRequired(dataAppEngine.coreRequired)
    }
  }, [dataAppEngine])

  useEffect(() =>{
    const cloudFunctions = environment?.cloudFunctions?.map(i => (
      {
        label: i.name,
        value: i.id,
        repository: i.repository?.bitbucket,
        coreRequired: i.coreRequired,
        cloudFunction: true,
        defaultDeployment: i.defaultDeployment
      }
    ))
    setCloudFunctions(cloudFunctions)

    const newCloudRuns = environment?.cloudRuns?.map(i => (
      {
        value: i.id,
        label: i.name,
        cloudRun: true,
        coreRequired: i.coreRequired,
        repository: i.repository?.bitbucket,
        defaultDeployment: i.defaultDeployment
      }
    ))
    setCloudRuns(newCloudRuns)
  },[environment])

  const createApproveRequest = async (id) => {
    const users = usersSelected.map(userInfo => ({id: userInfo.value}))

    const data = {
      type: 'Service Desk',
      users,
      status: 'pending',
      pipeline: {
        id
      }
    }

    return api({
      method: 'POST',
      url: 'approve-center/service-desk',
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
      })
      .catch(error => showResponseError(error))
  }

  const createPipeline = async (pipelineParams) => {
    return await api({
      method: 'POST',
      url: 'pipelines',
      data: {
        ...pipelineParams
      }
    }).then(({ data }) => {
      showResponseSuccess('BUILD_STARTED')
      handleSuccessBuild()
      return data
    }).catch(error =>   (error))
      .finally(() => setIsLoadingCard(false))
  }


  const getSelectedBranchByRepo = repo => {
    const selectedBranchName = seletedRepositories.find(j => j.repository === repo)?.branch
    return selectedBranchName
  }

  const getVersion = async () => {
    setIsLoadingCard(true)
    await getVersionByAppEngine()
      .then(version => setVersion(version))
      .catch(error => showResponseError(error))
      .finally(() => setIsLoadingCard(false))
  }

  const getVersionCloudRun = async (id) => {
    setIsLoadingCard(true)
    await getVersionByCloudRun(id)
      .then(version => setVersion(version))
      .catch(error => showResponseError(error))
      .finally(() => setIsLoadingCard(false))
  }

  useEffect(() => {
    if (appEngineRequired) {
      const microservicesDefaultDeployment = [...cloudFunctions ?? [], ...cloudRuns ?? []].filter(i => i.defaultDeployment)
      setMicroserviceSelectd(state => [...new Set([...state, ...microservicesDefaultDeployment])])
    }

    if(appEngineRequired && !version.length && project?.isProduction) {
      getVersion()
    }
  }, [appEngineRequired])

  useEffect(() => {
    if (project?.isProduction && microserviceSelected.some(i => i.cloudRun)) {
      const { value } = microserviceSelected.find(i => i.cloudRun)
      getVersionCloudRun(value)
    }

  }, [microserviceSelected])

  const handleBuild = async () => {
    const isEnvironmentSelected = !environment.value
    const versionError = project?.isProduction && !version?.length

    setErrorEnvironments(isEnvironmentSelected ? <I18n path='Global.REQUIRED_FIELD'/> : '')
    setErrorVersion(versionError ? <I18n path='Global.REQUIRED_FIELD'/> : '')

    if (isEnvironmentSelected || versionError)
      return

    setIsLoadingCard(true)

    const cfs = microserviceSelected.filter(i => i.cloudFunction).map(i => (
      {
        id: i.value,
        branchName: getSelectedBranchByRepo(cloudFunctions?.find(j => (j.value === i.value))?.repository),
        branchCore: coreRequired ? getSelectedBranchByRepo('core') : '',
        version: version || environment?.label.toLocaleLowerCase(),
        runtime: runtime.value
      }
    ))

    const crs = microserviceSelected.filter(i => i.cloudRun).map(i => (
      {
        id: i.value,
        branchName: getSelectedBranchByRepo(cloudRuns?.find(j => (j.value === i.value))?.repository),
        branchCore: coreRequired ? getSelectedBranchByRepo('core') : '',
        version: version || environment?.label.toLocaleLowerCase(),
        runtime: runtime.value
      }
    ))

    if(cfs.length || crs.length){
      setCellVoid2(false)
    }

    const appEngineSelected = {
      branchName: getSelectedBranchByRepo(dataAppEngine?.repository?.bitbucket),
      branchCore: coreRequired ? getSelectedBranchByRepo('core') : '',
      version: version || environment?.label.toLocaleLowerCase(),
      runtime: runtime.value
    }

    const pipelinesParams = {
      cloudFunctions: cfs ?? [],
      cloudRuns: crs ?? [],
      appEngine: appEngineRequired ? appEngineSelected : undefined
    }

    const responsePipeline = await createPipeline(pipelinesParams)
    if (appEngineRequired && useServiceDesk && usersSelected.length)
      await createApproveRequest(responsePipeline?.id)

    onCloseModal()
  }

  const handleFinishStep = () => {
    const repositories = WRepositoryRef.current?.getRepositories()
    setSeletedRepositories(repositories)
  }

  const [changeRepository, setChangeRepository] = useState([])

  const requiredRepos = () => {
      
    const repositories = microserviceSelected?.map(j => ({repository:j?.repository}))
    const repositoriesAppEngine = appEngineRequired ? [{repository: dataAppEngine?.repository?.bitbucket}] : []
    const core = ![...microserviceSelected, ...appEngineRequired ? [{coreRequired: dataAppEngine?.coreRequired}] : [] ]?.every((i) => !i.coreRequired) ? [{repository: 'core'}] : []

    setChangeRepository([...core, ...repositories, ...repositoriesAppEngine])

  }

  useEffect(()=>{
    requiredRepos()
  },[microserviceSelected, appEngineRequired ])

  const [cellVoid, setCellVoid] = useState(true)
  const [cellVoid2, setCellVoid2] = useState(true)

  const handleEmpty = () => {
    setCellVoid2(false)
  }

  useEffect(() => {
    if(environment?.label && (microserviceSelected?.length || appEngineRequired)){
      setCellVoid(false)
    }

  }, [environment, microserviceSelected, appEngineRequired])

  const handleChangeVersion = (ev) => {
    const newVesion = ev.target.value
    const regexVersion = /\d{1,2}\-\d{1,2}\-\d{1,2}[A-Za-z]{0,1}$/g

    if (!new RegExp(regexVersion).test(newVesion)) {
      setErrorVersion(`Invalid version ${newVesion} [ ${regexVersion} ]`)
    } else {
      setErrorVersion('')
    }

    setVersion(newVesion)
  }
 
  return (
    <WHorizontalLinearStepper
      childrens={[
        {
          stepName: '',
          element:
            <div className='1st step'>
              <div
                className='bg-[#111111] rounded-none'
              >
                <WSelectField
                  options={environments}
                  onChange={ev => setEnvironment(ev)}
                  value={environment}
                  label={<I18n path='Build.ENVIRONMENT'/>}
                  required
                  errorText={errorEnvironments}
                />

                <WSelectFieldMulti
                  placeholder=''
                  isDisabled = {!cloudFunctions?.length && !cloudRuns?.length}
                  options={[
                    {
                      label: 'Cloud functions',
                      options: cloudFunctions
                    },
                    {
                      label: 'Cloud run',
                      options: cloudRuns
                    }
                  ]}
                  value={microserviceSelected}
                  onChange={setMicroserviceSelectd}
                  label='Microservices'
                  className='mt-[5%]'
                />
                {
                  project?.isProduction &&
                  <WTextFieldV2
                    label={<I18n path='Build.VERSION'/>}
                    required
                    error={errorVersion.length}
                    helperText={errorVersion}
                    value={version}
                    onChange={handleChangeVersion}
                    variant='standard'
                  />
                }

                <WSelectField
                  label={<I18n path='Admin.ENVIRONMENTS.RUNTIME' />}
                  className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                  theme={{ colors: 'neutral0' }}
                  placeholder=''
                  value={runtime}
                  onChange={ev => setRuntime(ev)}
                  options={listRuntime}
                />

                <div>
                  <div className="flex 'mt-2">
                    {dataAppEngine !== '' ?
                      <WCheckbox
                        label='App Engine'
                        checked={appEngineRequired}
                        onChange={() => setAppEngineRequired(!appEngineRequired)}
                        id='appEngineRequired'
                        style={{
                        }}
                      />
                      :
                      <></>
                    }
                   
                  </div>
                </div>
                
              </div>
            </div>,
          emptyCell: cellVoid
        },
        {
          stepName: '',
          element:
            <div className='2nd step'>
              <div
                className='bg-[#111111] rounded-none'
              >

                {microserviceSelected?.length > 0 || appEngineRequired
                  ?
                  <>
                    <WRepository
                      repositories={changeRepository}
                      ref={WRepositoryRef}
                      required
                      handleEmpty={handleEmpty}
                    />
                  </>
                  :
                  <></>
                }
              </div>

              <div>
                {
                  !!permissions.some(i => (i === 'approve-center.post')) &&
                  <div>
                    <WSelectFieldMulti
                      placeholder=''
                      isDisabled = {!useServiceDesk}
                      options={users}
                      value={usersSelected}
                      onChange={setUsersSelected}
                      label={<I18n path='Build.USERS'/>}
                      className='mt-[5%]'
                    />
                    <WCheckbox
                      label='ServiceDesk'
                      checked={useServiceDesk}
                      disabled={!appEngineRequired}
                      onChange={(ev) => setUseServiceDesk(ev.target.checked)}
                      id='useServiceDesk'
                    />
                  </div>
                }
              </div>
            </div>,
          emptyCell: cellVoid2

        },
      ]}
      onHandleFinishStep={handleFinishStep}
      finishStep={
        <div>
          <h1 className='text-center text-lg subpixel-antialiased	'>
            <I18n path='Global.CREATE'/>
          </h1>
          <p className='flex-col'>
            Environment: {environment?.label}
          </p>
          <br/>
          <p className='flex-col'>
            {seletedRepositories?.map(i => (
              <>
                <p>
                  Branch: {i.branch}
                </p>
                <p>
                  Repository: {i.repository}
                </p>
                <br/>
              </>
            ))}
          </p>
          {useServiceDesk &&
          <p className='flex flex-col'>
            <ul>
            Users Service Desk:
            </ul>
            {usersSelected.map(i => (
              <>
                <li key={i.label} className='flex-col'>{i.label}</li>
              </>
            ))}
          </p>
          }

        </div>
      }
      isLoadingButton={isLoadingCard}
      type='Create'
      onSaveClick={() => handleBuild()}
    />
  )

}

export default FormCardClinicorp