import React, { useContext, useState } from 'react'
import { Context } from '../../../context/authContext'
import useApi from '../../../context/hooks/useApi'
import I18n from '../../../components/I18n'
import WButton from '../../../components/WButton'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import WEditor from '../../../components/WEditor'
import api from '../../../context/apiContext'
import { showResponse, showSuccess } from '../../../helper'

interface IFile {
  fileName: string
  data: string
}

export default function MetadataPage() {
  const { permissions } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const { data: file, isLoading, mutate } = useApi<IFile>({ url: 'metadata' })

  const UpdateButtonDisabled = !permissions?.includes('services.metadata.post')

  const handleUpdateMetadata = () => {
    setLoading(true)
    api({
      method: 'POST',
      url: 'metadata',
    }).then(() => {
      mutate()
      showSuccess('Updated File')
    }).catch(error => showResponse(error, null, null, error?.response?.data?.message === 'RUNNING_PROCESS' ? 'info' : 'danger'))
      .finally(() => setLoading(false))
  }

  return (
    <div className='h-full w-full'>
      <div className='h-full sm:grid grid-cols-3'>
        <div className='col-span-3 sm:col-span-2'>
          {
            isLoading && <Loading />
          }

          <WEditor
            filename={file?.fileName}
            code={file?.data}
            language='javascript'
            options={{
              readOnly: true
            }}
          />
        </div>
        <div className='flex w-full flex-col items-center space-y-10'>
          <div className='flex justify-center flex-wrap'>
            <div className='p-[10px] rounded-[10px] bg-[var(--GreyBlack)] w-[300px] text-white'>
              <div>
                <I18n path='Services.INFO_METADATA'/>
              </div>
              <div className='flex justify-center pt-[10px]'>
                <WButton
                  onClick={handleUpdateMetadata}
                  variant='contained'
                  loading={loading}
                  endIcon={<DriveFileRenameOutlineIcon />}
                  loadingPosition='end'
                  disabled={UpdateButtonDisabled}
                  label={<I18n path='Services.BUTTON_UPDATE'/>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}