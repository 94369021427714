import React, { useState } from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

const AddNewSecret = props => {
  const {
    onCloseModal,
    projectId,
    openModal
  } = props

  const [name, setName] = useState()
  const [value, setValue] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [valueError, setValueError] = useState(false)

  const handleConfirm = async () => {
    setNameError(!name?.length)
    setValueError(!value?.length)

    if (!name?.length || !value?.length)
      return

    setIsLoading(true)

    await api({
      method: 'POST',
      url: 'secrets',
      data: {
        name,
        value,
        project:{
          id: projectId
        }
      }
    }).then(({ data }) => {
      showResponseSuccess(data?.message)
      onCloseModal({ needReloadSecrets: true })
    }).catch(error => showResponseError(error))

    setIsLoading(false)
  }

  return (
    <WCustomModal
      buttonLabel={'Confirm'}
      title={<I18n path='Admin.SECRETS.MODAL.ADD.TITLE'/>}
      open={openModal}
      handleClose={onCloseModal}
      buttonOnConfirm={handleConfirm}
      icon={'check_circle'}
      loading={isLoading}
      disabled={false}
      element={
        <div className='flex flex-col w-80 h-80  min-h-min justify-center gap-y-5 p-4 overflow-auto overline'>
          <div className='w-full'>
            <WTextFieldV2
              label={<I18n path='Admin.SECRETS.MODAL.ADD.NAME'/>}
              value={name}
              error={nameError}
              helperText={<I18n path='Admin.SECRETS.MODAL.ADD.HELPER_TEXT_NAME'/>}
              required
              onChange={ev => setName(ev.target.value)}
              variant='standard'
            />
          </div>
          <div className='w-full'>
            <WTextFieldV2
              label={<I18n path='Admin.SECRETS.MODAL.ADD.VALUE'/>}
              value={value}
              error={valueError}
              helperText={<I18n path='Admin.SECRETS.MODAL.ADD.HELPER_TEXT_VALUE'/>}
              required
              onChange={ev => setValue(ev.target.value)}
              variant='standard'
            />
          </div>
        </div>
      }
    />
  )
}

export default AddNewSecret