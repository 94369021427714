import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Radio, { RadioProps } from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

interface ItemRadio {
  label: string | JSX.Element
  value: string | boolean
  disabled?: boolean
}

interface IWRadioGroup {
  options: ItemRadio[]
  label: string | JSX.Element
  defaultValue: string | boolean
  onChangeRadioGroup: (props: string) => void
}

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: '10px',
    background:'#a73804',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#a73804',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#a73804',
    TransitionEvent: '1s'
  },
})

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  )
}

const WRadioGroupV2 = (props: IWRadioGroup) => {
  const {
    label,
    defaultValue,
    options,
    onChangeRadioGroup,
  } = props

  const [value, setValue] = useState(defaultValue ?? options[0]?.value ?? '')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue(value)

    if (typeof onChangeRadioGroup === 'function')
      onChangeRadioGroup(value)
  }

  return (
    <FormControl sx={{color: 'white'}}>
      {
        label &&
      <FormLabel
        sx={{
          color: 'white'
        }}
        required
      >
        {label}
      </FormLabel>
      }
      <RadioGroup
        value={value}
        onChange={handleChange}
      >
        {
          options.map((i, index) => (
            <FormControlLabel
              control={<BpRadio />}
              key={`${i.label}-${index}`}
              value={i.value}
              label={i.label}
              disabled={i.disabled}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

export default WRadioGroupV2