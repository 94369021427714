export default {
  translations: {
    'Loading...': 'Carregando...',
    TOKEN_NOT_INFORMED: 'Token não informado',
    UPDATED_TOKEN: 'Token atualizado',
    Response: {
      SOMETHING_WENT_WRONG: 'Algo deu errado',
      WITHOUT_PERMISSION_CONTACT_ADMINISTRATOR: 'Sem permissão, entre em contato com o administrador',
      WITHOUT_PERMISSION_BUILD_CONTACT_ADMINISTRATOR: 'Sem permissão para buildar, entre em contato com o administrado',
      WITHOUT_PERMISSION_BUILD_ENVIRONMENT_PRODUCTION: 'Sem permissão para buildar em ambiente de produção',
      WITHOUT_PERMISSION_BUILD_CORE: 'Sem permissão para buildar no core',
      ENVIRONMENT_NOT_FOUND: 'Ambiente não encontrado',
      ENVIRONMENT_IS_WORKING_WITH_SELECTED_CLOUD_FUNCTIONS: 'Existe um build em progresso com alguma cloud function selecionada no mesmo ambiente',
      ENVIRONMENT_IS_WORKING_WITH_APP_ENGINE: 'Existe um build em progresso no mesmo ambiente para o App Engine',
      FAILED_TO_START_BUILD: 'Falha ao iniciar o build',
      NOT_FOUND_BUILD_INITIALIZATION: 'Inicialização do build não foi identificada',
      DATA_STORAGE_FAILURE: 'Falha no banco de dados',
      NEW_CLOUD_FUNCTION_CREATED: 'Nova cloud function criada',
      BUILD_STARTED: 'Build iniciado',
      NEW_ENVIRONMENT_CREATED: 'Novo ambiente criado',
      CLOUD_FUNCTION_UPDATED: 'Cloud function atualizada',
      ENVIRONMENT_UPDATED: 'Ambiente atualizado',
      EXCLUDED_CLOUD_FUNCTIONS: 'Microserviços excluídas',
      ENTITY_FOR_CANCEL_BUILD_NOT_FOUND: 'Não encontrado build para cancelar',
      STATUS_TO_CANCEL_NOT_ALLOWED: 'Status atual do build não permite cancelamento',
      YOU_ARE_NOT_ALLOWED_TO_CANCEL_THIS_BUILD: 'Você náo possui permissão pra cancelar este build',
      BUILD_CANCELLED: 'Build cancelado',
      CLINICORP_EXCLUSIVE_SYSTEM: 'Este sistema é exclusivo da clinicorp',
      NEED_TO_RELOG: 'Usuário não identificado, necessário relogar',
      USER_DOES_NOT_HAVE_ADMIN_ACCESS: 'Usuário não tem acesso necessário',
      APPLICATION_IN_MAINTENANCE: 'Aplicação em manutenção',
      NO_USER_FOUND: 'Usuário não encontrado',
      ENVIRONMENTS_NOT_INFORMED: 'Ambiente não informado',
      CLOUD_FUNCTIONS_NOT_INFORMED: 'Cloud function não informado',
      BUILD_ID_NOT_INFORMED: 'Id do buil não informado',
      UID_NOT_INFORMED: 'Uid não informado',
      TOKEN_NOT_INFORMED: 'Token não informado',
      ENVIRONMENT_NOT_INFORMED: 'Ambiemte não informado',
      CLOUD_FUNCTION_NOT_INFORMED: 'Cloud function não informado',
      DELETED_ENVIRONMENTS: 'Ambientes excluídos',
      INVALID_PROJECT: 'Projeto inválido',
      NAME_IN_USE: 'Nome em uso',
      NAME_NOT_INFORMED: 'Nome não informado',
      SYNTHETIC_TEST_STARTED: 'Teste sintético iniciado',
      SYNTHETIC_TESTING_STATUS_NOT_ALLOWED: 'Statue de teste sintético não permitido',
      NOT_FOUND_BUILD_ID: 'Id do build não identificado',
      PROMOTING_VERSION: 'Promovendo versão',
      REVERTING_VERSION: 'Revertendo a versão',
      VERSION_ALREADY_USED: 'Versão já utilizada',
      SELECTED_VERSION_IS_NOT_CURRENT: 'Versão selecionada não é atual',
      EDITED_USERS: 'Usuários editados',
      TOKEN_EXPIRED: 'Token expirado',
      UPDATED_STATUS: 'Status atualizado',
      SERVICE_STARTED: 'Seviço iniciado',
      RUNNING_PROCESS: 'Processo em execução',
      FAILED_TO_RUN_SERVICE: 'Falha ao iniciar o serviço',
      GENERATED_FILE: 'Arquivo gerado',
      FORBIDEN: 'Proibido',
      USER_DOES_NOT_HAVE_ACCESS: 'Usuário não tem acesso',
      SECRET_CREATED: 'Segredo criado',
      SECRET_UPDATED: 'Segredo atualizado',
      SECRET_DELETED: 'Segredo removido',
      CLOUD_RUN_CREATED: 'Cloud run criado',
      CLOUD_RUN_EDITED: 'Cloud run editado',
      EXCLUDED_CLOUD_RUN: 'Cloud run excluído',
    },
    Global: {
      WARNING: 'Alerta',
      ERROR: 'Erro',
      FAILURE: 'Falha',
      SUCCESS: 'Sucesso',
      INFO: 'Informativo',
      ALERT: 'Alerta',
      COPIED_LINK: 'Link copiado',
      LOGOUT: 'Sair',
      CANCELLED: 'Cancelado',
      WORKING: 'Em execução',
      CANCEL: 'Cancelar',
      CONFIRM: 'Confirmar',
      TOKEN_EXPIRED: 'Token expirado',
      TOKEN_NOT_FOUND: 'Token não encontrado, por favor faça login novamente',
      USER: 'Usuário',
      ENVIRONMENT: 'Ambiente',
      TESTING: 'Testando',
      WAITING_TO_PROMOTE: 'Aguardando promote',
      TEST_SYNTHETIC_FAILED: 'Falha no teste sintético',
      PROMOTING: 'Promovendo',
      TRAFFIC: 'Tráfego',
      NO_OPTIONS: 'Sem opções',
      REQUIRED_FIELD: 'Campo obrigatório',
      AGO: 'atrás',
      NEXT: 'Próximo',
      PREVIOUS: 'Anterior',
      CREATE: 'Pronto para criar ',
      QUEUED: 'Em Espera',
      ACTIONS: 'Ações',
      TIMEOUT: 'Timeout',
    },
    Header: {
      HELLO: 'Olá'
    },
    Home: {
      MENU_CARD: {
        BUILD: {
          TITLE: 'Build',
          SUB_TITLE: 'Faça sua própria versão de teste, atualize seus Microserviços'
        },
        APP_CARE: {
          TITLE: 'Cuidados com aplicação',
          SUB_TITLE: ''
        },
        ADMIN: {
          TITLE: 'Admin',
          SUB_TITLE: 'Gerenciar espaço de trabalho'
        },
        APPLICATION: {
          TITLE: 'Aplicação',
          SUB_TITLE: 'Gerenciar aplicação'
        },
        SERVICES: {
          TITLE: 'Serviços',
          SUB_TITLE: 'Execução de serviços comuns'
        },
        APPROVE_CENTER: {
          TITLE: 'Centro de Aprovação',
          SUB_TITLE: 'Aprovar Requests'
        }
      },
    },
    Build: {
      CLEAR_OPTIONS: 'Limpar opções',
      DEFAULT_OPTIONS: 'Opções padrão',
      ENVIRONMENT: 'Ambiente',
      BRANCH: 'Branch',
      BRANCH_FROM_CORE: 'Branch no core',
      CANCEL_BUILD: 'Deseja cancelar o(s) build(s)?',
      CONFIRM_RETRY_SYNTHETIC_TESTS: 'Deseja repetir os testes sintéticos?',
      CONFIRM_PROMOTE_VERSION: 'Deseja atualizar a versão?',
      CONFIRM_ROLLBACK_VERSION: 'Deseja reverter a versão?',
      STARTED_PROMOTED_AT: 'Iniciou o promote em',
      ENDED_PROMOTED_AT: 'Terminou o promote em',
      VERSION: 'Versão',
      USERS: 'Usuários',
    },
    AppCare: {
      CHECK_ROUTES: {
        NAME: 'Checar rotas',
        TABLE: {
          HEADERS: {
            CREATED_AT: 'Criado em',
            PROMOTED_AT: 'Promovido em',
            MODIFICATIONS: 'Modificações',
            FILTERS: {
              MODIFICATIONS: {
                TRUE: 'Verdadeiro',
                FALSE: 'Falso',
              }
            },
          },
          ROWS: {
            MODIFICATIONS: {
              TRUE: 'Possui modificações',
              FALSE: 'Não possui modificaçoes'
            }
          },
          COLLAPSE_ROWS: {
            COMPARE:{
              MORE: 'Adicionada',
              LESS: 'Removida',
              MODIFIED: 'Modificada',
              EQUAL: 'Igual',
            }
          },
          COLLAPSE_HEADERS: {
            REQUIRE_AUTHENTICATION: 'Requer autenticação',
            MODIFICATIONS: 'Modificações',
          },
        }
      },
      STATUS_PAGE: {
        NAME: 'Página de Status',
        SHOWCASE: 'Showcase',
        DEBUG: 'Abrir Logs do GCP',
        STATUS: {
          OPERATIONAL: 'Operacional',
          UNDER_MAINTENANCE: 'Em Manutenção',
          DEGRADED_PERFORMANCE: 'Desempenho Degradado',
          PARTIAL_OUTAGE: 'Interrupção Parcial',
          MAJOR_OUTAGE: 'Interrupção Grave'
        },
        MESSAGE: {
          SUCCESS: 'Componente Criado com Sucesso',
          EDIT: 'Editado com Sucesso',
          DELETE: 'Deletado com Sucesso'
        },
        TABLE: {
          ENDPOINT: 'Endpoint',
          SUBCOMPONENTS: 'Subcomponents',
          SLO: 'SLO',
          STATUS: 'Status',
          GROUP: 'Grupos',
          DEBUG: 'Debug',
        },
        FORMULARIO: {
          NAME_COMPONENT: 'Nome do Componente'
        },
        COMPONENT: 'Componente',
        NEW_NAME: 'Novo nome',
        EDIT_GROUP: 'Editar Grupo'
      }
    },
    Admin: {
      MENU_CARD: {
        USERS: {
          TITLE: 'Usários',
          SUB_TITLE: 'Gerenciar usuários'
        },
        ENVIRONMENTS: {
          TITLE: 'Ambientes',
          SUB_TITLE: 'Gerenciar ambientes'
        },
        CLOUD_FUNCTIONS: {
          TITLE: 'Microserviços',
          SUB_TITLE: 'Gerenciar microserviços'
        },
        APP_ENGINE: {
          TITLE: 'App Engine',
          SUB_TITLE: 'Gerenciar GAE',
        },
      },
      IAM: {
        TITLE: 'IAM',
        USERS: 'Usuários',
        ROLES: 'Papéis',
        MODAL: {
          NAME: 'Nome',
          ASSIGNED_PERMISSIONS: 'Permissões atribuídas',
          ADD_ROLE: 'Adicionar papel',
          EDIT_ROLE: 'Editar papel',
          DELETE_ROLE: 'Remover papel',
        },
        TABLE: {
          ROLES: {
            NAME: 'Nome',
            PERMISSIONS: 'Permissões'
          }
        }
      },
      MICROSERVICES: {
        TITLE: 'Microsserviços',
        CLOUD_FUNCTIONS: 'Cloud functions',
        CLOUD_RUN: 'Cloud run'
      },
      SECRETS: {
        MODAL: {
          ADD: {
            BTN_CONFIM: 'Confirmar',
            TITLE: 'Adicionar segredo',
            NAME: 'Nome',
            VALUE: 'Valor',
            PROJECT: 'Projeto',
            HELPER_TEXT_NAME: 'Nome é obrigatório',
            HELPER_TEXT_VALUE: 'Valor é obrigatório',
            HELPER_TEXT_PROJECT: 'Projeto é obrigatório',
          },
          EDIT: {
            BTN_CONFIM: 'Confirmar',
            TITLE: 'Atualizar segredo',
            NAME: 'Nome',
            VALUE: 'Valor',
            PROJECT: 'Projeto',
            HELPER_TEXT_VALUE: 'Valor é obrigatório',
          },
          DELETE: {
            BTN_CONFIM: 'Confirmar',
            TITLE: 'Remover segredo',
            RESPONSE_SECRETS_IN_USE_CF: 'O segredo {{secretName}} está em uso na cloud function {{cfName}} com env {{envName}}',
            RESPONSE_SECRETS_IN_USE_CR: 'O segredo {{secretName}} está em uso na cloud run {{crName}} com env {{envName}}',
          },
        }
      },
      APP_ENGINE: {
        NAME: 'App Engine',
        PREFIX: 'Prefixo',
        CORE_REQUIRED: 'Core necessário',
        BTN_SAVE: 'Salvar',
        REPOSITORY: 'Repositório'
      },
      ENVIRONMENTS: {
        TABLE: {
          ENVIRONMENTS: 'Ambientes',
          CLOUD_FUNCTIONS: 'Cloud functions',
          ADD: 'Adicionar',
          EDIT: 'Editar',
          DELETE: 'Delete',
        },
        ENVIRONMENT: 'Ambiente',
        CANCEL: 'Cancelar',
        REMOVE: 'Remover',
        REMOVE_ENVIRONMENT: 'Remover ambiente',
        CLOUD_FUNCTION_NOT_SELECTED: 'Nenhuma cloud function foi selecionada'
      },
      CLOUD_FUNCTIONS: {
        TABLE: {
          CLOUD_FUNCTION: 'Cloud function',
          NAME: 'Nome',
          MEMORY: 'Memória',
          RUNTIME: 'Runtime',
          TIMEOUT: 'Timeout',
          ADD: 'Adicionar',
          EDIT: 'Editar',
          DELETE: 'Deletar',
          GENERATION: 'Geração',
          REPOSITORY: 'Repositório',
          REGION: 'Região',
          INSTANCES: 'Instâncias',
          PREFIX: 'Prefixo',
          CORE_REQUIRED: 'Core necessário',
          DEFAULT_DEPLOYMENT: 'Implantação padrão',
          VPC_CONNECTOR: 'VPC connector',
        },
        REMOVE: 'Remover',
        REMOVE_CLOUD_FUNCTION: 'Remover cloud function',
        CREATE: 'Criar',
        CREATE_CLOUD_FUNCTION: 'Criar cloud function',
        CANCEL: 'Cancelar',
        MAX_INSTANCES: 'Máximo de instâncias',
        PROJECT: 'Projeto',
        SAVE: 'Salvar',
      },
      CLOUD_RUN: {
        TABLE: {
          NAME: 'Nome',
          MEMORY: 'Memória',
          TIMEOUT: 'Timeout',
          ADD: 'Adicionar',
          EDIT: 'Editar',
          DELETE: 'Remover',
          REPOSITORY: 'Repositório',
          REGION: 'Região',
          MIN_INSTANCES: 'Número mínimo de instâncias',
          MAX_INSTANCES: 'Número máximo de instâncias',
          AUTHENTICATION: 'Autenticação',
          CONCURRENCY: 'Solicitações máximas por container',
          CPU: 'CPU',
          PREFIX: 'Prefixo',
          CLOUD_SQL: 'Cloud SQL instances',
          CORE_REQUIRED: 'Core necessário',
          DEFAULT_DEPLOYMENT: 'Implantação padrão',
          VPC_CONNECTOR: 'VPC connector',
        },
        RADIO_GROUP: {
          AUTHENTICATION: {
            REQUIRED: 'Requer autenticação',
            NOT_REQUIRED: 'Permitir invocações não autenticadas',
          }
        },
        NOT_SELECTED: 'Cloud run não selecionado',
        REMOVE: 'Remover',
        REMOVE_CLOUD_RUN: 'Remover cloud run',
        CANCEL: 'Cancelar',
        MAX_INSTANCES: 'Maximo de instâncias',
        PROJECT: 'Projeto',
        SAVE: 'Salvar',
      },
      USERS: {
        NO_USER_IDENTIFIED: 'Nenhum usuário identificado',
        EDIT_USER: 'Editar usuário',
        EDIT_USERS: 'Editar usuários',
        DELETE_USER: 'Excluir usuário',
        DELETE_USERS: 'Excluir usuários',
        ENVIRONMENTS: 'Ambientes',
        ADD_USER: 'Adicionar usuário',
        ADD: {
          SUCCESS: 'Usuário adicionado com sucesso',
        },
      }
    },
    Application: {
      MESSAGE: 'Mensagem',
      CONFIGURATIONS: 'Configurações',
      VARIABLES: 'Variáveis',
      PROJECTS: {
        NAME: 'Projetos',
        NEW_NAME: 'Novo Projeto',
        TABLE: {
          NAME: 'Nome',
          PROJECT_ID: 'ID do projeto',
          PROJECT_NUMBER: 'Número do projeto',
          IS_PRODUCTION: 'Produtivo',
          ICON: 'Ícone',
          SERVICE: 'Serviço',
          GIT: 'Git',
          USER: 'Usuário',
          PIPELINE: 'Pipeline',
          STATUS: 'Status',
          TEMPLATE_URL_APP_ENGINE: 'Template url app engine',
          TEMPLATE_URL_CLOUD_RUN: 'Template url cloud run',
          TEMPLATE_URL_CLOUD_FUNCTION: 'Template url cloud function',
          APP_ENGINE: 'App Engine',
          ENVIRONMENT: 'Ambiente',
          DETAILS: 'Detalhes'
        },
        MODAL: {
          ICON: 'Ícone',
          NAME: 'Nome',
          PROJECT_ID: 'Id do projeto',
          PROJECT_NUMBER: 'Número do projeto',
          IS_PRODUCTION: 'Produtivo',
          ADD: {
            TITLE: 'Adicionar projeto',
            BTN_SAVE: 'Confirmar',
          },
          EDIT: {
            TITLE: 'Editar projeto',
            BTN_SAVE: 'Salvar',
          },
          DELETE: {
            TITLE_one: 'Remover projeto',
            TITLE_other: 'Remover projetos',
            BTN_SAVE: 'Deletar',
          }
        }
      },
      ASSIGNED_PERMISSIONS: {
        NAME: 'Permissões atribuídas',
        TABLE: {
          PERMISSION: 'Permissão',
        },
        MODAL: {
          PERMISSION: 'Permissão',
          ADD: {
            TITLE: 'Adicionar permissão',
            BTN_SAVE: 'Confirmar',
          },
          EDIT: {
            TITLE: 'Editar permissão',
            BTN_SAVE: 'Salvar',
          },
          DELETE: {
            TITLE_one: 'Remover permissão',
            TITLE_other: 'Remover permissões',
            BTN_SAVE: 'Deletar',
          }
        }
      },
      REPOSITORIES: {
        NAME: 'Repositórios',
        TABLE: {
          NAME: 'Nome',
          GCP: 'GCP',
          BITBUCKET: 'Bitbucket',
          URL: 'Url',
        },
        MODAL: {
          NAME: 'Nome',
          GCP: 'GCP',
          BITBUCKET: 'Bitbucket',
          URL: 'Url',
          ADD: {
            TITLE: 'Adicionar repositório',
            BTN_SAVE: 'Confirmar',
          },
          EDIT: {
            TITLE: 'Editar repositório',
            BTN_SAVE: 'Salvar',
          },
          DELETE: {
            TITLE_one: 'Remover repositório',
            TITLE_other: 'Remover repositórios',
            BTN_SAVE: 'Deletar',
            RESPONSE_REPOSITORIES_IN_USE_CF: 'Repositório {{repositoryName}} está em uso na cloud function {{cfName}}',
            RESPONSE_REPOSITORIES_IN_USE_CR: 'Repositório {{repositoryName}} está em uso no cloud run {{crName}}',
          }
        },
      },
      SAVE: 'Salvar',
      STATUS: {
        RUNNING: 'Em execução',
        MAINTENANCE: 'Manutenção'
      }
    },
    APPROVE_CENTER: {
      PROJECTS: {
        TABLE: {
          USERS: 'Usuários',
          OWNER: 'Proprietário',
          TYPE: 'Tipo',
          STATUS: 'Status',
          ENVIRONMENT: 'Ambiente',
          APPROVED_BY: 'Aprovador por',
          ACTION_BY: 'Realizado por',
          WAIT_APPROVE: 'Aguardando Aprovação',
          ACTION: 'Ação',
          SERVICE_DESK: {
            MESSAGE: 'Você deseja liberar o service desk para os e-mail(s): '
          }
        },
        MODAL: {
          TITLE: 'Pedido'
        },
        BUTTON: {
          OPEN_REQUEST: 'Visualizar Solicitação',
          REJECT: 'Rejeitar',
          APPROVE: 'Aprovar'
        }
      }
    },
    Services: {
      CLOUD_RUN: {
        INFO_GENERATE_CODE: 'Atualizar o arquivo generate-code',
        BUTTON_UPDATE: 'Atualizar'
      },
      INFO_METADATA: 'Atualizar o arquivo metadata.js',
      BUTTON_UPDATE: 'Atualizar'
    },
    Routes: {
      admin: 'Admin',
      home: 'Início',
      build: 'Build',
      appCare: 'App care',
      users: 'Usuários',
      environments: 'Ambientes',
      cloudFunctions: 'Cloud functions',
      application: 'Aplicação',
      services: 'Serviços',
    },
    Timestamp: {
      SECOND: 'segundo',
      SECONDS: 'segundos',
      MINUTE: 'minuto',
      MINUTES: 'minutos',
      HOUR: 'hora',
      HOURS: 'horas',
      SECONDS_one: 'segundo',
      SECONDS_other: 'segundos',
      MINUTES_one: 'minuto',
      MINUTES_other: 'minutos',
      HOURS_one: 'hora',
      HOURS_other: 'horas',
      STARTED: 'Iniciado',
      FINISHED: 'Finalizado',
      DAYS: 'dias',
      DAY: 'dia',
      DAYS_other: 'dias',
      DAYS_one: 'dia',
    },
    COMPONENTS: {
      WTABLE: {
        HEADER: {
          FILTERS:{
            LIKE: 'Contém',
            NOT_LIKE: 'Não contém',
            EQUAL: 'Igual',
            NOT_EQUAL: 'Diferente',
            LESS_THAN_EQUAL: 'Menor ou igual',
            LESS: 'Menor',
            MORE: 'Maior',
            MORE_THAN_EQUAL: 'Maior ou igual',
            CLEAN: 'Limpar',
          }
        },
        FOOTER:{
          ROWS_PER_PAGE: 'Linhas por página'
        }
      },
      WSECRETS: {
        TITLE: 'Segredos',
        ADD: 'Adicionar',
        NOT_SAVED: 'Não salvo',
        LABEL: {
          SECRET: 'Segredo',
          NAME: 'Nome',
          VERSION: 'Versão',
          DONE: 'Salvar',
        },
        ACCORDION_EXPANDED: {
          NEW: 'Referencie um segredo',
          EDIT: 'Editar uma referência secreta',
        },
        ERRORS: {
          ENV_REQUIRED: 'variável de ambiente obrigatório',
          VERSION_REQUIRED: 'versão obrigatória',
          SECRET_REQUIRED: 'segredo obrigatório'
        },
      },
      WENVIRONMENTVARIABLE: {
        TITLE: 'Variáveis de ambiente',
        ADD: 'Adicionar',
      },
      WKEYVALUE: {
        LABEL: {
          ENV: 'Env',
          VALUE: 'Valor',
        }
      },
      WGSUTIL: {
        TITLE: 'Cópia de arquivos',
        ADD: 'Adicionar',
        SOURCE: 'Origem',
        DEST: 'Destino',
      },
    }
  },
}