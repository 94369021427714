import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import WSelectBranch from './WSelectBranch'


const WRepository = (props, ref) => {

  const {
    repositories,
    handleEmpty
  } = props

  const [repository, setRepository] = useState([])
  const [filtredRepositories, setFiltredRepositories] = useState([])

  useEffect(() => {
    const newRepositories = repositories?.filter((value, index, self) => self.findIndex((m) => m.repository === value.repository) === index).sort((a, b) => a.repository < b.repository ? - 1 : a.repository > b.repository ? 1 : 0)
    setFiltredRepositories(newRepositories)
  }, [repositories])

  useEffect(() => {
    if(repository.length === 0) {
      return
    } else if (filtredRepositories.length === repository.length){
      handleEmpty()
    }
    
  },[repository ])


  const handleChangeBranch = (valor) => {

    const newRepository = repository.filter(i => (i.repository !== valor.repository)).concat(valor)
    setRepository(() => newRepository)
  }

  const getRepositories = () => repository

  useImperativeHandle(ref, () => ({
    getRepositories
  }))

  return(
    <>
      {
        filtredRepositories
          ?.map(i => (
            <>
              <WSelectBranch
                id={i.repository}
                placeholder=''
                repository={i.repository}
                onChange={handleChangeBranch}
                label={`Repository: ${i.repository}`}
                className='mt-[5%]'
                required
              />
            </>
          ))
      }
    </>
  )
}

export default forwardRef(WRepository)