import React from 'react'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import svgAppEngineOn from '../../svg/app-engine-on.svg'
import svgCloudFuntionsOn from '../../svg/cloud-functions-on.svg'


const CommitAppengineCF = (props) => {
  const {
    cloudFunctions,
    cloudRuns,
    isAppEngine,
  } = props


  const getTooltipCloudFunctions = () => {
    return (
      <div>
        <p>Microservices</p>
        {
          (!!cloudFunctions?.length && !!cloudRuns?.length) && <>Cloud functions</>
        }
        {
          cloudFunctions?.map((i, index) => (
            <div key={`${i.name}-${index}`}>{i.name}</div>
          ))
        }
        {
          (!!cloudFunctions?.length && !!cloudRuns?.length) && <>Cloud runs</>
        }
        {
          cloudRuns?.map((i, index) => (
            <div key={`${i.name}-${index}`}>{i.name}</div>
          ))
        }
      </div>
    )
  }

  const style = [
    {
      ...isAppEngine ?
        {
          cursor:'pointer'
        }
        :
        {
          opacity: 0.3
        }
    },
    {
      ...(cloudFunctions?.length || cloudRuns?.length)?
        {
          cursor:'pointer'
        }
        :
        {
          opacity: 0.3
        }
    }
  ]


  return (
    <div>

      <div className='flex items-center justify-center'>
        <Tooltip
          tooltip={getTooltipCloudFunctions()}
          tooltipStyle={{ fontSize: '12px' }}
          disabled={(!cloudFunctions?.length && !cloudRuns?.length) ? true : false}
        >
          <img
            className='svg-list-build '
            src={svgCloudFuntionsOn}
            style={style[1]}
          />
        </Tooltip>

        <Tooltip
          tooltip={'App engine'}
          tooltipStyle={{ fontSize: '12px' }}
          disabled={!isAppEngine ? true : false}
        >
          <img
            className='svg-list-build text-white'
            src={svgAppEngineOn}
            style={style[0]}
          />
        </Tooltip>

      </div>
    </div>
  )
}

export default CommitAppengineCF