import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import Icon from '@mui/material/Icon'
import I18n from '../../../components/I18n'

const DetailsTimestamp = props => {
  const { i18n } = useTranslation()

  const {
    createdTime,
    startTime,
    finishTime,
    status
  } = props

  const getTitleDuration = (start, end) => {
    const initDate = new Date(start[0]).toLocaleString(i18n.language)
    const endDate = new Date(end[0]).toLocaleString(i18n.language)
    const now = new Date().toLocaleString(i18n.language)

    const isValidEndDate = endDate.toString() !== 'Invalid Date'
    const finishStatus = status !== 'QUEUED' && status !== 'WORKING'

    return (
      <div>
        <p><I18n path='Timestamp.STARTED'/>: {initDate.replace(now.split(/\s/)[0], '')}</p>
        {(isValidEndDate && finishStatus) && <p><I18n path='Timestamp.FINISHED' />: {endDate.replace(now.split(/\s/)[0], '')}</p>}
      </div>
    )
  }

  return (
    <div className='d-flex align-items-center'>
      <Tooltip
        tooltip={getTitleDuration(startTime ?? createdTime, finishTime)}
        tooltipStyle={{ fontSize: '12px' }}
        className='flex text-white cursor-help'
      >
        <Icon fontSize='small'>timelapse</Icon>
      </Tooltip>
    </div>
  )
}

export default DetailsTimestamp