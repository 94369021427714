import React from 'react'
import Row from './Row'

const BodyRows = (props) => {
  const {
    rows,
    limitForPage,
    checkboxRows,
    colSpanOnHoverRow,
    getProps
  } = props

  return (
    <>
      {
        rows.concat(Array(Math.max(limitForPage - rows.length, 0)).fill(null)).map((row, rowIndex) => {
          return (
            <Row
              key={`_${rowIndex}`}
              checkboxRows={checkboxRows}
              colSpanOnHoverRow={colSpanOnHoverRow}
              rowIndex={rowIndex}
              getProps={getProps}
              row={row}
            />
          )
        })
      }</>
  )
}

export default BodyRows