import React, { useState, useEffect, useImperativeHandle, forwardRef, useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import I18n from '../../I18n'
import { WRow } from './WRow'
import { StyledTablePagination } from './commons'
import WTableHead from './WTableHead'
import { CONSTANTS } from '../../../js/res/CONSTANTS'
import { Button, Icon, IconButton } from '@mui/material'
import { Context } from '../../../context/authContext'
import WSelectFieldV2 from '../../WSelectField/V2'

const WTable = (props, ref) => {
  const { permissions } = useContext(Context)

  const {
    headers,
    rows,
    isCollapse,
    collapseHeaders,
    isSelectableAll,
    onCollapse,
    isLoadingData,
    onChangeRowsPerPage,
    onChangePage,
    onChangePageCollapse,
    handleChangeRowsPerPageRowCollapse,
    limitCollapse,
    optionsRowsPerPage,
    countRows,
    limit,
    order,
    orderBy,
    onChangeSort,
    orderCollapse,
    orderByCollapse,
    onChangeSortCollapse,
    onChangeFilters,
    onChangeFiltersCollapse,
    tableHeader,
    onAddNewRow,
    onEditRow,
    onDeleteRow,
    permissionsTableHeader,
    tabTopMenu,
    hiddenHeader,
    iconSerchable,
    selectTopMenu,
    onEditCollapseRow,
    onDeleteCollapseRow,
  } = props

  const [selected, setSelected] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(limit ?? 10)
  const [page, setPage] = useState(0)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((row, index) => row.key ?? index)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isSelected = key => selected?.indexOf(key) !== -1

  const onChangeSelecteds = newSelecteds => setSelected(newSelecteds)

  const clearCheckedValues = () => setSelected([])

  const updateFilters = (filters) => {
    handleChangeFilters([filters])
  }

  const getIdCheckedValues = () => {
    const selectedRow = rows?.filter((_, index) => selected?.includes(index))
    return selectedRow?.flatMap(i => i.cells.filter(j => j.key === 'id'))?.map(i => i.value)
  }

  const getCheckedRows = () => rows?.filter((_, index) => selected?.includes(index)).map(i => Object?.assign({}, ...i.cells.flatMap(j => ({ [j.key]: j.value }))))

  useImperativeHandle(ref, () => ({
    clearCheckedValues,
    updateFilters,
    getIdCheckedValues,
    getCheckedRows,
  }))

  const rowsSelectables = rows?.filter(row => row?.isSelectable)

  const handleChangePageRow = (rowId, newPage) => {
    if(typeof onChangePageCollapse === 'function'){
      onChangePageCollapse(rowId, newPage)
    }
  }

  const handleChangeRowsPerPageRow = (rowId, rowsPerPage) => {

    if(typeof handleChangeRowsPerPageRowCollapse === 'function'){
      handleChangeRowsPerPageRowCollapse(rowId, rowsPerPage)
    }
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'

    if(typeof onChangeSort === 'function'){
      onChangeSort(isAsc ? 'desc' : 'asc', property)
    }
  }

  const handleRequestSortCollapse = (event, property) => {
    const isAsc = orderByCollapse === property && orderCollapse === 'asc'
    
    if(typeof onChangeSortCollapse === 'function'){
      onChangeSortCollapse(isAsc ? 'desc' : 'asc', property)
    }
  }

  const handleChangeFilters = filters => {
    if (typeof onChangeFilters === 'function')
      onChangeFilters(filters.length ? `&filters=${encodeURI(JSON.stringify(filters))}` : '')
  }

  const handleChangeFiltersCollapse = filters => {
    if (typeof onChangeFiltersCollapse === 'function')
      onChangeFiltersCollapse(filters.length ? `&filters=${encodeURI(JSON.stringify(filters))}` : '')
  }

  useEffect(() => {
    if (typeof onChangeRowsPerPage === 'function')
      onChangeRowsPerPage(rowsPerPage)
  }, [rowsPerPage])

  useEffect(() => {
    if (typeof onChangePage === 'function')
      onChangePage(page)
  }, [page])

  const contentAddPermission = permissions?.some(i => (i === permissionsTableHeader?.add))
  const contentEditPermission = permissions?.some(i => (i === permissionsTableHeader?.edit))
  const contentDeletePermission = permissions?.some(i => (i === permissionsTableHeader?.remove))

  return (
    <div className='w-full h-full'>
      {
        isLoadingData && <Loading />
      }
      {
        tableHeader &&
        <div
          className={'w-full p-3 mb-5 rounded-sm'}
          style={{
            background: CONSTANTS.Style.GreyBlackColor
          }}
        >
          <div className='flex justify-between'>
            <div className='flex'>
              <IconButton
                style={{
                  color: CONSTANTS.Style.White,
                  opacity: !contentAddPermission ? 0.4 : 1,
                }}
                onClick={() => onAddNewRow()}
                disabled={!contentAddPermission}
              >
                <Icon fontSize='small'>{'add'}</Icon>
              </IconButton>
              <IconButton
                disabled={selected.length !== 1 || !contentEditPermission}
                style={{
                  opacity: (selected.length !== 1 || !contentEditPermission) ? 0.4 : 1,
                  color: CONSTANTS.Style.White
                }}
                onClick={() => onEditRow(selected)}
              >
                <Icon fontSize='small'>{'edit'}</Icon>
              </IconButton>
              <IconButton
                onClick={() => onDeleteRow(selected)}
                disabled={!selected?.length || !contentDeletePermission}
                style={{
                  opacity: (!selected?.length || !contentDeletePermission) ? 0.4 : 1,
                  color: CONSTANTS.Style.White
                }}
              >
                <Icon color='#fff' fontSize='small'>{'delete'}</Icon>
              </IconButton>
            </div>
            <div className='flex'>
              {
                tabTopMenu?.map((i, index) => (
                  <Button
                    key={`${i.label}_${index}`}
                    type={i.type}
                    startIcon={i.startIcon}
                    endIcon={i.endIcon}
                    style={{ opacity: i.disabled ? 0.4 : 1, margin: '0 5px ', ...i.style }}
                    onClick={i.onClick}
                    disabled={i.disabled}
                    variant={!i.selected ? 'outlined' : 'contained'}
                    sx={{
                      background: !i.selected ? '' : CONSTANTS.Style.NewOrangeClinicorp,
                      color: !i.selected ? CONSTANTS.Style.NewOrangeClinicorp : 'white',
                      borderColor: CONSTANTS.Style.NewOrangeClinicorp
                    }}
                  >{i.label}</Button>
                ))
              }
              <div className='w-52'>
                { selectTopMenu?.map((i, index) => (
                  <WSelectFieldV2
                    key={`${i.id}_${index}`}
                    label={i.label}
                    value={i.value}
                    options={i.options}
                    onChange={i.onChange}
                    className='border-b border-transparent border-b-[#a73804] text-white'
                  />
                ))
                }
              </div>
            </div>
          </div>
        </div>
      }

      <TableContainer
        component={Paper}
        className='shadow-2xl  bg-[transparent] h-[100%] backdrop-blur-md'
      >
        <Table stickyHeader >
          <WTableHead
            headers={headers}
            hiddenHeader={hiddenHeader}
            isSelectableAll={isSelectableAll}
            rowsSelectables={rowsSelectables}
            isCollapse={isCollapse}
            onSelectAllClick={handleSelectAllClick}
            onChangeSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            selected={selected}
            onChangeFilters={handleChangeFilters}
            containsSeletable={rows?.some(i => i.isSelectable)}
            iconSerchable={iconSerchable}
          />
          <TableBody className='overflow-auto'>
            {
              rows?.map((row, index) => {
                row.key = row.key || index
                return (
                  <WRow
                    row={row}
                    headers={headers}
                    isCollapse={row.isCollapse}
                    collapseHeaders={collapseHeaders}
                    isSelected={isSelected}
                    key={row.key}
                    selected={selected}
                    onChangeSelecteds={onChangeSelecteds}
                    onCollapse={onCollapse}
                    handleChangePageRow={handleChangePageRow}
                    handleChangeRowsPerPageRow={handleChangeRowsPerPageRow}
                    limitCollapse={limitCollapse}
                    orderCollapse={orderCollapse}
                    orderByCollapse={orderByCollapse}
                    onChangeSortCollapse={handleRequestSortCollapse}
                    onChangeFiltersCollapse={handleChangeFiltersCollapse}
                    onEditCollapseRow={onEditCollapseRow}
                    onDeleteCollapseRow={onDeleteCollapseRow}
                  />
                )
              })
            }
          </TableBody>
        </Table>
        <StyledTablePagination
          className='flex'
          rowsPerPageOptions={optionsRowsPerPage ?? [10, 25, 100]}
          labelRowsPerPage={<I18n path='COMPONENTS.WTABLE.FOOTER.ROWS_PER_PAGE' />}
          count={countRows ?? rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </div>
  )
}

export default forwardRef(WTable)