import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { MenuCard } from '@clinicorp/designsystem.components.basic.panels.menucard'
import I18n from '../../components/I18n'
import { Context } from '../../context/authContext'

const Home = () => {
  const navigate = useNavigate()
  const { permissions } = useContext(Context)

  const cardsHome = routes.home.subPaths

  return (
    <div className='h-full flex items-center justify-center flex-wrap'>
      {
        Object.keys(cardsHome).map(card => (
          (!cardsHome[card].permissions || cardsHome[card].permissions.some(perm => permissions?.includes(perm))) ?
            <MenuCard
              key={cardsHome[card].title}
              icon={cardsHome[card].icon}
              onClick={() => navigate(cardsHome[card].getRoute())}
              title={<I18n path={cardsHome[card].title}/>}
              size='medium'
              className='tiles-application'
              circleStyle={{backgroundColor: 'var(--NewOrangeClinicorp)'}}
              subtitle={<I18n path={cardsHome[card].description}/>}
            />
            :
            <></>
        ))
      }
    </div>
  )
}

export default Home
