import React from 'react'
import WSelectField from '../WSelectField/V2'
import {
  LimitedChipsContainer,
  defaultTheme,
  customStyles,
  Control,
  LoadingMessage,
  NoOptionsMessage,
} from '../ReactSelect/components'

const WSelectFieldMuilti = props => {
  const {
    placeholder,
    limitChips,
    components,
    ...selectSearchProps
  } = props

  return (
    <WSelectField
      theme={defaultTheme}
      className='wselect-multi text-base'
      classNamePrefix='wselect-multi'
      placeholder={placeholder}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      limitChips={limitChips}
      style={customStyles}
      isMulti
      components={{
        Control,
        ValueContainer: LimitedChipsContainer,
        NoOptionsMessage,
        LoadingMessage,
        ...components
      }}
      {...selectSearchProps}
    />
  )
}


export default WSelectFieldMuilti