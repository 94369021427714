import React from 'react'
import api from '../../../../context/apiContext'
import { showError, showResponseError, showResponseSuccess } from '../../../../helper'
import WCustomModal from '../../../../components/WModal/V3'

const ModalDeleteUser = props => {
  const {
    title,
    ids,
    handleCloseModal,
    openModal,
    users
  } = props

  const handleEditUser = async () => {
    if (!ids.length)
      showError('Admin.USERS.NO_USER_IDENTIFIED')
    else {
      const isGetUsers = await api({
        method: 'DELETE',
        url: `users?ids=${ids.join(',')}`
      }).then(async ({ data }) => {
        showResponseSuccess(data?.mesage)
        return true
      }).catch(error => {
        showResponseError(error)
        return false
      })

      await handleCloseModal(isGetUsers)
    }
  }

  return (
    <WCustomModal
      buttonLabel={'Delete'}
      title={title}
      open={openModal}
      handleClose={handleCloseModal}
      buttonOnConfirm={handleEditUser}
      icon={'delete'}
      element={
        <div className='flex w-80 h-80 justify-center items-center text-white'>
          <p className='border-b border-orange w-60 text-center'>{users?.map((i, index) => (
            <p key={index} className='pb-2'>{i[1].label}</p>
          ))}</p>
        </div>
      }
    />
  )
}

export default ModalDeleteUser