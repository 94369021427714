import axios from 'axios'
import Icon from '@mui/material/Icon'
import React, { useEffect, useContext, useState } from 'react'
import { Context } from './authContext'
import { useNavigate } from 'react-router-dom'

let api = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
})

const ApiProvider = ({ children }) => {
  const navigate = useNavigate()
  const [maintenance, setMaintenance] = useState(false)
  const [maintenanceMessage, setMaintenanceMessage] = useState('')

  const { handleRefreshToken, accessToken } = useContext(Context)
  useEffect(() => {
    const resInterceptor = response => {
      return response
    }

    const errInterceptor = async error => {
      if (!error?.response?.data)
        return

      const { data, status } = error?.response
      const { _retry } = error?.config
      if (!_retry) {
        const originalRequest = error.config
        originalRequest._retry = true

        if (status === 401) {
          const message = data?.message ?? data?.details?.message
          if (message === 'Unauthorized') {
            await handleRefreshToken()
            return Promise.resolve(api(originalRequest))
          } else if (message === 'TOKEN_NOT_FOUND' || message === 'NEED_TO_RELOG') {
            navigate('/login')
          }
        } else if (status === 503 && data?.details?.Status === 'MAINTENANCE') {
          setMaintenance(true)
          setMaintenanceMessage(data.details?.Message)
          return Promise.reject()
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    }

    api.interceptors.response.use(resInterceptor, errInterceptor)

    return () => api.interceptors.response.eject(errInterceptor)
  })

  useEffect(() => {
    api.defaults.headers['Authorization'] = `Bearer ${accessToken}`
  }, [accessToken])

  useEffect(() => {
    if (maintenance)
      document.activeElement.blur()
  }, [maintenance])

  return (
    <>
      {children}
      {maintenance &&
        <div id='modal-maintenance'>
          <div className='modal-maintenance-overlay'></div>
          <div id='modal-maintenance'>
            <div className='modal-maintenance-container'>
              <div className='modal-maintenance-title'>
                <Icon style={{ marginRight: '10px' }}>warning</Icon>
                <p>Maintenance Application</p>
              </div>
              <div className='modal-maintenance-body'>
                <Icon icon='info' style={{ color: '#071fff', marginRight: '10px' }}/>
                <span>{maintenanceMessage}</span>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default api
export {
  ApiProvider
}