import React, { useEffect, useRef, useState } from 'react'
import WTable from '../../../components/WTable/v2'
import useApi from '../../../context/hooks/useApi'
import AddProject from './modal/addProject'
import EditProject from './modal/editProject'
import { IForwardRefV2 } from '../../../components/WTable/v2/table'
import DelProject from './modal/delProject'
import { IProject } from './modal/FormData'
import I18n from '../../../components/I18n'

interface IDataProject {
  id: string
  name: string
  projectId: string
  projectNumber: string
  isProduction: boolean
}

export interface IProjects {
  data: IDataProject[]
  count: number
}

interface IRows {
  isCollapse: boolean
  isSelectable: boolean
  cells: { key: string, value: string | JSX.Element }[]
}

const ProjectsPage = () => {
  const [rows, setRows] = useState<IRows[]>([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [filters, setFilters] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [typeModal, setTypeModal] = useState('')

  const { data: dataProjects, isLoading: isloadingProjects, mutate: mutateProjects } = useApi<IProjects>({ url: `project?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })

  const WTableRef = useRef<IForwardRefV2<IProject>>(null)

  const headers = [
    {
      key: 'id',
      value: 'Id',
      align: 'center',
      hidden: true
    },
    {
      key: 'name',
      value: <I18n path='Application.PROJECTS.TABLE.NAME'/>,
      align: 'center',
    },
    {
      key: 'projectId',
      value: <I18n path='Application.PROJECTS.TABLE.PROJECT_ID'/>,
      align: 'center'
    },
    {
      key: 'projectNumber',
      value: <I18n path='Application.PROJECTS.TABLE.PROJECT_NUMBER'/>,
      align: 'center'
    },
    {
      key: 'isProduction',
      value: <I18n path='Application.PROJECTS.TABLE.IS_PRODUCTION'/>,
    }
  ]

  useEffect(() => {
    if (dataProjects) {

      const newRows = dataProjects.data?.map(i => ({
        isCollapse: false,
        isSelectable: true,
        cells: [
          {
            key: 'id',
            value: i.id,
          },
          {
            key: 'name',
            value: i.name,
          },
          {
            key: 'projectId',
            value: i.projectId
          },
          {
            key: 'projectNumber',
            value: i.projectNumber
          },
          {
            key: 'isProduction',
            value: i.isProduction.toString()
          },
        ]
      }))

      setRows(newRows)
      setCount(dataProjects.count)
    }
  }, [dataProjects])

  const onOpenModal = (type: string) => {
    setTypeModal(type)
    setOpenModal(true)
  }

  const onCloseModal = () => {
    WTableRef.current?.clearCheckedValues()
    setOpenModal(false)
  }

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder: string, newOrderBy: string) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = (filters: string) => {
    setFilters(filters)
  }

  const permissionsTableHeader = {
    add: 'application.project.post',
    edit: 'application.project.put',
    remove: 'application.project.delete',
  }

  return (
    <div className='flex w-[99%] max-h-full'>
      {
        openModal &&
        <>
          {
            typeModal === 'add' &&
            <AddProject
              onCloseModal={onCloseModal}
              mutate={mutateProjects}
            />
          }
          {
            typeModal === 'edit' &&
            <EditProject
              mutate={mutateProjects}
              onCloseModal={onCloseModal}
              id={WTableRef.current?.getIdCheckedValues()[0] ?? ''}
            />
          }
          {
            typeModal === 'delete' &&
            <DelProject
              onCloseModal={onCloseModal}
              projects={WTableRef.current?.getCheckedRows() ?? []}
              mutate={mutateProjects}
            />
          }
        </>
      }
      <WTable
        ref={WTableRef}
        isLoadingData={isloadingProjects}
        headers={headers}
        rows={rows}
        isCollapse={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        countRows={count}
        limit={10}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        onChangeFilters={handleChangeFilters}
        tableHeader
        permissionsTableHeader={permissionsTableHeader}
        onAddNewRow={() => onOpenModal('add')}
        onEditRow={() => onOpenModal('edit')}
        onDeleteRow={() => onOpenModal('delete')}
      />
    </div>
  )
}


export default ProjectsPage