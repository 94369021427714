import React, { useContext } from 'react'
import { Button } from '@clinicorp/designsystem.components.basic.buttons.button'
import { Context } from '../../context/authContext'
import I18n from '../I18n'
import WSidebar from '../WSidebar/v2/'
import Location from '../../routes/Location'
import { Avatar } from '@mui/material'

const Header = () => {
  const { user, handleLogout } = useContext(Context)
  const isHomePage = Location().pathname === '/home'

  return (
    <div
      style={{boxShadow: '0 8px 6px -6px black'}}
      className='fixed top-0 flex w-full justify-between items-center h-[50px] bg-[var(--GreyBlack)] z-[80] px-[20px] text-orange'
    >
      {isHomePage ? '' : <WSidebar />}
      <div></div>
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <div className='d-flex'>
          <p
            style={{
              fontSize: '16px',
              color: '#E5E5E5',
              textDecoration: 'underline'
            }}
          >
            {
              isHomePage ?
                <>
                  <I18n path='Header.HELLO' />, { user?.displayName }
                </>
                : null
            }
          </p>
        </div>
        <Avatar src={user?.photoURL} fontSize='small' className='w-8 h-8 mr-5'/>
        <Button
          type='icon'
          iconChildren='logout'
          onClick={() => handleLogout()}
          style={{color: 'white'}}
          tooltipLabel={<I18n path='LOGOUT'/>}
        />
      </div>
    </div>
    
  )
}

export default Header