import React, { useState, memo } from 'react'
import { TableCell, TableCheckbox, TableRow } from '@react-md/table'

const Row = (props) => {
  const {
    row,
    getProps,
    colSpanOnHoverRow,
    checkboxRows,
    rowIndex
  } = props

  const checkboxProps = getProps(row)
  const { checked, onChange } = checkboxProps
  const [onHoverRow, setOHoverRow] = useState(false)
  const lengthCells = row?.cells?.filter(i => !i.hidden && !i.onHoverRow)?.length

  return (
    <TableRow
      onMouseEnter={() => setOHoverRow(true)}
      onMouseLeave={() => setOHoverRow(false)}
      disableBorders
      className={`workbench-table-body-row-${rowIndex % 2 === 0 ? '1' : '2' }`}
      clickable={checkboxRows}
      selected={checked}
      onClick={onChange}
    >
      {
        row ?
          <>
            {
              checkboxRows &&
            <TableCell
              hAlign='center'
              className='workbench-table-body-cell'
            >
              <TableCheckbox
                id={`${rowIndex}-checkbox`}
                {...checkboxProps}
              />
            </TableCell>
            }
            {
              row?.cells?.filter(i => !i.hidden && !i.onHoverRow)
                .slice(0, (onHoverRow  ? lengthCells - colSpanOnHoverRow : lengthCells))
                .map((cell, cellIndex) => (
                  <TableCell
                    key={`${cell.label}_${cellIndex}`}
                    hAlign={cell.align}
                    style={cell.style}
                    lineWrap={cell.lineWrap}
                    className='workbench-table-body-cell'
                  >{cell.label}
                  </TableCell>
                ))
            }
          </>
          : <TableCell colSpan='100%'/>
      }
      {
        onHoverRow &&
      row?.cells?.filter(i => i.onHoverRow).map((cell, cellIndex) => (
        <TableCell
          key={`${cell.label}_${cellIndex}`}
          className='workbench-table-body-cell'
          onMouseLeave={() => setOHoverRow(false)}
          lineWrap={true}
          style={{
            filter: 'blur(0.3px)'
          }}
          colSpan='100%'>
          {cell.label}
        </TableCell>
      ))
      }
    </TableRow>
  )
}

export default memo(Row)