import React, { useEffect, useRef, useState } from 'react'
import WTable from '../../../components/WTable/v2'
import useApi from '../../../context/hooks/useApi'
import { IForwardRefV2 } from '../../../components/WTable/v2/table'
import I18n from '../../../components/I18n'
import AddPermission from './modal/addPermission'
import EditPermission from './modal/editPermisison'
import DelPermission from './modal/delPermission'
import { IPermission } from './modal/FormData'

interface IDataPermission {
  id: string
  permission: string
}

export interface IPermissions {
  data: IDataPermission[]
  count: number
}

interface IRows {
  isCollapse: boolean
  isSelectable: boolean
  cells: { key: string, value: string | JSX.Element }[]
}

const AssignedPermissionsPage = () => {
  const [rows, setRows] = useState<IRows[]>([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('permission')
  const [filters, setFilters] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [typeModal, setTypeModal] = useState('')

  const { data: dataAssignedPermissions, isLoading: isloadingAssignedPermissions, mutate: mutateAssignedPermissions } = useApi<IPermissions>({ url: `roles/permission?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })

  const WTableRef = useRef<IForwardRefV2<IPermission>>(null)

  const headers = [
    {
      key: 'id',
      value: 'Id',
      align: 'center',
      hidden: true
    },
    {
      key: 'permission',
      value: <I18n path='Application.ASSIGNED_PERMISSIONS.TABLE.PERMISSION'/>,
      align: 'center',
    },
  ]

  useEffect(() => {
    if (dataAssignedPermissions) {

      const newRows = dataAssignedPermissions.data?.map(i => ({
        isCollapse: false,
        isSelectable: true,
        cells: [
          {
            key: 'id',
            value: i.id,
          },
          {
            key: 'permission',
            value: i.permission,
          },
        ]
      }))

      setRows(newRows)
      setCount(dataAssignedPermissions.count)
    }
  }, [dataAssignedPermissions])

  const onOpenModal = (type: string) => {
    setTypeModal(type)
    setOpenModal(true)
  }

  const onCloseModal = () => {
    WTableRef.current?.clearCheckedValues()
    setOpenModal(false)
  }

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder: string, newOrderBy: string) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = (filters: string) => {
    setFilters(filters)
  }

  const permissionsTableHeader = {
    add: 'application.assigned_permissions.get',
    edit: 'application.assigned_permissions.put',
    remove: 'application.assigned_permissions.delete',
  }

  return (
    <div className='flex w-[99%] max-h-full'>
      {
        openModal &&
        <>
          {
            typeModal === 'add' &&
            <AddPermission
              onCloseModal={onCloseModal}
              mutate={mutateAssignedPermissions}
            />
          }
          {
            typeModal === 'edit' &&
            <EditPermission
              mutate={mutateAssignedPermissions}
              onCloseModal={onCloseModal}
              id={WTableRef.current?.getIdCheckedValues()[0] ?? ''}
            />
          }
          {
            typeModal === 'delete' &&
            <DelPermission
              onCloseModal={onCloseModal}
              permissions={WTableRef.current?.getCheckedRows() ?? []}
              mutate={mutateAssignedPermissions}
            />
          }
        </>
      }
      <WTable
        ref={WTableRef}
        isLoadingData={isloadingAssignedPermissions}
        headers={headers}
        rows={rows}
        isCollapse={false}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        countRows={count}
        limit={10}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        onChangeFilters={handleChangeFilters}
        tableHeader
        permissionsTableHeader={permissionsTableHeader}
        onAddNewRow={() => onOpenModal('add')}
        onEditRow={() => onOpenModal('edit')}
        onDeleteRow={() => onOpenModal('delete')}
      />
    </div>
  )
}


export default AssignedPermissionsPage