import React, { useState, useEffect, useContext } from 'react'
import useApi from '../../../../context/hooks/useApi'
import WSelectFieldV2 from '../../../../components/WSelectField/V2'
import I18n from '../../../../components/I18n'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import { Context } from '../../../../context/authContext'
import WTextFieldV2 from '../../../../components/WTextField/V2'
import WButton from '../../../../components/WButton'

const optionsStatus = [
  { label: <I18n path='Application.STATUS.RUNNING'/>, value: 'RUNNING'},
  { label: <I18n path='Application.STATUS.MAINTENANCE'/>, value: 'MAINTENANCE'},
]

const Status = () => {
  const { permissions } = useContext(Context)
  
  const [status, setStatus] = useState()
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState()
  const { data, isLoading } = useApi({ url: 'application/status',  retryOnFail: false, countRetry: 0 , displayError: true })

  useEffect(() => {
    if (data) {
      setStatus(optionsStatus.find(i => i.value === data.Status))
      setMessage(data.Message)
    }
  }, [data])

  const handleUpdateStatus = () => {
    setLoading(true)
    api({
      method: 'PATCH',
      url: 'application/status',
      data: {
        status: status.value,
        message: message
      }
    }).then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => {
        showResponseError(error)
      })
      .finally(() => setLoading(false))
  }

  const disabledSaveButton = !permissions?.includes('application.configuration.status.put')

  return (
    <div className='flex flex-col w-96 h-96 justify-between gap-y-10 p-[20px] rounded-lg backdrop-blur-lg'>
      <WSelectFieldV2
        label='Status'
        options={optionsStatus}
        isLoading={isLoading}
        value={status}
        onChange={ev => setStatus(ev)}
      />
      <WTextFieldV2
        label={<I18n path='Application.MESSAGE'/>}
        value={message}
        required
        disabled={status?.value === 'RUNNING'}
        onChange={ev => setMessage(ev.target.value)}
        variant='standard'
      />
      <WButton
        onClick={handleUpdateStatus}
        label={<I18n path='Application.SAVE'/>}
        disabled={disabledSaveButton || isLoading || loading}
        loading={isLoading}
        loadingPosition='start'
      />
    </div>
  )
}

export default Status