import React, { useContext } from 'react'
import Header from '../WHeader'
import { Outlet } from 'react-router-dom'
import Location from '../../routes/Location'
import { Context } from '../../context/authContext'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'


const WLayout = () => {
  const isHomePage = Location().pathname === '/home'
  const { loadingProject} = useContext(Context)

  return (
    <>
      {
        loadingProject && <Loading />
      }
      <header>
        <Header />
      </header>
      {isHomePage ?
        <div className='h-full w-full py-[80px] overflow-auto'>
          <Outlet />
        </div>
        :
        <div className='h-full ml-52 xl:ml-72 pb-1 pt-16'>
          <Outlet />
        </div>
      }
      
      {/* <footer>
        <Footer />
      </footer> */}
    </>
  )
}

export default WLayout