import useSWR, { Fetcher, SWRConfiguration } from 'swr'
import { showResponseError } from '../../helper'
import api from '../apiContext'
import { useContext } from 'react'
import { Context } from '../authContext'
import { AxiosError } from 'axios'

interface IOnError {
  status: number | undefined
  data: unknown
}

interface IOptions {
  url: string
  retryOnFail?: boolean
  countRetry?: number
  displayError?: boolean
  executeOnError?: (props: IOnError) => void
}

const useApi = <T>(options: IOptions) => {
  const { currentProject } = useContext(Context)
  const {
    url,
    retryOnFail,
    countRetry,
    displayError,
    executeOnError,
    ...moreOptions
  } = options

  const fetcher: Fetcher<T, typeof url> = async () => {
    const response = await api.get(url)

    return response.data
  }

  const config: SWRConfiguration = {
    onError: (error: AxiosError) => {
      if (displayError)
        showResponseError(error)

      if (typeof executeOnError === 'function') {
        executeOnError({
          status: error.response?.status,
          data: error.response?.data
        })
      }
    },
    onErrorRetry: (error: AxiosError, key, config, revalidate, { retryCount }) => {
      if (error.response?.status === 404 || !retryOnFail) return

      if (retryCount >= (countRetry ?? 2)) return

      setTimeout(() => revalidate({ retryCount }), 5000)
    },
    ...moreOptions
  }

  return useSWR<T>(url.length ? `${url}-${currentProject}` : undefined, fetcher, config)
}

export default useApi