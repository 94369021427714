import React from 'react'
import WModal from '../../../../components/WModal/V2'
import FormCardClinicorp from './FormCardClinicorp'

const ControlCards = props => {
  const {
    handleSuccessBuild,
    openModal,
    onCloseModal
  } = props

  return(
    <WModal
      openModal={openModal}
      onCloseModal={onCloseModal}
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <FormCardClinicorp
        onCloseModal={onCloseModal}
        handleSuccessBuild={handleSuccessBuild}
      />
    </WModal>
  )

}

export default ControlCards