import React, { useState } from 'react'
import WSelectField from '../../../../components/WSelectField/V2'
import useApi from '../../../../context/hooks/useApi'

import {
  defaultTheme,
  customStyles,
  Control,
  LoadingMessage,
  NoOptionsMessage,
} from '../../../../components/ReactSelect/components'

const WSelectBranch = (props) => {
  const {
    theme,
    id,
    components,
    className,
    repository,
    onChange,
    ...selectSearchProps
  } = props

  const customTheme = {
    ...defaultTheme,
    ...theme
  }

  // const [branchName, setBranchName] = useState()
  
  
  const [value, setValue] = useState('')
  const {data: dataBranch, isLoading} = useApi({url: `git?repository=${repository}`})
  // const {data: dataBranch, isLoading} = useApi({url: `git?repository=${repository}${branchName ? `&branchName=${branchName}`: ''}`})

  const arrayBranch = dataBranch?.map(i => (
    {
      label: i.name,
      commit: i.commit,
      message: i.message,
      value: i.commit
    }
  ))

  const customClassName = `wselect ${className}`

  const handleChangeBranch = (valor) => {
    if(typeof onChange === 'function'){
      onChange({repository: repository, branch: valor.label})
      setValue(valor)
    }
  }

  return (
    <WSelectField
      id={`wselect-${id}`}
      className={customClassName}
      value={value}
      isLoading={isLoading}
      // onInputChange={(e) => setBranchName(e)}
      theme={{
        ...customTheme
      }}
      styles={{
        ...customStyles,
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999, backgroundColor: '#111111' }),
      }}
      options={arrayBranch}
      onChange={handleChangeBranch}
      components={{
        Control,
        NoOptionsMessage,
        LoadingMessage,
        ...components
      }}
      {...selectSearchProps}
    />
  )
}


export default WSelectBranch