import React, { useEffect, useState } from 'react'
import WTextFieldV2 from '../../../components/WTextField/V2'
import WHorizontalLinearStepper from '../../../components/WHorinzontalLinearStepper'
import WCheckbox from '../../../components/WCheckbox'
import I18n from '../../../components/I18n'
import WChip from '../../../components/WChip'
import WCreatableSelect from '../../../components/WCreatableSelect'
import useApi from '../../../context/hooks/useApi'
import WSelectFieldV2 from '../../../components/WSelectField/V2'


const FormEditGroup = (props) => {

  const {
    className,
    onSave,
    groups,
    id,
    pageSelected
  } = props

  const [componentName, setComponentName] = useState('')
  const [showCase, setShowCase] = useState(false)
  const [selectGroup, setSelectGroup] = useState(null)
  const [slo, setSlo] = useState()
  const [endpoint, setEndpoint] = useState('')
  const [endpointValue, setEndpointValue] = useState([])
  const [status, setStatus] = useState('')

  const [errorEndpoint, setErrorEndpoint] = useState(false)
  const [errorSlo, setErrorSlo] = useState(false)
  const [errorComponent, setErrorComponent] = useState(false)

  const {data: listEditComponents} = useApi({url: `${ id ? `status-page/pages/${pageSelected}/components/${id}` : ''}`})

  const handleSave = () => {

    const editComponent = {
      status: status?.value,
      name: componentName,
      groupIdOrName: selectGroup?.label,
      showcase: showCase,
      slo: slo,
      endpoint: endpointValue,
      id: id
    }

    onSave(editComponent)
  }

  useEffect(() => {
    if(listEditComponents)
      setComponentName(listEditComponents?.name)
    setStatus(optionsStatus?.find(id => id.value === listEditComponents?.status)),
    setShowCase(listEditComponents?.showcase),
    setSlo(listEditComponents?.slo),
    setEndpointValue(listEditComponents?.endpoint),
    setSelectGroup(groups.find(id => id.label === listEditComponents?.group.name))
        

  }, [listEditComponents])

  const handleDelete = (item) => {
    const newSelecteds = [...endpointValue].filter((endpoint) => endpoint !== item)
    setEndpointValue(newSelecteds)
  }

  const handleEndpoint = () => {
    const existsItem = endpointValue.some((value) => value === endpoint)

    setErrorEndpoint(existsItem)
    if(existsItem || !endpoint.length){
      return
    }

    setEndpointValue((value) => value.concat(...[endpoint]))
    setEndpoint('')
  }

  const handleSelectGroup = (ev) => {
    setSelectGroup(ev)
  }

  const validarInfos = () => {

    let validadeCells = false

    const valueSlo = parseFloat(slo)
    const errorSloValue = isNaN(valueSlo)
    setErrorSlo(errorSloValue)
    if(!isNaN(valueSlo)){
      setSlo(valueSlo)
    }

    const errorComponentName = !componentName.length
    const errorEndpointValue = !endpointValue.length
 
    setErrorComponent(errorComponentName)
    setErrorEndpoint(errorEndpointValue)

    if(errorSloValue || errorComponentName || errorEndpointValue){
      return validadeCells
    }

    validadeCells = true

    return validadeCells
  }

  const optionsStatus = [
    {
      id: 'operational',
      label: 'Operational',
      value: 'operational',
    },
    {
      id: 'under_maintenance',
      label: 'Under Maintenance',
      value: 'under_maintenance',
    }
  ]

  return(
    <div className={`flex flex-col w-full h-full ${className}`}>
      
      <WHorizontalLinearStepper
        childrens={[
          {
            stepName: '',
            element:
            <div className='1st step'>
              <div className='bg-[#111111] rounded-none'>
                <>
                  <WTextFieldV2
                    label={<I18n path='AppCare.STATUS_PAGE.FORMULARIO.NAME_COMPONENT'/>}
                    id={'input-name-component'}
                    margin={'dense'}
                    value={componentName}
                    onChange={ev => setComponentName(ev.target.value)}
                    error={errorComponent}
                    helperText={'Empty Cell'}
                  />
                  <div className='mt-5'>
                    <WCheckbox
                      label={<I18n path='AppCare.STATUS_PAGE.SHOWCASE'/>}
                      checked={showCase}
                      onChange={() => setShowCase(!showCase)}
                    />
                  </div>
                  <WSelectFieldV2
                    label={<I18n path='AppCare.STATUS_PAGE.TABLE.STATUS'/>}
                    id={'input-status'}
                    value={status}
                    options={optionsStatus}
                    onChange={(ev) => setStatus(ev)}
                    className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                    variant='standard'
                  />
                  <WCreatableSelect
                    label={<I18n path='AppCare.STATUS_PAGE.TABLE.GROUP'/>}
                    id={'input-group'}
                    value={selectGroup}
                    options={groups}
                    onChange={ev => handleSelectGroup(ev)}
                    className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
                    variant='standard'
                    clearable={true}
                  />
                  <WTextFieldV2
                    label={<I18n path='AppCare.STATUS_PAGE.TABLE.SLO'/>}
                    id={'input-slo'}
                    margin={'dense'}
                    value={slo}
                    onChange={ev => setSlo(ev.target.value)}
                    sufix={'%'}
                    error={errorSlo}
                    helperText={'Is not a number'}
                  />
                  <WTextFieldV2
                    label={<I18n path='AppCare.STATUS_PAGE.TABLE.ENDPOINT'/>}
                    id={'input-endpoint'}
                    margin={'dense'}
                    value={endpoint}
                    onChange={ev => setEndpoint(ev.target.value)}
                    onKeyEnter={handleEndpoint}
                    error={errorEndpoint}
                    helperText={'Duplicate or Empty Value'}
                  />
                  <div className='flex flex-wrap w-full m-1 p-0'>
                    {
                      endpointValue?.map((i, index) => (
                        <WChip
                          key={index}
                          label={i}
                          className='text-white bg-orange m-1'
                          onDelete={() => handleDelete(i)}
                        />
                      ))
                    }
                  </div>
                </>

              </div>
            </div>,
            emptyCell: false,
            validar: () => validarInfos()
          },
        ]}
        finishStep={
          <div>
            <p>
              Component name: {componentName}
            </p>
            <p>
              Status: {status?.label}
            </p>
            <p>
              Showcase: {showCase ? 'true' : 'false'}
            </p>
            <p>
              Group name: {selectGroup?.label}
            </p>
            <p>
              SLO: {slo}
            </p>
            <div className='flex flex-row'>
              <span>Endpoint: </span>
              <ul>
                {endpointValue?.map(i => (
                  <>
                    <li className='ml-1'> {i}</li>
                  </>
                ))
                }
              </ul>
              
            </div>
          </div>
        }
        type='Update'
        iconButton='edit'
        onSaveClick={() => handleSave()}
      />
    </div>
  )
}

export default FormEditGroup