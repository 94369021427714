import React, { useState } from 'react'
import { IRepository } from './FormData'
import api from '../../../../context/apiContext'
import { KeyedMutator } from 'swr'
import { IRepositories } from '..'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'

interface IDelRepository {
  onCloseModal: () => void
  repositories: IRepository[]
  mutate: KeyedMutator<IRepositories>
}

interface IDelRepositoryError {
  id: string
  name: string
  cloudFunctions: { name: string }[]
  cloudRuns: { name: string }[]
}

const DelRepository = (props: IDelRepository) => {
  const {
    onCloseModal,
    repositories,
    mutate
  } = props

  const [isLoading, setIsloading] = useState(false)
  const [listErrors, setListErros] = useState<IDelRepositoryError[]>([])

  const handleSave = async () => {
    setIsloading(true)
    await api({
      method: 'DELETE',
      url: 'repositories',
      params: {
        ids: repositories.map(i => i.id).join(',')
      }
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => {
        if (error.response.status === 428) {
          const { data } = error.response
          setListErros(data.details)
        }

        showResponseError(error)
      })
      .finally(() => setIsloading(false))
  }
  
  return (
    <WCustomModal
      title={<I18n path='Application.REPOSITORIES.MODAL.DELETE.TITLE' variables={{ count: repositories.length }}/>}
      open={true}
      handleClose={onCloseModal}
      loading={isLoading}
      buttonLabel={<I18n path='Application.REPOSITORIES.MODAL.DELETE.BTN_SAVE'/>}
      buttonOnConfirm={handleSave}
      icon={'delete'}
      element={
        <div className='flex flex-col w-80 h-56 gap-4 justify-center items-center p-2 text-white'>
          {
            !listErrors.length &&
          repositories?.map((i, index) => (
            <p key={index} className='border-b border-orange'>{i.name}</p>
          ))
          }

          <ul>
            {
              listErrors.map(i => (
                <li key={i.id}>
                  <div className='flex space-x-2'>
                    {
                      !!i.cloudFunctions.length &&
                    <I18n
                      path='Application.REPOSITORIES.MODAL.DELETE.RESPONSE_REPOSITORIES_IN_USE_CF'
                      variables={{ repositoryName: i.name, cfName: i.cloudFunctions?.map(cf => cf.name).join(', ') }}
                    />
                    }
                    {
                      !!i.cloudRuns.length &&
                    <I18n
                      path='Application.REPOSITORIES.MODAL.DELETE.RESPONSE_REPOSITORIES_IN_USE_CR'
                      variables={{ repositoryName: i.name, crName: i.cloudRuns?.map(cf => cf.name).join(', ') }}
                    />
                    }
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      }
    />
  )
}

export default DelRepository