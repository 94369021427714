export const moduleName = 'workbench'
export const moduleDescription = 'this module is for internal devs use!'
export const routes = {
  home: {
    name: 'home',
    icon: 'home',
    domain: [],
    path: '/home',
    getRoute: () => '/home',
    pathComponent: 'src/pages/home/',
    header: true,
    footer: true,
    sideBar: true,
    permissions: ['home.get'],
    subPaths: {
      build: {
        name: 'build',
        icon: 'cloud_sync',
        domain: [],
        path: '/build',
        getRoute: () => '/build',
        pathComponent: 'src/pages/build/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['build.get'],
        title: 'Home.MENU_CARD.BUILD.TITLE',
        description: 'Home.MENU_CARD.BUILD.SUB_TITLE'
      },
      services: {
        name: 'services',
        icon: 'miscellaneous_services',
        domain: [],
        path: '/services',
        getRoute: () => '/services',
        pathComponent: 'src/pages/services/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['services.get'],
        title: 'Home.MENU_CARD.SERVICES.TITLE',
        description: 'Home.MENU_CARD.SERVICES.SUB_TITLE',
        subPaths: {
          metadata: {
            name: 'metadata',
            icon: 'article',
            domain: [],
            path: '/services/metadata',
            getRoute: () => '/services/metadata',
            pathComponent: 'src/pages/services/metadata',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['services.metadata.get'],
            title: 'Metadata',
          },
          cloudRun: {
            name: 'cloudRun',
            icon: 'double_arrow',
            domain: [],
            path: '/services/cloud-run',
            getRoute: () => '/services/cloud-run',
            pathComponent: 'src/pages/services/cloudRun',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['services.cloud_run.list'],
            title: 'Cloud run',
            subPaths: {
              generateCode: {
                name: 'generateCode',
                icon: 'article',
                domain: [],
                path: '/services/cloud-run/generate-code',
                getRoute: () => '/services/cloud-run/generate-code',
                pathComponent: 'src/pages/services/cloudRun/Generate-code',
                header: true,
                footer: true,
                sideBar: true,
                permissions: ['services.cloud_run.generate_code.get'],
                title: 'generate-code',
              },
            }
          },
        },
      },
      appCare: {
        name: 'appCare',
        icon: 'monitor_heart',
        domain: [],
        path: '/app-care',
        getRoute: () => '/app-care',
        pathComponent: 'src/pages/app-care/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['app-care.get'],
        title: 'Home.MENU_CARD.APP_CARE.TITLE',
        description: 'Home.MENU_CARD.APP_CARE.SUB_TITLE',
        subPaths: {
          users: {
            name: 'checkRoutes',
            icon: 'router',
            domain: [],
            path: '/app-care/check-routes',
            getRoute: () => '/app-care/check-routes',
            pathComponent: 'src/pages/app-care/check-routes',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['app-care.list-urls'],
            title: 'AppCare.CHECK_ROUTES.NAME',
          },
          statusPage: {
            name: 'statusPage',
            icon: 'web',
            domain: [],
            path: '/app-care/status-page',
            getRoute: () => '/app-care/status-page',
            pathComponent: 'src/pages/app-care/status-page',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['app-care.status-page'],
            title: 'AppCare.STATUS_PAGE.NAME',
          },
        }
      },
      admin: {
        name: 'admin',
        icon: 'admin_panel_settings',
        domain: [],
        path: '/admin',
        getRoute: () => '/admin',
        pathComponent: 'src/pages/dmin/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['admin.get'],
        title: 'Home.MENU_CARD.ADMIN.TITLE',
        description: 'Home.MENU_CARD.ADMIN.SUB_TITLE',
        subPaths: {
          iam: {
            name: 'iam',
            icon: 'admin_panel_settings',
            domain: [],
            path: '/admin/iam',
            getRoute: () => '/admin/iam',
            pathComponent: 'src/pages/admin/iam',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['iam.list'],
            title: 'Admin.IAM.TITLE',
            description: 'Admin.MENU_CARD.IAM.SUB_TITLE',
            subPaths: {
              users: {
                name: 'users',
                icon: 'person',
                domain: [],
                path: '/admin/iam/users',
                getRoute: () => '/admin/iam/users',
                pathComponent: 'src/pages/admin/iam/users',
                header: true,
                footer: true,
                sideBar: true,
                permissions: ['iam.users.list'],
                title: 'Admin.IAM.USERS',
              },
              roles: {
                name: 'roles',
                icon: 'groups',
                domain: [],
                path: '/admin/iam/roles',
                getRoute: () => '/admin/iam/roles',
                pathComponent: 'src/pages/admin/iam/roles',
                header: true,
                footer: true,
                sideBar: true,
                permissions: ['iam.roles.list'],
                title: 'Admin.IAM.ROLES',
              }
            }
          },
          environments: {
            name: 'environments',
            icon: 'cloud_upload',
            domain: [],
            path: '/admin/environments',
            getRoute: () => '/admin/environments',
            pathComponent: 'src/pages/admin/environments',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['environments.list'],
            title: 'Admin.MENU_CARD.ENVIRONMENTS.TITLE',
            description: 'Admin.MENU_CARD.ENVIRONMENTS.SUB_TITLE'
          },
          appEngine: {
            name: 'appEngine',
            icon: 'lens_blur',
            domain: [],
            path: '/admin/app-engine',
            getRoute: () => '/admin/app-engine',
            pathComponent: 'src/pages/admin/app-engine',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['admin.app_engine.list'],
            title: 'Admin.MENU_CARD.APP_ENGINE.TITLE',
            description: 'Admin.MENU_CARD.APP_ENGINE.SUB_TITLE'
          },
          microservices: {
            name: 'microservices',
            icon: 'settings',
            domain: [],
            path: '/admin/cloud-functions',
            getRoute: () => '/admin/microservices',
            pathComponent: 'src/pages/admin/microservices',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['microservices.list'],
            title: 'Admin.MICROSERVICES.TITLE',
            subPaths: {
              cloudFunctions: {
                name: 'cloud-functions',
                icon: 'settings_ethernet',
                domain: [],
                path: '/admin/microservices/cloud-functions',
                getRoute: () => '/admin/microservices/cloud-functions',
                pathComponent: 'src/pages/admin/cloud-functions',
                header: true,
                footer: true,
                sideBar: true,
                permissions: ['microservices.cloud_functions.list'],
                title: 'Admin.MICROSERVICES.CLOUD_FUNCTIONS',
              },
              cloudRun: {
                name: 'cloud-run',
                icon: 'double_arrow',
                domain: [],
                path: '/admin/microservices/cloud-run',
                getRoute: () => '/admin/microservices/cloud-run',
                pathComponent: 'src/pages/admin/cloud-run',
                header: true,
                footer: true,
                sideBar: true,
                permissions: ['microservices.cloud_run.list'],
                title: 'Admin.MICROSERVICES.CLOUD_RUN',
              }
            }
          },
          secrets: {
            name: 'secrets',
            icon: 'lock',
            domain: [],
            path: '/admin/secrets',
            getRoute: () => '/admin/secrets',
            pathComponent: 'src/pages/admin/secrets',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['secrets.list'],
            title: 'Admin.MENU_CARD.SECRETS.TITLE',
            description: 'Admin.MENU_CARD.SECRETS.SUB_TITLE'
          },
        }
      },
      application: {
        name: 'application',
        icon: 'settings',
        domain: [],
        path: '/application',
        getRoute: () => '/application',
        pathComponent: 'src/pages/application/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['application.get'],
        title: 'Home.MENU_CARD.APPLICATION.TITLE',
        description: 'Home.MENU_CARD.APPLICATION.SUB_TITLE',
        subPaths: {
          configurations: {
            name: 'configurations',
            icon: 'settings',
            domain: [],
            path: '/application/configurations',
            getRoute: () => '/application/configurations',
            pathComponent: 'src/pages/application/configurations',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['application.configuration.list'],
            title: 'Application.CONFIGURATIONS',
          },
          variables: {
            name: 'variables',
            icon: 'lock',
            domain: [],
            path: '/application/variables',
            getRoute: () => '/application/variables',
            pathComponent: 'src/pages/application/variables',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['application.variables.list'],
            title: 'Application.VARIABLES',
          },
          projects: {
            name: 'projects',
            icon: 'space_dashboard',
            domain: [],
            path: '/application/projects',
            getRoute: () => '/application/projects',
            pathComponent: 'src/pages/application/Projects',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['application.project.list'],
            title: 'Application.PROJECTS.NAME'
          },
          assignedPermissions: {
            name: 'assignedPermissions',
            icon: 'security',
            domain: [],
            path: '/application/assigned-permissions',
            getRoute: () => '/application/assigned-permissions',
            pathComponent: 'src/pages/application/AssignedPermissions',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['application.assigned_permissions.list'],
            title: 'Application.ASSIGNED_PERMISSIONS.NAME'
          },
          repositories: {
            name: 'repositories',
            icon: 'code',
            domain: [],
            path: '/application/repositories',
            getRoute: () => '/application/repositories',
            pathComponent: 'src/pages/application/Repositories',
            header: true,
            footer: true,
            sideBar: true,
            permissions: ['application.repositories.list'],
            title: 'Application.REPOSITORIES.NAME'
          },
        },
      },
      approveCenter: {
        name: 'approveCenter',
        icon: 'check_circle',
        domain: [],
        path: '/approve-center',
        getRoute: () => '/approve-center',
        pathComponent: 'src/pages/approve-center/',
        header: true,
        footer: true,
        sideBar: true,
        permissions: ['approve-center.get'],
        title: 'Home.MENU_CARD.APPROVE_CENTER.TITLE',
        description: 'Home.MENU_CARD.APPROVE_CENTER.SUB_TITLE',
      },
    }
  },
  login: {
    name: 'login',
    icon: '',
    domain: [],
    path: '/login',
    getRoute: () => '/login',
    pathComponent: 'src/pages/login/',
    header: false,
    footer: false,
    sideBar: false
  }
}