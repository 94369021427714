import React, { useEffect, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

interface ItemRadio {
  label: string | JSX.Element
  value: string | boolean
  disabled?: boolean
}

interface IWRadioGroup {
  options: ItemRadio[]
  label: string | JSX.Element
  defaultValue: string | boolean
  onChangeRadioGroup: (props: string) => void
}

const WRadioGroup = (props: IWRadioGroup) => {
  const {
    label,
    defaultValue,
    options,
    onChangeRadioGroup,
  } = props

  const [value, setValue] = useState(defaultValue ?? options[0]?.value ?? '')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue(value)

    if (typeof onChangeRadioGroup === 'function')
      onChangeRadioGroup(value)
  }

  return (
    <FormControl>
      {
        label &&
      <FormLabel className='text-white'>
        {label}
      </FormLabel>
      }
      <RadioGroup
        value={value}
        onChange={handleChange}
        
      >
        {
          options.map((i, index) => (
            <FormControlLabel
              key={`${i.label}-${index}`}
              value={i.value}
              control={<Radio
                className='text-orange'
              />}
              label={i.label}
              disabled={i.disabled}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

export default WRadioGroup