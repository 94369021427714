import './style.scss'
import React, { useEffect, useState } from 'react'

import { Button } from '@clinicorp/designsystem.components.basic.buttons.button'
import { TextField } from '@clinicorp/designsystem.components.basic.text-fields.text-field'
import { SelectField } from '@clinicorp/designsystem.components.basic.select-fields.select-field'

const WFiltersModal = props => {
  const [error, setError] = useState(false)
  const [value, setValue] = useState('')

  const {
    positionElement,
    item,
    onAddItem
  } = props

  useEffect(() => {
    item.value = value
  }, [value])

  useEffect(() => {
    if (!value.length)
      setValue(item.list?.find(i => i.selected)?.value ?? '')
  }, [item.list])

  const onClickAddItem = () => {
    if (item.validationValue) {
      if (!item.value?.length) {
        setError(true)
        return
      }
    }

    if (typeof onAddItem === 'function')
      onAddItem(item)
  }

  const onChange = ev => {
    setValue(ev.target.value)
  }

  return (
    <div
      className='modal-filter'
      style={{
        top: `${positionElement.y}px`,
        left: `${positionElement.x + positionElement.width + 5}px`,
        height: `${positionElement.height}px`
      }}
    >
      <div className='modal-item'>
        {
          item.type === 'textfield' &&
          <TextField
            style={item.style}
            value={value}
            onChange={onChange}
            error={error}
          />
        }
        {
          item.type === 'selectfield' &&
          <SelectField
            style={item.style}
            value={value}
            onChange={onChange}
            list={item.list}
          />
        }
        <Button type='icon' iconChildren='check' onClick={onClickAddItem} />
      </div>
    </div>
  )
}

export default WFiltersModal