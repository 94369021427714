import { Checkbox, TableHead } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CONSTANTS } from '../../../js/res/CONSTANTS'
import { StyledTableCell, StyledTableRow } from './commons'
import { WCell } from './WCell'
import WCellSearchable from './WCellSearchable'

const WTableHead = props => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    headers,
    isSelectableAll,
    rowsSelectables,
    isCollapse,
    onChangeSort,
    selected,
    onChangeFilters,
    containsSeletable,
    hiddenHeader
  } = props

  const [filters, setFilters] = useState([])

  const onSearch = search => {
    const newFilters = filters?.filter(i => i.key !== search.key).concat({
      key: search.key,
      operator: search.operator,
      value: search.value,
    }).filter(i => typeof i.value !== 'undefined' && i.value !== null)

    setFilters(newFilters)
  }

  useEffect(() => {
    if (typeof onChangeFilters === 'function')
      onChangeFilters(filters)

  }, [filters])

  return (
    <TableHead className='sticky top-0 z-[1]' style={{boxShadow: '0 8px 6px -6px black',}}>
      <StyledTableRow>
        {
          isSelectableAll ?
            <StyledTableCell padding='checkbox'>
              <Checkbox
                style={{
                  color: CONSTANTS.Style.White
                }}
                indeterminate={selected?.length > 0 && selected?.length < rowsSelectables?.length}
                checked={selected?.length > 0 && selected?.length === rowsSelectables?.length}
                onChange={onSelectAllClick}
              />
            </StyledTableCell>
            :
            containsSeletable ?
              <StyledTableCell/>
              :
              <></>
        }
        {
          isCollapse ?
            <StyledTableCell />
            :
            <></>
        }
        {!hiddenHeader &&
          headers?.filter(i => !i.hidden).map((header, index) => (
            <>
              {
                header.key ?
                  <WCell
                    key={`${header.key}_${index}`}
                    cell={header}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={onChangeSort}
                  />
                  :
                  <StyledTableCell />
              }
            </>
          ))
        }
      </StyledTableRow>
      {
        headers?.some(i => i.searchable) &&
        <StyledTableRow>
          {
            (isSelectableAll || containsSeletable) ?
              <StyledTableCell />
              :
              <></>
          }
          {
            isCollapse &&
              <StyledTableCell />
          }
          {
            headers?.filter(i => !i.hidden).map(header => (
              <>
                {
                  header.searchable ?
                    <WCellSearchable
                      cell={header}
                      onSearch={onSearch}
                      iconSerchable={header.iconSerchable}
                    />
                    :
                    <StyledTableCell />
                }
              </>
            ))
          }
        </StyledTableRow>
      }
    </TableHead>
  )
  
}

export default WTableHead