import React ,{ useState, useEffect } from 'react'
import { signInWithPopup, GoogleAuthProvider, getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import api from '../apiContext'
import authFirebase from '../../config/firebase'
import { showResponseError, showResponseInfo, showResponseAlert, showResponseSuccess, showInfo } from '../../helper'
import jwt_decode from 'jwt-decode'
import I18n from '../../components/I18n'
import { subcriberNotification } from '../../js/notifications'


const auth = getAuth(authFirebase)

const useAuth = () => {
  const navigate = useNavigate()
  const [authenticated, setAuthenticated] = useState(false)
  const [globalLoading, setGlobalLoading] = useState(true)
  const [user, setUser] = useState(auth.currentUser)
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token') || '')
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token') || '')
  const [permissions, setPermissions] = useState(localStorage.getItem('permissions') || '')
  const [currentProject, setCurrentProject] = useState(localStorage.getItem('project_id') || '')
  const [allowedProjects, setAllowedProjects] = useState([])
  const [expAccessToken, setExpAccessToken] = useState()
  const [loadingProject, setLoadingProject] = useState()

  const myStateRef = React.useRef(null)

  const onChangeSubscription = data => {
    myStateRef.current = data
  }

  useEffect(() => {
    if (!user) {
      onAuthStateChanged(auth, user => {
        if (user && window.location.pathname !== '/login') {
          setUser(user)

          login()
        } else {
          setAuthenticated(false)
          setGlobalLoading(false)
        }
      })
    }
  }, [auth.currentUser])

  useEffect(() => {
    if (authenticated) {
      subcriberNotification().then(onChangeSubscription)
    }
  }, [authenticated])

  useEffect(() => {
    navigator.permissions
      .query({ name: 'notifications' })
      .then((permissionStatus) => {
        permissionStatus.onchange = async () => {

          if (permissionStatus.state !== 'granted' && myStateRef.current) {
            await api({
              url: 'notifications/unsubscriber',
              method: 'PUT',
              data: myStateRef.current
            })
          }
        }
      })
  }, [navigator.permissions])

  useEffect(() => {
    localStorage.setItem('access_token', accessToken)
  }, [accessToken])

  useEffect(() => {
    localStorage.setItem('refresh_token', refreshToken)
  }, [refreshToken])

  useEffect(() => {
    localStorage.setItem('permissions', permissions)
  }, [permissions])

  useEffect(() => {
    localStorage.setItem('project_id', currentProject)
  }, [currentProject])

  useEffect(()=> {
    let interval
    if (expAccessToken) {

      const now = new Date().getTime()
      const msOnRefresh = new Date(expAccessToken).setMinutes(new Date(expAccessToken).getMinutes() - 5) - now

      interval =  setInterval(()=> {

        handleRefreshToken()
      }, msOnRefresh)
    }

    return () => clearInterval(interval)

  }, [expAccessToken])

  const getTokenFirebase = async (foreceRefresh) => {
    return await auth.currentUser?.getIdToken(foreceRefresh)
  }

  const handleUpdateToken = async ({ url, data }) => {
    await api({
      method: 'POST',
      url,
      data
    }).then(({ data }) => {
      const payload = jwt_decode(data.access_token)

      api.defaults.headers['Authorization'] = `Bearer ${data.access_token}`

      setExpAccessToken(new Date(payload.exp * 1000).getTime())
      setAccessToken(data.access_token)
      setRefreshToken(data.refresh_token)
      setAllowedProjects(data.allowed_projects)
      setPermissions(payload.permissions)
      setCurrentProject(payload.projectId)
      setAuthenticated(true)

    })
      .finally(() => setGlobalLoading(false))
  }

  const login = async () => {
    const firebaseToken = await getTokenFirebase()
      .catch(() => setAuthenticated(false))

    await handleUpdateToken({
      url: 'auth/login',
      data: {
        firebaseToken: firebaseToken,
        projectId: currentProject,
      },
    })
      .catch(() => setAuthenticated(false))
  }

  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider()
      await signInWithPopup(auth, provider)
        .then(async data => {

          setUser(data.user)
          await login()
        })
        .catch(error => {
          if (error.code !== 'auth/internal-error' && error.message.indexOf('Cloud Function') !== -1) {
            showResponseError('FORBIDEN')
          } else {
            const responseError = error.message?.split(': ')[2]?.split(' ')[0]
            let message = 'FORBIDEN'

            if (responseError)
              message = JSON.parse(responseError)?.error?.message

            showResponseError(message)
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefreshToken = async () => {
    await handleUpdateToken({
      url: 'auth/refresh-token',
      data: {
        refresh_token: refreshToken,
      }
    }).catch(() => {
      handleLogin()
        .then(() => showInfo(<I18n path='UPDATED_TOKEN' />))
        .catch(() => setAuthenticated(false))
    })
  }

  const handleChangeProject = async (project) => {
    if (project === currentProject) {
      return
    }

    setLoadingProject(true)

    const firebaseToken = await getTokenFirebase(true)
      .catch(() => setAuthenticated(false))

    await handleUpdateToken({
      url: 'auth/change-project',
      data: {
        firebaseToken: firebaseToken,
        projectId: project,
      },
    })
      .catch(() => setAuthenticated(false))
      .finally(() => setLoadingProject(false))
  }

  const handleLogout = (message, type) => {
    navigate('/login')

    setAccessToken(null)
    setPermissions(null)
    setRefreshToken(null)

    signOut(auth)
      .then(() => {
        if (message) {
          switch (type) {
          case 'success':
            showResponseSuccess(message)
            break
          case 'alert':
            showResponseAlert(message)
            break
          case 'info':
            showResponseInfo(message)
            break
          default:
            showResponseError(message)
            break
          }
        }
      })
      .finally(() => {
        setAuthenticated(false)
        setUser(null)
      })
  }

  return {
    user,
    authenticated,
    globalLoading,
    handleLogin,
    handleLogout,
    accessToken,
    refreshToken,
    permissions,
    currentProject,
    handleRefreshToken,
    handleChangeProject,
    allowedProjects,
    loadingProject
  }
}

export default useAuth