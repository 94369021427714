import React, { useEffect, useState } from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import FormDataAssignedPermissions, { IPermission, ISaveFormData } from './FormData'
import { KeyedMutator } from 'swr'
import useApi from '../../../../context/hooks/useApi'
import { IPermissions } from '..'

interface IEditPermission {
  id: string
  onCloseModal: () => void
  mutate: KeyedMutator<IPermissions>
}

const EditPermission = (props: IEditPermission) => {
  const {
    id,
    onCloseModal,
    mutate,
  } = props

  const [permission, setPermission] = useState<IPermission>()

  const { data: dataPermission } = useApi<IPermission>({ url: `roles/permission/${id}` })

  useEffect(() => {
    if (dataPermission) {
      setPermission(dataPermission)
    }
  }, [dataPermission])

  const handleSave = async (data: ISaveFormData) => {
    await api({
      method: 'PUT',
      url: `roles/permission/${id}`,
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
  }

  return (
    <FormDataAssignedPermissions
      permission={permission}
      onCloseModal={onCloseModal}
      onSaveForm={handleSave}
    />
  )
}

export default EditPermission