import * as React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Icon from '@mui/material/Icon'

interface IWIconButton extends IconButtonProps{
  icon: string
  iconColor: string
}

const WIconButton = (props: IWIconButton) => {
  const {
    onClick,
    className,
  } = props

  return (
    <Stack direction="row" spacing={1}>
      <IconButton
        onClick={onClick}
        className={className}
        sx={{color: `${props.iconColor}`}}
        {...props}
      >
        <Icon>{props.icon}</Icon>

      </IconButton>
    </Stack>
  )
}

export default WIconButton