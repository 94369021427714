import * as React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'

interface IWCheckbox extends CheckboxProps {
  i18n?: JSX.Element
  defaultChecked: boolean
  checked: boolean
  label?: string | JSX.Element
}

const WCheckbox = (props: IWCheckbox) => {
  return (
    <FormGroup>
      <FormControlLabel control={
        <Checkbox
          sx={{
            color: '#fc673d',
            '&.Mui-checked': {
              color: '#fc673d',
            },
          }}
          size='small'
          defaultChecked={props.defaultChecked}
          checked={props.checked}
          onChange={props.onChange}
        />}
      label={
        <span className='text-[var(--White)]'>
          {props.i18n || props.label}
        </span>
      }
      
      />
    </FormGroup>
  )
}

export default WCheckbox
