import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

interface WButtonProps extends LoadingButtonProps {
  label?: string | JSX.Element
  loading?: boolean
}

const WButton = (props: WButtonProps) => {
  const {
    variant,
    onClick,
    loading,
    loadingPosition,
    disabled,
    label,
    startIcon,
    endIcon,
    className,
    size
  } = props

  return (
    <LoadingButton
      className={`${className} bg-orange ${disabled ? '/50':''} text-white${disabled ? '/50' : ''}`}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      onClick={onClick}
      loading={loading}
      size={size}
      loadingPosition={loadingPosition}
      disabled={disabled}
      sx={{textTransform: 'none'}}
    >
      {
        label
      }
    </LoadingButton>
  )
}

export default WButton