import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../context/authContext'
import { Outlet } from 'react-router-dom'

const PrivateRoute = ({ permission }) => {
  const navigate = useNavigate()
  const { authenticated, permissions } = useContext(Context)

  if (!authenticated)
    return navigate('/login')

  if (permission && !permission.some(i => permissions?.includes(i)))
    return navigate('/home')

  return(
    <Outlet />
  )
}

export default PrivateRoute