import './App.scss'
import CWRoutes from './routes'
import { AuthProvider } from './context/authContext'
import { ApiProvider } from './context/apiContext'
import { BrowserRouter } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEquals,
  faNotEqual,
  faPercent,
  faLessThanEqual,
  faLessThan,
  faGreaterThan,
  faGreaterThanEqual,
  faExclamation,
  faBackspace,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faEquals,
  faNotEqual,
  faPercent,
  faLessThanEqual,
  faLessThan,
  faGreaterThan,
  faGreaterThanEqual,
  faExclamation,
  faBackspace,
)

navigator.serviceWorker.register('service-worker.js')

const App = () => {
  return (
    <div className='h-full w-full'>
      <BrowserRouter>
        <AuthProvider>
          <ApiProvider>
            <CWRoutes/>
          </ApiProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
