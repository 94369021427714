import React from 'react'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
function CircularProgressWithLabel(props:CircularProgressProps  & { value: number }) {

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex'
      }}
    >
      <CircularProgress
        {...props}
        variant= {props.value < 100 && props.value > 0 ? 'indeterminate' : 'determinate'}
        style={{color: '#005700'}}
        size={30}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          style={{
            color: 'var(--White)',
            fontSize: '8px'
          }}
        >
          {props.value ?
            `${Math.round(props.value)}%`
            :
            ''
          }
        </Typography>
      </Box>

    </Box>
  )
}

interface WCircularProgress {
  value: number
}

const WCircularProgress = (props: WCircularProgress) => {
  

  return <CircularProgressWithLabel value={props.value} />
}

export default WCircularProgress