import React, { useContext, useEffect, useRef, useState } from 'react'
import WTable from '../../components/WTable/v2'
import useApi from '../../context/hooks/useApi'
import I18n from '../../components/I18n'
import { Avatar } from '@react-md/avatar'
import {  Icon, Tooltip } from '@mui/material'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import { Context } from '../../context/authContext'
import WGroupAvatar from '../../components/WGroupAvatar'
import api from '../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../helper'
import WIconButton from '../../components/WButton/Icon'

const ApproveCenter = () => {

  const { permissions } = useContext(Context)

  const permissionApproveCenterPut = permissions?.some(i => (i === 'approve-center.put'))


  const [approveRequests, setApproveRequests] = useState([])
  const [listBuilds, setListBuilds] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [refreshData, setRefreshData] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState('')
  const [count, setCount] = useState(0)

  const {data: requests, isLoading: isloadingPipelines, mutate: mutateApproveCenter} = useApi({url: `approve-center?limit=${limit}&page=${currentPage}&order=desc${filters}`})

  const WTableRef = useRef(null)

  useEffect(() => {
    mutateApproveCenter()
  }, [refreshData])

  useEffect(() => {
    if (requests) {
      setApproveRequests(requests?.data)
      setCount(requests?.count)
    }
  }, [requests])


  const handleAfterResponseActions = () => {
    setIsLoading(false)
    setRefreshData(!refreshData)
    mutateApproveCenter()
  }

  const rejectRequest = async (data) => {
    const updatedData = {...data, status: 'rejected'}
    setIsLoading(true)
    await api({
      method: 'PUT',
      url: `approve-center/${data.id}`,
      data: updatedData
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
      })
      .catch(error => showResponseError(error))
      .finally(() => handleAfterResponseActions())
  }

  const approveRequest = async (data) => {
    const updatedData = {...data, status: 'approved'}
    setIsLoading(true)
    await api({
      method: 'PUT',
      url: `approve-center/approve/${data.id}`,
      data: updatedData
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
      })
      .catch(error => showResponseError(error))
      .finally(() => handleAfterResponseActions())
  }

  useEffect(() => {
    if (Array.isArray(approveRequests)) {
      const listBuildData = approveRequests.map((approveInformation) => {
        const tooltipMessage =
          approveInformation?.status?.toLowerCase() === 'approved'
            ? 'Request already approved'
            : 'Request already rejected'

        return {
          isCollapse: false,
          isSelectable: false,
          cells: [
            {
              key: 'user',
              value: (
                <div className='flex items-center justify-center w-full'>
                  <Tooltip
                    title={approveInformation?.requestUserName || 'User without name'}
                  >
                    <Avatar src={approveInformation?.requestUserPicture} className='w-9 h-9'>
                      {!approveInformation?.requestUserPicture && <Icon >person</Icon>}
                    </Avatar>
                  </Tooltip>
                </div>
              ),
            },
            {
              key: 'type',
              value: <div>{approveInformation?.type}</div>,
            },
            {
              key: 'status',
              value: <div>
                <Tooltip
                  title={approveInformation?.status?.toUpperCase()}>
                  <Icon
                    data-status={approveInformation?.status?.toLowerCase()}
                    className={`
                    flex justify-center items-center text-2xl
                    data-[status=pending]:text-orange data-[status=pending]:animate-spin-slow
                    data-[status=approved]:text-green-900
                    data-[status=rejected]:text-red
                    `}
                  >
                    {approveInformation?.status?.toLowerCase() === 'pending'
                      ?
                      'pending'
                      :
                      approveInformation?.status?.toLowerCase() === 'approved'
                        ?
                        'check_circle'
                        :
                        'cancel'}
                  </Icon>
                </Tooltip>
              </div>,
            },
            {
              key: 'environment',
              value: <div>{approveInformation?.pipeline?.cloudBuilds?.[0]?.version}</div>,
            },
            {
              key: 'users',
              value: <div className=''>
                <WGroupAvatar
                  label= {approveInformation?.users?.map(j => j.name)}
                  avatar={approveInformation?.users?.map(j => j.picture)}
                  size={'2rem'}
                  spacing={'medium'}
                />
              </div>
            },
            {
              key: 'actionBy',
              value: <div className='flex justify-center items-center'>
                {approveInformation?.actionUser ||
                  <Tooltip
                    title={<I18n path='APPROVE_CENTER.PROJECTS.TABLE.WAIT_APPROVE'/>}>
                    <Icon className='flex justify-center items-center text-orange text-2xl animate-spin-slow'>pending</Icon>
                  </Tooltip>
                }
              </div>,
            },
            {
              key: 'action',
              value: (
                <div className='flex items-center justify-center w-full'>
                  <Tooltip
                    title={tooltipMessage}
                    disableHoverListener={approveInformation?.status === 'pending'}
                    placement="top"
                  >
                    <div className='flex flex-row space-x-1'>
                      <WIconButton
                        icon='done'
                        data-active={approveInformation?.status?.toLowerCase() === 'pending'}
                        className='text-green-900 transition delay-150 duration-500 ease-in-out data-[active=true]:hover:bg-green-400 data-[active=false]:opacity-40 data-[active=false]:text-green-600  data-[active=false]:cursor-auto'
                        loading={isLoading}
                        onClick={permissionApproveCenterPut && approveInformation?.status?.toLowerCase() === 'pending' ? () => approveRequest(approveInformation) : ''}
                      />
                      <WIconButton
                        data-active={approveInformation?.status?.toLowerCase() === 'pending'}
                        icon='close'
                        className='text-red transition delay-150 duration-500 ease-in-out data-[active=true]:hover:bg-red-100 data-[active=false]:opacity-40 data-[active=false]:cursor-auto'
                        loading={isLoading}
                        onClick={permissionApproveCenterPut && approveInformation?.status?.toLowerCase() === 'pending' ? () => rejectRequest(approveInformation) : ''}
                      />
                    </div>
                  </Tooltip>
                </div>
              ),
            },
          ],
        }
      })
      setListBuilds(listBuildData)
    }
    setIsLoading(false)
  }, [approveRequests, requests])

  const headers = [
    {
      key: 'user',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.OWNER' />,
      align: 'center',
      hidden: false,
    },
    {
      key: 'type',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.TYPE' />,
      align: 'center',
      hidden: false,
    },
    {
      key: 'status',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.STATUS' />,
      align: 'center',
      hidden: false,
      sortable: true,
      searchable: {
        operator: 'EQUAL',
        options: [
          {
            label: 'Rejected',
            value: 'rejected',
          },
          {
            label: 'Approved',
            value: 'approved',
          },
          {
            label: 'Pending',
            value: 'pending',
          },
        ],
      },
    },
    {
      key: 'environment',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.ENVIRONMENT' />,
      align: 'center',
      hidden: false,
    },
    {
      key: 'users',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.USERS' />,
      align: 'center',
      hidden: false
    },
    {
      key: 'actionBy',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.ACTION_BY' />,
      align: 'center',
      hidden: false,
    },
    {
      key: 'action',
      value: <I18n path='APPROVE_CENTER.PROJECTS.TABLE.ACTION' />,
      align: 'center',
      hidden: false,
    },
  ]

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setLimit(rowsPerPage)
  }

  const onChangePage = (newPage) => {
    setCurrentPage(newPage)
    setRefreshData(!refreshData)
  }

  const handleChangeFilters = (filters) => {
    setFilters(filters)
  }

  return (
    <div>
      {isLoading && <Loading />}
      <div className='w-full sm:max-w-[60rem] md:max-w-[82rem] lg:max-w-[90rem] mx-auto'>
        <WTable
          ref={WTableRef}
          isLoadingData={isloadingPipelines}
          headers={headers}
          rows={listBuilds}
          isCollapse={false}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={onChangePage}
          onChangeFilters={handleChangeFilters}
          limit={limit}
          countRows={count}
          optionsRowsPerPage={[]}
        />
      </div>
    </div>
  )
}

export default ApproveCenter
