import React, { useState, useEffect, useRef, useContext } from 'react'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import WTable from '../../../../components/WTable'
import I18n from '../../../../components/I18n'
import useApi from '../../../../context/hooks/useApi'
import WSelectFieldMuilti from '../../../../components/WSelectFieldMulti'
import { Context } from '../../../../context/authContext'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

const headerTable = [
  { label: <I18n path='Admin.IAM.TABLE.ROLES.NAME'/> },
  { label: <I18n path='Admin.IAM.TABLE.ROLES.PERMISSIONS'/> },
]

const Roles = () => {
  const { permissions } = useContext(Context)

  const [rows, setRows] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [listPermissions, setListPermissions] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [permissionsRole, setPermissionsRole] = useState()
  const [titleModal, setTitleModal] = useState('')
  const [nameRole, setNameRole] = useState('')
  const WtableRef = useRef()


  const { data: dataRoles, isLoading: loadingRoles, mutate: mutateRoles } = useApi({ url: 'roles' })
  const { data: dataPermissions, isLoading: loadingPermissions } = useApi({ url: 'roles/permission' })

  useEffect(() => {
    switch (typeModal) {
    case 'add':
      setTitleModal(<I18n path='Admin.IAM.MODAL.ADD_ROLE'/>)
      break
    case 'edit':
      setTitleModal(<I18n path='Admin.IAM.MODAL.EDIT_ROLE'/>)
      getDataRole()
      break
    case 'delete':
      setTitleModal(<I18n path='Admin.IAM.MODAL.DELETE_ROLE'/>)
      getDataRole()
      break
    }

    if (typeModal.length)
      setOpenModal(true)
  }, [typeModal])

  useEffect(() => {
    if (dataPermissions) {
      const newPermissions = dataPermissions.data?.map(i => ({ label: i.permission, value: i.id }))
      setListPermissions(newPermissions)
    }
  }, [dataPermissions])

  useEffect(() => {
    if (dataRoles) {
      const listRoles = dataRoles.data?.map(i => (
        {
          cells: [
            { id: i.id, hidden: true },
            { label: i.role },
            { label: i.permissions?.map(j => j.permission).join(', '), lineWrap: true }
          ]
        }
      ))

      setRows(listRoles)
    }

  }, [dataRoles])

  const getDataRole = async () => {
    setLoading(true)
    const id = getCellsSelected().filter(i => i.id).map(i => i.id)[0]

    await api({
      method: 'GET',
      url: `roles/${id}`
    }).then(({ data }) => {
      setNameRole(data.role)

      setPermissionsRole(listPermissions.filter(i => data.permissions?.some(j => j.id === i.value)))
    }).catch(error => showResponseError(error))
      .finally(() => setLoading(false))
  }

  const getRowsSelected = value => {
    setCheckedRows(value)
  }

  const getCellsSelected = () => {
    return checkedRows.flatMap(i => i.cells)
  }

  const getCellsSelectedToDelete = () => {
    return checkedRows.flatMap(i => ([i.cells]))
  }

  const onCloseModal = () => {
    WtableRef.current?.clearCheckedValues()
    setOpenModal(false)
    setTypeModal('')
    setNameRole('')
    setPermissionsRole([])
  }

  const addNewRole = async () => {
    setLoading(true)
    const data = {
      role: nameRole,
      permissions: permissionsRole?.map(i => ({ id: i.value }))
    }

    await api({
      method: 'POST',
      url: 'roles',
      data
    })
      .then(({ data }) => {
        mutateRoles()
        showResponseSuccess(data?.message)
      })
      .catch(error => showResponseError(error))
      .finally(() => setLoading(false))
  }

  const editRole = async () => {
    const id = getCellsSelected().filter(i => i.id)?.map(i => i.id)[0]
    const permissionsSelected = permissionsRole?.map(i => ({ id: i.value, permission: i.label }))

    const data = {
      role: nameRole,
      permissions: permissionsSelected
    }

    api({
      method: 'PUT',
      url: `roles/${id}`,
      data
    })
      .then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => {
        showResponseError(error)
        mutateRoles()
      })

    const editedRoles = dataRoles.data.map(i => (i.id === id ? { ...i, role: nameRole, permissions: permissionsSelected } : i))
    mutateRoles({ data: editedRoles }, false)
  }

  const deleteRole = async () => {
    const ids = getCellsSelected().filter(i => i.id).map(i => i.id)

    api({
      method: 'DELETE',
      url: 'roles',
      params: {
        ids: ids.join(',')
      }
    })
      .then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => {
        showResponseError(error)
        mutateRoles()
      })

    const newRows = [...dataRoles.data].filter(i => !ids.includes(i.id))

    mutateRoles({ data: newRows }, false)
  }

  const handleSubmitModal = () => {
    switch (typeModal) {
    case 'add':
      addNewRole()
        .finally(() => onCloseModal())
      break
    case 'edit':
      editRole()
        .finally(() => onCloseModal())
      break
    case 'delete':
      deleteRole()
        .finally(() => onCloseModal())
      break
    default:
      break
    }
  }

  const buttons = [
    {
      type: 'icon',
      icon: 'add',
      onClick: () => setTypeModal('add'),
      disabled: !permissions?.includes('iam.roles.post')
    },
    {
      type: 'icon',
      icon: 'edit',
      disabled: checkedRows.length !== 1 || !permissions?.includes('iam.roles.put'),
      onClick: () => setTypeModal('edit'),
    },
    {
      type: 'icon',
      icon: 'delete',
      disabled: (!checkedRows.length || !permissions?.includes('iam.roles.delete')),
      onClick: () => setTypeModal('delete'),
    },
  ]

  return (
    <div className='container'>
      {(loadingRoles || loadingPermissions) && <Loading />}
      {
        <div>
          <WCustomModal
            buttonLabel={typeModal === 'delete' ? 'Remove' : typeModal === 'edit' ? 'Save' : 'Confirm'}
            open={openModal}
            title={titleModal}
            handleClose={onCloseModal}
            buttonOnConfirm={handleSubmitModal}
            icon={typeModal === 'delete' ? 'delete' : typeModal === 'edit' ? 'edit' : 'check_circle'}
            loading={loading}
            element={
              typeModal !== 'delete' ?
                <div className='flex flex-col w-96 h-96 items-center space-x-[10px]'>
                  <div className='w-full'>
                    <WTextFieldV2
                      label={<I18n path='Admin.IAM.MODAL.NAME'/>}
                      required
                      margin='dense'
                      value={nameRole}
                      onChange={ev => setNameRole(ev.target.value)}
                      variant='standard'
                    />
                  </div>
                  <div className='w-full mt-10'>
                    <WSelectFieldMuilti
                      label={<I18n path='Admin.IAM.MODAL.ASSIGNED_PERMISSIONS'/>}
                      required
                      value={permissionsRole}
                      onChange={ev => setPermissionsRole(ev)}
                      options={listPermissions}
                    />
                  </div>
                </div>
                :
                <div className='flex flex-row w-80 h-60 justify-center items-center text-white'>
                  <span className='border-b border-orange w-40 text-center'>
                    {getCellsSelectedToDelete().map((i, index) => (
                      <p key={index} className='mb-2'>
                        {i?.[1].label}
                      </p>
                    ) )}
                  </span>
                </div>
            }
          />
        </div>
      }
      <div className='row' style={{ paddingTop: '10px' }}>
        <WTable
          ref={WtableRef}
          fullWidth={true}
          tableHeader={true}
          checkboxRows={true}
          headerCells={headerTable}
          tableFooter={true}
          footerPagination={false}
          rows={rows}
          getCheckedValues={getRowsSelected}
          topMenu={true}
          buttonsTopMenu={buttons}
        />
      </div>
    </div>
  )
}

export default Roles