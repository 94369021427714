import React, { useState, useContext } from 'react'
import WEditor from '../../../../components/WEditor'
import useApi from '../../../../context/hooks/useApi'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import I18n from '../../../../components/I18n'
import api from '../../../../context/apiContext'
import { showResponse, showResponseSuccess } from '../../../../helper'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Context } from '../../../../context/authContext'
import WButton from '../../../../components/WButton'

const GenerateCode = () => {
  const { permissions } = useContext(Context)

  const [loading, setLoading] = useState(false)
  const { data: file, isLoading, mutate } = useApi({ url: 'generate-code' })


  const handleUpdateGenerateCode = () => {
    setLoading(true)
    api({
      method: 'POST',
      url: 'generate-code',
    }).then(({ data }) => {
      mutate()
      showResponseSuccess(data?.message)
    }).catch(error => showResponse(error, null, null, error?.response?.data?.message === 'RUNNING_PROCESS' ? 'info' : 'danger'))
      .finally(() => setLoading(false))
  }

  const UpdateButtonDisabled = !permissions?.includes('services.cloud_run.generate_code.post')

  return (
    <div className='h-full w-full'>
      <div className='h-full sm:grid grid-cols-3'>
        <div className='col-span-3 sm:col-span-2'>
          {
            isLoading && <Loading />
          }

          <WEditor
            filename={file?.fileName}
            code={file?.data}
            language='json'
            options={{
              readOnly: true
            }}
          />
        </div>
        <div className='flex w-full flex-col items-center space-y-10'>
          <div className='flex justify-center flex-wrap'>
            <div className='p-[10px] rounded-[10px] bg-[var(--GreyBlack)] w-[300px] text-white'>
              <div>
                <I18n path='Services.CLOUD_RUN.INFO_GENERATE_CODE'/>
              </div>
              <div className='flex justify-center pt-[10px]'>
                <WButton
                  onClick={handleUpdateGenerateCode}
                  variant='contained'
                  loading={loading}
                  endIcon={<DriveFileRenameOutlineIcon />}
                  loadingPosition='end'
                  disabled={UpdateButtonDisabled}
                  label={<I18n path='Services.CLOUD_RUN.BUTTON_UPDATE'/>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateCode