export const CONSTANTS = {
  version: process.env.REACT_APP_VERSION,
  Style: {
    SelectedRowColor: '#fc673d',
    BlackColor: '#000000',
    GreyBlackColor: '#1C1E1F',
    GreyColor: '#252525',
    SelectGrayColor: '#303030',
    SelectCollapseGrayColor: '#202020',
    BorderColor: '#CCCCCC',
    AmountPlus: '#1976d2',
    AmountMinus: '#e53935',
    GreenAmount: '#388e3c',
    GreenText: '#c8e6c9',
    RedAmount: '#e53935',
    RedText: '#ffccbc',
    ModalCol1Width: '130px',
    TabCol1Width: '150px',
    ErrorFontColor: '#a94442',
    TableHeaderBackgroundColor: '#424242',
    TableHeaderTextColor: '#f5f5f5',
    TableStripedBackgroundColor: '#f9f9f9',
    ClinicorpOrange: '#fc673d',
    NewOrangeClinicorp: '#a73804',
    BlueStatus: '#1976d2',
    RedStatus: '#e53935',
    YellowStatus: '#ff9801',
    PurpleStatus: '#9c27b0',
    White: '#E5E5E5',
    ButtonRaisedBackground: '#2a7fd5',
    ApprovedEstimate: '#008b8b',
    ErrorColor: '#f44336'
  }
}