
import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepConnector,
  stepConnectorClasses,
  styled,
  StepIconProps,
  Icon
} from '@mui/material'
import Check from '@mui/icons-material/Check'
import WButton from '../WButton'
import I18n from '../I18n'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'

interface IShowChildrens {
  stepName: string | JSX.Element
  element: JSX.Element
  emptyCell?: boolean
  validar?: () => boolean | undefined
}
interface IWHorizontalLinearStepper {
  childrens: IShowChildrens[]
  finishStep: JSX.Element
  onSaveClick: () => void
  type: string | JSX.Element
  iconButton?: string
  onHandleFinishStep?: () => void
  isLoading?: boolean
  isLoadingButton?: boolean
}
const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    transition: '1s'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#a73804',
      transition: '1s'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#a73804',
      transition: '1s'
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'gray',
    borderTopWidth: 3,
    borderRadius: 1,
    transition: '1s'
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: '#a73804',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    transition: '1s',
    ...(ownerState.active && {
      color: '#a73804',
      transition: '1s'
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#a73804',
      zIndex: 1,
      fontSize: 18,
      transition: '1s'
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#a73804',
      transition: '1s'
    },
  }),
)

function QontoStepIcon(props: StepIconProps): JSX.Element {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

/**
 * @visibleName 🔸 How to use this component 🔸
 *
 * Is recommended use this component with WModal, but is optional.
 *
 * To use this component y need to pass the following props.
 *
 * childrens={[{
 *
 *   stepName: 'string' (if you want to show no steps names just pass an empty string)
 *
 *    element: <element></element> (the element that you want to render on the step)
 *
 * }]}
 *
 * finishedStep={
 *
 *   <element></element>  (the element that is the finish itens configured)
 *
 * }
 *
 * onSaveClick={function}  (the function that run something on your logic)
 *
 * type= 'Create' || 'Edit'  (Show that your steps is for edit or creating something)
 *
 * Example file: client/src/pages/admin/microservices/cloud-functions/FormData.jsx:245
 */

const WHorizontalLinearStepper = (props: IWHorizontalLinearStepper) => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNextFinishStep = () => {
    if(typeof props.onHandleFinishStep === 'function'){
      props.onHandleFinishStep()
    }
  }
  
  const handleNext = () => {
    if(props.childrens[activeStep].emptyCell)
      return

    const isValid = props.childrens[activeStep]?.validar?.() ?? true
    if(!isValid)
      return

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (activeStep === props.childrens.length - 1){
      handleNextFinishStep()
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div className='flex flex-col min-w-full relative' style={{minHeight: 'inherit'}}>
      {props.isLoading && <Loading />}

      <div>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {props.childrens.map(({ stepName }, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={`${stepName}-${index}`} {...stepProps} sx={{ color: 'white' }}>
                <StepLabel StepIconComponent={QontoStepIcon} {...labelProps}>{stepName}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>
      <div
        className={`${activeStep === props.childrens.length ? 'block' : 'hidden'}`}
      >
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, color: 'white' }}>
            {props.finishStep}
          </Typography>
        </React.Fragment>
      </div>
      {
        props.childrens.map((i, index) => (
          <div
            className={`mt-4 m-h-full h-auto ${index === activeStep ? 'block' : 'hidden'}`}
            key={index}>
            <div className='w-full'>
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {i.element}
                </Typography>
              </React.Fragment>
            </div>
          </div>
        ))}
        
      <div className='flex w-full mt-auto justify-between'>
        <div>
          {activeStep != 0 &&
          <WButton
            disabled={activeStep === 0}
            onClick={handleBack}
            label={<I18n path='Global.PREVIOUS'/>}
          >
          </WButton>
          }
        </div>
        {activeStep === props.childrens.length ?
          <div>
            <WButton
              onClick={props.onSaveClick}
              label={props.type}
              loading={props.isLoadingButton}
              startIcon={<Icon>{props.iconButton || 'add_circle'}</Icon>}
              loadingPosition='start'
            />
          </div> :
          <div>
            <WButton
              onClick={handleNext}
              label={activeStep === props.childrens.length - 1 ? <I18n path='Global.CONFIRM'/> : <I18n path='Global.NEXT'/>}
              variant='contained'
              loading={props.isLoadingButton}
              disabled={props.childrens[activeStep].emptyCell}
            >
            </WButton>
          </div>
        }
      </div>
    </div>
  )
}
export default WHorizontalLinearStepper