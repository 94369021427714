import React, { useState, useCallback, useContext, useEffect } from 'react'
import Icon from '@mui/material/Icon'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import I18n from '../../../components/I18n'
import ModalCancelBuild from './ModalCancelBuild'
import ModalRetrySyntheticTests from './ModalRetrySyntheticTests'
import ModalPromoteVersion from './ModalPromoteVersion'
import { Context } from '../../../context/authContext'

const WStatus = (props) => {
  const {
    builds,
    buildId,
    reloadBuilds,
    pipelineId
  } = props

  const { permissions } = useContext(Context)

  const [isLoading, setIsLoading] = useState(false)
  const [openModalCancelBuild, setOpenModalCancelBuild] = useState(false)
  const [openModalRetrySyntheticTests, setOpenModalRetrySyntheticTests] = useState(false)
  const [openModalPromoteVersion, setOpenModalPromoteVersion] = useState(false)
  const [hoverWorkingStatus, setHoverWorkingStatus] = useState()
  const [status, setStatus] = useState('QUEUED')

  useEffect(() => {
    let statusSucces = 0, statusError = 0, statusWorking = 0, statusCancelled = 0, statusWaitingPromote = 0, statusTimeout = 0
    builds.forEach(element => {
      if (element.status === 'FAILURE') statusError += 1
      else if (element.status === 'TIMEOUT') statusTimeout += 1
      else if (element.status === 'SUCCESS') statusSucces += 1
      else if (element.status === 'CANCELLED') statusCancelled += 1
      else if (element.status === 'WAITING_TO_PROMOTE') statusWaitingPromote += 1
      else statusWorking += 1
    })

    if (statusWorking > 0) {
      setStatus('WORKING')
    } else if (statusTimeout > 0) {
      setStatus('TIMEOUT')
    } else if (statusError > 0 && (statusSucces > 0 || statusWorking > 0)) {
      setStatus('WARNING')
    } else if (statusError > 0) {
      setStatus('FAILURE')
    } else if (statusWaitingPromote > 0) {
      setStatus('WAITING_TO_PROMOTE')
    } else if (statusSucces > 0) {
      setStatus('SUCCESS')
    } else if (statusCancelled > 0 && (statusSucces === 0 && statusWorking === 0 && statusError === 0)) {
      setStatus('CANCELLED')
    } else {
      setStatus('QUEUED')
    }
  }, [builds])

  const getClassStatusBuild = status => {
    switch (status?.toLowerCase()) {
    case 'working': return 'working-build'
    case 'warning': return 'warning-build'
    case 'success': return 'success-build'
    case 'cancelled': return 'cancelled-build'
    case 'cancel': return 'cancel-build'
    case 'testing': return 'testing-build'
    case 'waiting_to_promote': return 'waiting-to-promote'
    case 'test_synthetic_failed': return 'testing-failure'
    case 'promoting': return 'promoting'
    case 'queued' : return 'queued-build'
    case 'timeout' : return 'fail-build'
    default: return 'fail-build'
    }
  }

  const getIcon = status => {
    switch (status?.toLowerCase()) {
    case 'working': return 'autorenew'
    case 'warning': return 'priority_high'
    case 'success': return 'check'
    case 'cancelled': return 'do_disturb'
    case 'cancel': return 'cancel'
    case 'testing': return 'copyright'
    case 'waiting_to_promote': return 'trending_up'
    case 'test_synthetic_failed': return 'replay'
    case 'promoting': return 'change_circle'
    case 'queued' : return 'schedule'
    case 'timeout' : return 'schedule'
    default: return 'clear'
    }
  }

  const handleFocusIcon = () => {
    if (status?.toLocaleLowerCase() !== 'working' && status?.toLocaleLowerCase() !== 'queued')
      return

    setHoverWorkingStatus('cancel')
  }

  const handleBlurIcon = () => {
    if (status?.toLocaleLowerCase() !== 'working' && status?.toLocaleLowerCase() !== 'queued')
      return

    setHoverWorkingStatus(null)
  }

  const handleOpenModalPromoteVersion = () => {

    setOpenModalPromoteVersion(true)
  }

  const handleOpenModalRetrySynthetictests = () => {
    if (!permissions?.includes('builds.pipelines.retry_syntehtic_tests')) return

    setOpenModalRetrySyntheticTests(true)
  }

  const handleOnClickStatus = () => {
    switch (status.toLowerCase()) {
    case 'working':
    case 'queued': return setOpenModalCancelBuild(true)
    case 'waiting_to_promote': return handleOpenModalPromoteVersion()
    case 'test_synthetic_failed': return handleOpenModalRetrySynthetictests()
    default: return
    }
  }

  const getCursorIconStatus = useCallback(() => {
    switch (status?.toLocaleLowerCase()) {
    case 'working':
    case 'queued': return 'pointer'
    case 'test_synthetic_failed': return permissions?.includes('builds.pipelines.retry_syntehtic_tests') ? 'pointer' : 'auto'
    case 'waiting_to_promote': return (permissions?.includes('pipelines.promote')) ? 'pointer' : 'auto'
    case 'success':
    default: return 'auto'
    }
  })

  const onCloseModalCancelBuild = () => {
    setOpenModalCancelBuild(false)
  }

  const onCloseModalRetrySyntheticTests = () => {
    setOpenModalRetrySyntheticTests(false)
  }

  const onCloseModalPromoteVersion = () => {
    setOpenModalPromoteVersion(false)
  }

  const loading = value => {
    setIsLoading(value)
  }

  return (
    <>
      {
        openModalCancelBuild &&
        <ModalCancelBuild
          pipelineId={pipelineId}
          openModal={openModalCancelBuild}
          onCloseModal={onCloseModalCancelBuild}
          status={status}
          reloadBuilds={reloadBuilds}
          isLoading={isLoading}
          loading={loading}
        />
      }
      {
        openModalRetrySyntheticTests &&
        <ModalRetrySyntheticTests
          buildId={buildId}
          openModal={openModalRetrySyntheticTests}
          isLoading={isLoading}
          loading={loading}
          onCloseModal={onCloseModalRetrySyntheticTests}
          reloadBuilds={reloadBuilds}
        />
      }
      {
        openModalPromoteVersion &&
        <ModalPromoteVersion
          pipelineId={pipelineId}
          openModal={openModalPromoteVersion}
          isLoading={isLoading}
          loading={loading}
          onCloseModal={onCloseModalPromoteVersion}
          reloadBuilds={reloadBuilds}
        />
      }
      <Tooltip
        tooltip={<I18n path={`Global.${status}`}/>}
        tooltipStyle={{ fontSize: '12px' }}
      >
        <Icon
          style={{ cursor: getCursorIconStatus(), width:'25px', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          onMouseEnter={handleFocusIcon}
          onMouseLeave={handleBlurIcon}
          className={getClassStatusBuild(hoverWorkingStatus ?? status)}
          onClick={handleOnClickStatus}
          fontSize='small'
        >{getIcon(hoverWorkingStatus ?? status)}</Icon>
      </Tooltip>
    </>
  )
}

export default WStatus