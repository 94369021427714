import React, { useState, useEffect } from 'react'
import WTable from '../../../components/WTable/v2'
import useApi from '../../../context/hooks/useApi'
import { useTranslation } from 'react-i18next'
import I18n from '../../../components/I18n'
import { Icon, Tooltip } from '@mui/material'

const headers = [
  {
    key: 'id',
    value: 'Id',
    align: 'center',
    hidden: true
  },
  {
    key: 'modifications',
    value: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.MODIFICATIONS'/>,
    align: 'center',
    sortable: true,
    searchable: {
      operator: 'EQUAL',
      options:[
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.FILTERS.MODIFICATIONS.TRUE'/>,
          value: 1,
        },
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.FILTERS.MODIFICATIONS.FALSE'/>,
          value: 0,
        },
      ]
    },
  },
  {
    key: 'url',
    value: 'Url',
    align: 'center',
    sortable: true,
    searchable: true,
  },
  {
    key: 'created_at',
    value: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.CREATED_AT'/>,
    align: 'center',
    sortable: true,
    searchable: false
  },
  {
    key: 'promote_at',
    value: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.PROMOTED_AT'/>,
    align: 'center',
    sortable: true,
    searchable: false,
  }
]

const collapseHeaders = [
  {
    key: 'id',
    align: 'center',
    hidden: true
  },
  {
    key: 'compare_latest_version',
    align: 'center',
    value: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_HEADERS.MODIFICATIONS'/>,
    sortable: true,
    searchable: {
      operator: 'EQUAL',
      options:[
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_ROWS.COMPARE.LESS'/>,
          value: 'less',
        },
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_ROWS.COMPARE.MORE'/>,
          value: 'more',
        },
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_ROWS.COMPARE.MODIFIED'/>,
          value: 'modified',
        },
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_ROWS.COMPARE.EQUAL'/>,
          value: 'equal',
        },
      ]
    },
  },
  {
    key: 'data',
    align: 'center',
    value: 'Data',
    sortable: true,
    searchable: true
  },
  {
    key: 'path',
    align: 'center',
    value: 'Path',
    sortable: true,
    searchable: true
  },
  {
    key: 'require_authentication',
    align: 'center',
    value: <I18n path='AppCare.CHECK_ROUTES.TABLE.COLLAPSE_HEADERS.REQUIRE_AUTHENTICATION'/>,
    sortable: true,
    searchable: {
      operator: 'EQUAL',
      options:[
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.FILTERS.MODIFICATIONS.TRUE'/>,
          value: 1,
        },
        {
          label: <I18n path='AppCare.CHECK_ROUTES.TABLE.HEADERS.FILTERS.MODIFICATIONS.FALSE'/>,
          value: 0,
        },
      ]
    },
  },
  {
    key: 'status',
    align: 'center',
    value: 'Status',
    sortable: true,
    searchable: true
  }
]

const defaultLimitCollapse = 25

const getColorByStatus = status => {
  switch (status) {
  case 'less':
    return 'error'
  case 'more':
    return 'success'
  case 'modified':
    return 'warnig'
  case 'equal':
  default:
    return 'disabled'
  }
}

const CheckRoutes = () => {
  const [rows, setRows] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [currentPageCollapse, setCurrentPagecollapse] = useState(0)
  const [filters, setFilters] = useState('')
  const [filtersCollapse, setFiltersCollapse] = useState('')
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_at')
  const [orderCollapse, setOrderCollapse] = useState('desc')
  const [orderByCollapse, setOrderByCollapse] = useState('created_at')
  const [limitCollapse, setLimitCollapse] = useState(defaultLimitCollapse)
  const { data: listUrls, isLoading } = useApi({ url: `app-care/endpoints?limit=${limit}&page=${currentPage}&order=${order}&orderBy=${orderBy}${filters}` })
  const { data: listEndpoints, isLoading: loadingEndpoints} = useApi({ url: `${selectedRowId ? `app-care/endpoints/${selectedRowId}?limit=${limitCollapse}&page=${currentPageCollapse}&order=${orderCollapse}&orderBy=${orderByCollapse}${filtersCollapse}` : ''}`})
  const { i18n } = useTranslation()

  useEffect(() => {
    if (listUrls?.data?.length) {

      const newRows = listUrls?.data[0]?.map(i => (
        {
          isCollapse: true,
          key: i.id,
          cells: [
            {
              key: 'id',
              value: i.id,
            },
            {
              key: 'modifications',
              startIcon: <Tooltip disableInteractive title={<I18n path={`AppCare.CHECK_ROUTES.TABLE.ROWS.MODIFICATIONS.${i.modifications ? 'TRUE' : 'FALSE'}`}/>}><Icon color={i.modifications ? 'error' : 'disabled'} >change_history</Icon></Tooltip>,
            },
            {
              key: 'url',
              value: <a href={i.url} target='_blank' rel='noreferrer'>{i.url}</a>,
              align: 'center',
              endIcon: <Icon style={{ cursor: 'pointer' }} onClick={() => window.open(i.url, '_blank')} fontSize='small'>open_in_new</Icon>
            },
            {
              key: 'created_at',
              value: new Date(i.created_at).toLocaleString(i18n.language),
              align: 'center'
            },
            {
              key: 'promote_at',
              value: i.promoted_at ? new Date(i.promoted_at).toLocaleString(i18n.language) : '-',
              align: 'center'
            }
          ]
        }))

      setRows(newRows)
      setCount(listUrls.data[1])
    }
  }, [listUrls?.data])

  useEffect(() => {
    if (listEndpoints?.length) {
      const newRows = rows.map(i => (
        i.key !== selectedRowId ? { ...i }
          : {
            ...i,
            totalRows: listEndpoints[1],
            optionsRowsPerPage: [10, 25, 100, 1000],
            collapseValues: listEndpoints[0]?.map(j => ([
              {
                key: 'id',
                value: j.id,
                align: 'center',
              },
              {
                key: 'compare_latest_version',
                startIcon: <Tooltip disableInteractive title={<I18n path={`AppCare.CHECK_ROUTES.TABLE.COLLAPSE_ROWS.COMPARE.${j.compare_latest_version?.toUpperCase()}`}/>}><Icon color={getColorByStatus(j.compare_latest_version)} >change_history</Icon></Tooltip>,
              },
              {
                key: 'data',
                value: j.data,
                align: 'center',
              },
              {
                key: 'path',
                value: j.path,
                align: 'center',
              },
              {
                key: 'require_authentication',
                value: j.require_authentication.toString(),
                align: 'center',
              },
              {
                key: 'status',
                value: j.status,
                align: 'center',
              }
            ]))
          }
      ))

      setRows(newRows)
    }
  }, [listEndpoints])

  const onCollapse = (id, isOpen) => {
    if (isOpen) {
      setSelectedRowId(id)
    }
  }

  const onChangePage = (newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setLimit(rowsPerPage)
  }

  const onChangePageCollapse = (rowId, newPage) => {
    setSelectedRowId(rowId)
    setCurrentPagecollapse(newPage)
  }

  const handleChangeRowsPerPageRowCollapse = (rowId, rowsPerPage) => {
    setSelectedRowId(rowId)
    setLimitCollapse(rowsPerPage)
  }

  const onChangeSort = (newOrder, newOrderBy) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const onChangeSortCollapse = (newOrder, newOrderBy) => {
    setOrderCollapse(newOrder)
    setOrderByCollapse(newOrderBy)
  }

  const onChangeFilters = filters => {
    setFilters(filters)
  }

  const onChangeFiltersCollapse = filters => {
    setFiltersCollapse(filters)
  }

  return (
    <div
      className='flex w-[99%] max-h-full'
    >
      <WTable
        isLoadingData={isLoading || loadingEndpoints}
        headers={headers}
        rows={rows}
        isCollapse={true}
        collapseHeaders={collapseHeaders}
        onCollapse={onCollapse}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePageCollapse={onChangePageCollapse}
        handleChangeRowsPerPageRowCollapse={handleChangeRowsPerPageRowCollapse}
        limitCollapse={limitCollapse}
        countRows={count}
        limit={limit}
        onChangeSort={onChangeSort}
        order={order}
        orderBy={orderBy}
        orderCollapse={orderCollapse}
        orderByCollapse={orderByCollapse}
        onChangeSortCollapse={onChangeSortCollapse}
        onChangeFilters={onChangeFilters}
        onChangeFiltersCollapse={onChangeFiltersCollapse}
      />
    </div>
  )
}

export default CheckRoutes