import React, { FormEvent, useEffect, useState } from 'react'
import { IListItems } from '.'
import WTextFieldV2 from '../WTextField/V2'
import WIconButton from '../WButton/Icon'

interface IWKeyValue {
  id: string,
  keyLabel: string | JSX.Element
  valueLabel: string | JSX.Element
  defaultKey?: string
  defaultValue?: string
  keyPrefix?: string | JSX.Element
  valuePrefix?: string | JSX.Element
  onChangeProps: (props: IListItems) => void
  onSlice: () => void
}

const WKeyValue = (props: IWKeyValue) => {
  const {
    id,
    keyLabel,
    valueLabel,
    defaultKey,
    defaultValue,
    keyPrefix,
    valuePrefix,
    onChangeProps,
    onSlice,
  } = props

  const [currentKey, setCurrentKey] = useState(defaultKey ?? '')
  const [currentValue, setCurrentValue] = useState(defaultValue ?? '')

  useEffect(() => {
    if (typeof onChangeProps === 'function')
      onChangeProps({ id, key: currentKey, value: currentValue })
  }, [currentKey, currentValue])

  const handleSliceEnv = () => {
    if (typeof onSlice === 'function')
      onSlice()
  }

  const handleChangeKey = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentKey(ev.currentTarget.value)
  }

  const handleChangeValue = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentValue(ev.currentTarget.value)
  }

  return (
    <div className='flex space-x-4 my-2'>
      <WTextFieldV2
        prefix={keyPrefix}
        label={keyLabel}
        value={currentKey}
        onChange={handleChangeKey}
        variant='standard'
      />
      <WTextFieldV2
        prefix={valuePrefix}
        label={valueLabel}
        value={currentValue}
        onChange={handleChangeValue}
        variant='standard'
      />
      <WIconButton
        className='rounded-3xl'
        onClick={handleSliceEnv}
        icon='delete'
        iconColor='#a73804'
      />
    </div>
  )
}

export default WKeyValue