import './style.scss'

import React, { useState, useEffect, useRef, useContext } from 'react'
import WButton from '../../components/WButton'
import Icon from '@mui/material/Icon'
import WTable from '../../components/WTable/v2'
import WStatus from './WStatus'
import TimesTampAgo from './TimestampAgo'
import Environment from './environment'
import ControlCards from './ControlCards/V2'
import I18n from '../../components/I18n'
import { Context } from '../../context/authContext'
import Details from './Details'
import useApi from '../../context/hooks/useApi'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import svgGitIcon from '../../svg/git-icon-white.svg'
import CommitAppengineCF from './CommitAppengineCF'
import WCircularProgress from '../../components/WCircularProgress'
import { Avatar } from '@mui/material'

const oneSecond = 1000

const Build = () => {
  const { currentProject, allowedProjects } = useContext(Context)

  const [timerGetBuild, setTimerGetBuild] = useState(60)
  const [filters, setFilters] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [listBuilds, setListBuilds] = useState([])
  const [count, setCount] = useState(0)
  const modalRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [ project, setProject] = useState({})

  useEffect(() => {
    setProject(allowedProjects.find(i => i.id === currentProject))
  },[currentProject, allowedProjects])

  const {data: dataBuilds, isLoading: isloadingPipelines, mutate: mutatePipelines} = useApi({url: `pipelines?limit=${limit}&page=${currentPage}&order=desc${filters}`})

  useEffect(() => {
    let interval
    interval = setInterval(() => {
      if (currentPage === 0)
        mutatePipelines()
    }, (oneSecond * timerGetBuild))
    return () => {
      clearInterval(interval)
    }
  }, [currentPage])

  const handleSuccessBuild = () => {
    mutatePipelines()
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setLimit(rowsPerPage)
  }

  const onChangeSort = (newOrder, newOrderBy) => {
    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleChangeFilters = (filters) => {
    setFilters(filters)
  }

  const onChangePage = (newPage) => {
    setCurrentPage(newPage)
  }

  const WTableRef = useRef(null)

  
  const headers = [
    {
      key: 'status',
      value: <I18n path='Application.PROJECTS.TABLE.STATUS'/>,
      align: 'center',
      hidden: false,
      
    },
    {
      key: 'user.name',
      value: <I18n path='Application.PROJECTS.TABLE.USER'/>,
      align: 'center',
      searchable: true,
      iconSerchable: 'person'
    },
    {
      key: 'cloudBuilds.version',
      value: <I18n path='Application.PROJECTS.TABLE.ENVIRONMENT'/>,
      align: 'center',
      searchable: true,
      iconSerchable: 'cloud_upload'
    },
    {
      key: 'git',
      value: <I18n path='Application.PROJECTS.TABLE.GIT'/>,
      align: 'center',
    },
    {
      key: 'appEngine',
      value: <I18n path='Application.PROJECTS.TABLE.APP_ENGINE'/>,
      align: 'center',
    },
    {
      key: 'pipeline',
      value: <I18n path='Application.PROJECTS.TABLE.PIPELINE'/>,
      align: 'center',
      hidden: true
    },
    {
      key: 'promote',
      value: <I18n path='Application.PROJECTS.TABLE.PROMOTE'/>,
      align: 'center',
      hidden: !project.isProduction
    },
    {
      key: 'details',
      value: <I18n path='Application.PROJECTS.TABLE.DETAILS'/>,
      align: 'center'
    }
    
  ]

  useEffect(() => {

    if (dataBuilds) {

      const isProgress = dataBuilds.data.some(i => (i.cloudBuilds[0]?.status?.toLowerCase() === 'working' || i.cloudBuilds[0]?.status?.toLowerCase() === 'promoting' || i.cloudBuilds[0]?.status?.toLowerCase() === 'testing'))

      setTimerGetBuild(isProgress ? 15 : 60)
      const list = dataBuilds.data.map(i => {

        return ({
          isCollapse: false,
          isSelectable: false,
          animationClass: i.cloudBuilds.some(i => (i.status === 'WORKING' || i.status === 'QUEUED')) ? 'animate-pulse' : '',
          cells: [
            {
              key: 'status',
              value:
              <div>
                <WStatus
                  builds={i.cloudBuilds}
                  pipelineId={i.id}
                  reloadBuilds={mutatePipelines}
                />
              </div>
            },
            {
              key: 'pipeline',
              value: <Tooltip
                tooltip={i.id}
                tooltipStyle={{ fontSize: '12px' }}
                className='w-[60px] truncate text-sm xs:text-xs'
              >
                {i.id}
              </Tooltip>
            },
            {
              key: 'user.name',
              value: <div className='flex items-center justify-center w-full'>
                <Tooltip
                  tooltip={i.user?.name}
                  tooltipStyle={{ fontSize: '12px' }}
                >
                  <Avatar src={i.user?.picture} fontSize='small' className='w-8 h-8'>
                  </Avatar>
                </Tooltip>
              </div>
            },
            {
              key: 'git',
              value:
                <div className='flex flex-row justify-items-center align-items-center'>
                  <div className=' w-full'>
                    <Tooltip
                      tooltip={
                        i.cloudBuilds?.filter((value, index, self) => self.findIndex((m) => m.branchName === value.branchName) === index).sort((a,b) => a.branchName < b.branchName ? -1 : a.branchName > b.branchName ? 1 : 0)?.map((j, index) => (
                          <div key={index}>
                            <p>Branch Name: {j.branchName}</p>
                            {j.branchCore ?
                              <p>Branch Core: {j.branchCore}</p>
                              :
                              <></>
                            }
                          </div>
                        ))
                        
                      }
                      tooltipStyle={{ fontSize: '12px' }}
                    >
                      <img src={svgGitIcon} style={{ width: '22px', height: '22px' }} />
                    </Tooltip>
                  </div>

                </div>
            },
            {
              key: 'appEngine',
              value:
              <div>
                <CommitAppengineCF
                  isAppEngine={i.cloudBuilds?.find(j => (j.appEngine))?.appEngine}
                  cloudFunctions={i.cloudBuilds?.filter(j => (j.cloudFunction)).map(j => j.cloudFunction)}
                  cloudRuns={i.cloudBuilds?.filter(j => (j.cloudRun)).map(j => j.cloudRun)}
                  repository={i.cloudBuilds?.find(j => (j.appEngine || j.cloudRun))?.repository?.name}
                  url={i.cloudBuilds[0]?.appEngine?.repository?.url ?? i.cloudBuilds[0]?.cloudFunction?.repository?.url ?? i.cloudBuilds[0]?.cloudRun?.repository?.url}
                  commit={i.cloudBuilds[0]?.commit}
                />
              </div>
            },
            {
              key: 'cloudBuilds.version',
              value: <div className='flex'>
                <Environment
                  environment={i.cloudBuilds.map(j => (j?.version))}
                  version={i.cloudBuilds?.find(i => i.appEngine || i.cloudRun)?.version}
                  traffic={i.cloudBuilds?.find(i => i.appEngine || i.cloudRun)?.traffic}
                />
              </div>
            },
            {
              key: 'promote',
              value:<>
                <WCircularProgress
                  value={i.cloudBuilds?.find(i => i.appEngine || i.cloudRun)?.traffic}
                />
              </>
            },
            {
              key: 'details',
              value: <div className='flex w-40 justify-evenly items-center h-full'>
                <Tooltip
                  tooltip={
                    <TimesTampAgo
                      createdTime={i.createdAt}
                      startTime={i.updatedAt}
                      textHidden={true}
                    />
                  }
                  tooltipStyle={{ fontSize: '12px' }}
                >
                  <div className='flex justify-center align-items-center'>
                    <TimesTampAgo
                      createdTime={i.createdAt}
                      startTime={i.updatedAt}
                      textHidden={false}
                    />
                  </div>
                </Tooltip>
                <div className='h-full'>
                  <Details
                    debugUrlAppEngine={i.cloudBuilds.find(j => (j.appEngine || j.cloudRun))?.debugUrl}
                    isAppEngine={i.project.name}
                    url={i.cloudBuilds.find(j => j.appEngine)?.url ?? i.cloudBuilds.find(j => j.cloudRun)?.url}
                    createdTime={i.cloudBuilds.map(j => (j?.createdAt))}
                    startTime={i.cloudBuilds.map(j => (j?.createdAt))}
                    finishTime={i.cloudBuilds.map(j => (j?.updatedAt))}
                    status={i.cloudBuilds[0]?.status}
                    project={i?.cloudBuilds[0]?.cloudRun?.project?.projectId}
                    disabled={i.cloudBuilds.every(i => (i.status === 'WORKING' || i.status === 'CANCELLED'|| i.status === 'QUEUED'|| i.status === 'FAILURE'))}
                  />
                </div>
              </div>,
              onHoverRow: true
            }
          ]
        })
      })
      setListBuilds(list)
      setCount(dataBuilds.count)
    }

  }, [dataBuilds])

  return (
    <div className='flex flex-col w-full h-full' >
      <div className='w-full'>
        <div className='flex ml-[5x] mt-[10px]'>
          <WButton
            startIcon={<Icon>add_circle</Icon>}
            label='Pipeline'
            onClick={() => setOpenModal(true)}
            className='shadow-2xl transition hover:scale-105 hover:delay-100 '
          />
        </div>
        <div ref={modalRef}>
          {openModal && (
            <ControlCards
              handleSuccessBuild={handleSuccessBuild}
              openModal={openModal}
              onCloseModal={() => setOpenModal(!openModal)}
              projectSelected={currentProject}
            />
          )}
        </div>
      </div>
      <div className='flex justify-center my-5 overflow-auto'>
        <div className='w-[100%]'>
          <WTable
            ref={WTableRef}
            isLoadingData={isloadingPipelines}
            headers={headers}
            rows={listBuilds}
            isCollapse={false}
            onChangePage={onChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            countRows={count}
            optionsRowsPerPage={[]}
            limit={limit}
            onChangeSort={onChangeSort}
            order={order}
            orderBy={orderBy}
            onChangeFilters={handleChangeFilters}
            hiddenHeader={true}
          />
        </div>
      </div>
      
    </div>
  )
}

export default Build