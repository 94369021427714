import React, { useEffect, useState } from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import FormDataRepository, { IRepository, ISaveFormData } from './FormData'
import { KeyedMutator } from 'swr'
import { IRepositories } from '..'
import useApi from '../../../../context/hooks/useApi'

interface IEditRepository {
  id: string
  onCloseModal: () => void
  mutate: KeyedMutator<IRepositories>
}

const EditRepository = (props: IEditRepository) => {
  const {
    id,
    onCloseModal,
    mutate,
  } = props

  const [repository, setRepository] = useState<IRepository>()

  const { data: dataRepository, isLoading: isLoading } = useApi<IRepository>({ url: `repositories/${id}` })

  useEffect(() => {
    if (dataRepository) {
      setRepository(dataRepository)
    }
  }, [dataRepository])

  const handleSave = async (data: ISaveFormData) => {
    await api({
      method: 'PUT',
      url: `repositories/${id}`,
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
  }

  return (
    <FormDataRepository
      repository={repository}
      onCloseModal={onCloseModal}
      onSaveForm={handleSave}
      loading={isLoading}
    />
  )
}

export default EditRepository