import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import I18n from '../I18n'
import WListkeyValue, { IListItems, WListKeyValueForwardRef } from '../WListKeyValue'

export interface IGsutil {
  id: string
  source?: string
  dest?: string
}

interface IWGsutil {
  loadCopies: IGsutil[]
  valuePrefix?: string | JSX.Element
}

export interface GsUtilForwardRefProps {
  getGsutil: () => IGsutil[]
}

const WGsutil = forwardRef<GsUtilForwardRefProps ,IWGsutil>((props, ref) => {
  const {
    loadCopies,
    valuePrefix,
  } = props

  const refListKeyValue = useRef<WListKeyValueForwardRef>(null)

  const [listGsutil, setListGsutil] = useState<IListItems[]>([])

  useEffect(() => {
    setListGsutil(loadCopies?.map(i => ({ ...i, key: i.source, value: i.dest })) ?? [])
  }, [loadCopies])

  const getGsutil = () => {
    if (refListKeyValue.current) {

      const list = refListKeyValue.current.getListItems()
      return list.map(i => ({ ...i, source: i.key, dest: i.value }))
    }

    return []
  }

  useImperativeHandle(ref, () => ({
    getGsutil
  }), [])

  return (
    <WListkeyValue
      ref={refListKeyValue}
      title={<I18n path='COMPONENTS.WGSUTIL.TITLE'/>}
      btnLabelMore={<I18n path='COMPONENTS.WGSUTIL.ADD'/>}
      loadKeyValue={listGsutil}
      keyLabel={<I18n path='COMPONENTS.WGSUTIL.SOURCE' />}
      valueLabel={<I18n path='COMPONENTS.WGSUTIL.DEST' />}
      keyPrefix='gs://'
      valuePrefix={valuePrefix}
    />
  )
})


WGsutil.displayName = 'WGsutil'
export default WGsutil