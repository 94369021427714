import React, { useState, useEffect, useRef } from 'react'
import WTable from '../../../components/WTable/v2'
import WModal from '../../../components/WModal/V2'
import FormComponent from './FormComponent'
import FormEditGroup from './FormEditGroup'
import useApi from '../../../context/hooks/useApi'
import './style.scss'
import I18n from '../../../components/I18n'
import api from '../../../context/apiContext'
import { showResponseError, showSuccess } from '../../../helper'
import WCustomModal from '../../../components/WModal/V3'
import WTextFieldV2 from '../../../components/WTextField/V2'
import { Icon, Tooltip } from '@mui/material'


const headers = [
  {
    key: 'id',
    align: 'center',
    hidden: true,
  },
  {
    key: 'group',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path={'AppCare.STATUS_PAGE.TABLE.GROUP'}/>}>
        <div className='flex align-middle'>
          <Icon color={'white'} fontSize='small'>group_work</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.GROUP'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    hidden: false,
  }
]

const collapseHeaders = [
  {
    key: 'id',
    align: 'center',
    hidden: true
  },
  {
    key: 'subcomponents',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path='AppCare.STATUS_PAGE.TABLE.SUBCOMPONENTS'/>}>
        <div className='flex items-center'>
          <Icon color={'white'} fontSize='small'>workspaces</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.SUBCOMPONENTS'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    hidden: false,
  },
  {
    key: 'endpoint',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path='AppCare.STATUS_PAGE.TABLE.ENDPOINT'/>}>
        <div className='flex items-center'>
          <Icon color={'white'} fontSize='small'>dns</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.ENDPOINT'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    searchable: false,
  },
  {
    key: 'debug',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path='AppCare.STATUS_PAGE.TABLE.DEBUG'/>}>
        <div className='flex items-center'>
          <Icon color={'white'} fontSize='small'>bug_report</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.DEBUG'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    searchable: false,
  },
  {
    key: 'slo',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path='AppCare.STATUS_PAGE.TABLE.SLO'/>}>
        <div className='flex items-center'>
          <Icon color={'white'} fontSize='small'>ssid_chart</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.SLO'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    searchable: false,
  },
  {
    key: 'status',
    value: <div>
      <Tooltip
        enterDelay={10}
        title={<I18n path='AppCare.STATUS_PAGE.TABLE.STATUS'/>}>
        <div className='flex items-center'>
          <Icon color={'white'} fontSize='small'>monitor_heart</Icon>
          <span className='ml-2'>
            {<I18n path='AppCare.STATUS_PAGE.TABLE.STATUS'/>}
          </span>
        </div>
      </Tooltip>
    </div>,
    align: 'center',
    searchable: false,
  }
]

const StatusPage = () => {

  const WTableRef = useRef(null)
  const modalRef = useRef(null)

  const [list, setList] = useState([])
  const [rowSelectedId, setRowSelectedId] = useState('')
  const [count, setCount] = useState(0)
  const [selectedRowId, setSelectedRowId] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showEditComponent, setShowEditComponent] = useState(false)
  const [showEditGroup, setShowEditGroup] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [options, setOptions] = useState([])
  const [selectOption, setSelectOption] = useState([])
  const [listGroupsToSelect, setListGroupsToSelect] = useState([])
  const [componenteDelete, setComponenteDelete] = useState('')
  const [nameGroupEdit, setNameGroupEdit] = useState('')
  const [nameGroup, setNameGroup] = useState('')


  const {data: listPages, mutate: mutatePages, isLoading: loadingPage } = useApi({url: 'status-page/pages'})
  const {data: listGroup, mutate: mutateGroup, isLoading: loadingGroup} = useApi({url: `${ selectOption?.length !== 0 ? `status-page/pages/${selectOption?.id}/group` : ''}`})
  const {data: listComponents, mutate: mutateComponents} = useApi({url:`${selectedRowId ? `status-page/pages/${selectOption?.id}/group/${selectedRowId}` : ''}`})

  const permissionsTableHeader = {
    add: 'status-page.post',
    edit: 'status-page.put',
    remove: 'status-page.delete',
  }

  useEffect(() => {

    if(listPages){
      const newOptions = listPages?.map(i => (
        {
          id: i.id,
          label: i.name,
          value: i.name,
        }
      ))
      setOptions(newOptions)
      setSelectOption(newOptions[0])
    }
  
  }, [listPages])

  useEffect(() => {

    const newGroup = listGroup?.data?.map(i => (
      {
        id: i.id,
        label: i.name,
        value: i.name,
      }
    ))
    setListGroupsToSelect(newGroup)

  }, [listGroup, selectOption])

  const select = [
    {
      options: options,
      value: selectOption,
      onChange: ((ev) => {
        setSelectOption(ev)
        mutatePages()
        mutateGroup()
        mutateComponents()
      })
    }
  ]

  useEffect(() => {

    const newList = listGroup?.data?.map(i => ({
      isCollapse: true,
      isSelectable: true,
      key: i.id,
      cells: [
        {
          key: 'id',
          value:
            <div>
              <p>{i.id}</p>
            </div>
        },
        {
          key: 'group',
          value:
            <div>
              <p>{i.name}</p>
            </div>
        }
      ]
    }))
    setList(newList)
    setCount(listGroup?.count)
  }, [listGroup, selectOption])

  useEffect(() => {

    const newList = list?.map(i => (
      i.key !== selectedRowId ? { ...i }
        : {
          ...i,
          optionsRowsPerPage: [10, 25, 100, 1000],
          totalRows: listComponents?.components?.length,
          collapseValues: listComponents?.components?.map(j => ([
            {
              key: 'id',
              value: j.id,
              align: 'center',
            },
            {
              key: 'subcomponents',
              value:
                <div className='flex text-left'>
                  <span>{j.name}</span>
                </div>
            },
            {
              key: 'endpoint',
              value:
                <div className='flex text-left'>
                  <span>{j.endpoint}</span>
                </div>
            },
            {
              key: 'debug',
              value:
              <Tooltip
                title={<I18n path={'AppCare.STATUS_PAGE.DEBUG'}/>}
              >
                <div>
                  <a href={getUrl(j.endpoint)} target='_blank' rel="noreferrer">
                    <Icon color={'white'} fontSize='small'>open_in_new</Icon>
                  </a>
                </div>
              </Tooltip>
            },
            {
              key: 'slo',
              value:
              <div className='w-15'>
                <span>{j.slo}%</span>
              </div>
            },
            {
              key: 'status',
              value:
              <Tooltip
                title={<I18n path={`AppCare.STATUS_PAGE.STATUS.${j.status?.toUpperCase()}`}/>}
              >
                <div className={`w-3 h-3 rounded-full ${j.status}`}>
                   
                </div>
              </Tooltip>
            },
          ]))
        }))

    if(newList?.length)
      setList(newList)

  }, [listComponents, selectOption])

  const getUrl = (value) => {
    const valor = value
    const resultado = valor?.[0].split(':')[0]

    const date = new Date()

    const url = `https://console.cloud.google.com/logs/query;query=%22${encodeURIComponent(resultado)}%22%0Aseverity!%3DINFO%0Aseverity!%3DNOTICE%0Aseverity!%3DDEFAULT%0Aresource.type%3D%22cloud_run_revision%22%20OR%20resource.type%3D%22gae_app%22%20OR%20resource.type%3D%22cloud_function%22;timeRange=P7D;cursorTimestamp=${date.toISOString()}?referrer=search&project=prd-1pay&supportedpurview=project`
    
    return url
  }

  const onCollapse = (id, isOpen) => {
    if (isOpen) {
      setSelectedRowId(id)
    }
  }

  const onCloseModal = () => {
    setShowCreate(false)
    setShowEditComponent(false)
    setShowDelete(false)
    setShowEditGroup(false)
    mutatePages()
    mutateGroup()
    mutateComponents()
    WTableRef.current?.clearCheckedValues()
  }

  const addNewComponent = () => {
    setShowCreate(!showCreate)
  }

  const editComponent = (ev) => {
    setRowSelectedId(ev)
    setShowEditComponent(!showEditComponent)
  }

  const saveComponent = async (data) => {
    await api({
      method: 'POST',
      url: `status-page/pages/${selectOption.id}/components`,
      data
    })
      .then(() => {
        showSuccess(<I18n path='AppCare.STATUS_PAGE.MESSAGE.SUCCESS'/>)
        onCloseModal()
      })
      .catch(error => {
        showResponseError(error)
        onCloseModal()
      })
  }

  const saveEditComponent = async (data) => {
    await api({
      method: 'PUT',
      url: `status-page/pages/${selectOption.id}/components/${data.id}`,
      data
    })
      .then(() => {
        showSuccess(<I18n path='AppCare.STATUS_PAGE.MESSAGE.EDIT'/>)
        onCloseModal()
      })
      .catch(error => {
        showResponseError(error)
        onCloseModal()
      })
  }

  const saveEditGroup = async (id) => {
    const data = {
      id: nameGroupEdit?.id,
      atlassianId: nameGroupEdit?.atlassianId,
      name: nameGroup
    }
    
    await api({
      method: 'PUT',
      url: `status-page/pages/${selectOption.id}/group/${id}`,
      data
    })
      .then(() => {
        showSuccess(<I18n path='AppCare.STATUS_PAGE.MESSAGE.EDIT'/>)
        onCloseModal()
      })
      .catch(error => {
        showResponseError(error)
        onCloseModal()
      })
  }

  const handleDelete = async (value) => {

    const url = value?.component ? `status-page/pages/${selectOption.id}/components/${value.component.id}` : `status-page/pages/${selectOption.id}/group/${value.group?.map(i => i.id)}`

    api({
      method: 'DELETE',
      url: url,
    })
      .then(() => {
        showSuccess(<I18n path='AppCare.STATUS_PAGE.MESSAGE.DELETE'/>)
        onCloseModal()
      })
      .catch(error => {
        showResponseError(error)
        onCloseModal()
      })

  }

  const deleteGroup = (value) => {
    
    setShowDelete(!showDelete)

    const nameGroup = listGroup?.data.filter(id => value.includes(id.id))

    setComponenteDelete({group: nameGroup})
  }

  const deleteComponent = (value) => {
    setShowDelete(!showDelete)
    const name = listComponents?.components.find(id => id.id === value)

    setComponenteDelete({component: name})
  }
  
  const editGroup = (value) => {
    setNameGroup('')
    setShowEditGroup(!showEditGroup)

    const newGroup = listGroup?.data.find(i => i.id === value[0])

    setNameGroupEdit(newGroup)
  }


  return(
    <div className='flex flex-col w-full max-h-full'>
      <div className='w-full h-full'>
        <div ref={modalRef}>
          {showCreate && listPages !== undefined && (
            <WModal
              openModal={showCreate}
              onCloseModal={onCloseModal}
            >
              {
                <div className='w-full h-full'>
                  <FormComponent
                    onSave={(ev) => saveComponent(ev)}
                    groups={listGroupsToSelect}
                  />
                </div>
              }
            </WModal>
          )}
        </div>
        <div>
          {showEditComponent&& (
            <WModal
              openModal={showEditComponent}
              onCloseModal={onCloseModal}
            >
              <div>
                <FormEditGroup
                  onSave={(ev) => saveEditComponent(ev)}
                  selectProject={selectOption}
                  groups={listGroupsToSelect}
                  id={rowSelectedId}
                  pageSelected={selectOption.id}
                />
              </div>
            </WModal>
          )}
        </div>
        <div>
          {showDelete && (
            <WCustomModal
              title={'Delete'}
              open={showDelete}
              handleClose={() => onCloseModal()}
              buttonLabel={'Delete'}
              buttonOnConfirm={() => handleDelete(componenteDelete)}
              element={
                <>
                  <div className='flex flex-row text-white'>
                    <span>Name: </span>
                    <ul>
                      {componenteDelete?.component?.name ||componenteDelete?.group?.map((i, index) => (
                        <>
                          <li key={index} className='ml-1'>
                            {i.name}
                          </li>
                        </>
                      ))
                      }
                    </ul>
                  </div>
                </>
              }
            />
          )
          }
        </div>
        <div>
          {showEditGroup && (
            <WCustomModal
              title={<I18n path='AppCare.STATUS_PAGE.EDIT_GROUP'/>}
              open={showEditGroup}
              handleClose={() => onCloseModal()}
              buttonLabel={'Save'}
              buttonOnConfirm={() => saveEditGroup(nameGroupEdit.id)}
              element={
                <>
                  <div className='flex w-full items-center border-b-[1px] border-orange h-10 mb-2 '>
                    <p className='text-white'>Group: {nameGroupEdit.name}</p>
                  </div>
                  <WTextFieldV2
                    label={'New Name Group'}
                    margin={'dense'}
                    value={nameGroup}
                    id={'input-name-component'}
                    onChange={ev => setNameGroup(ev.target.value)}
                    required={true}
                  />
                </>
              }
            />
          )
          }
        </div>
      </div>

      <div className='flex justify-center my-5 overflow-auto' >
        <div className='w-[100%]'>
          <WTable
            ref={WTableRef}
            isLoadingData={loadingPage || loadingGroup}
            headers={headers}
            rows={list}
            isCollapse={true}
            collapseHeaders={collapseHeaders}
            onCollapse={onCollapse}
            // onChangePage={onChangePage}
            countRows={count}
            optionsRowsPerPage={[]}
            limit={10}
            hiddenHeader={false}
            tableHeader={true}
            checkboxRows={true}
            // getCheckedValues={getRowsSelected}
            onAddNewRow={addNewComponent}
            onEditRow={editGroup}
            onDeleteRow={deleteGroup}
            selectTopMenu={select}
            permissionsTableHeader={permissionsTableHeader}
            onEditCollapseRow={(id) => editComponent(id)}
            onDeleteCollapseRow={(id) => deleteComponent(id)}
          />
        </div>
      </div>

    </div>
  )
}

export default StatusPage