import React, { createContext } from 'react'

import useAuth from './hooks/useAuth'

const Context = createContext()

const AuthProvider = ({ children }) => {
  const {
    user,
    globalLoading,
    authenticated,
    handleLogin,
    handleLogout,
    accessToken,
    refreshToken,
    permissions,
    currentProject,
    handleRefreshToken,
    handleChangeProject,
    allowedProjects,
    loadingProject
  } = useAuth()

  return (
    <Context.Provider value={{
      user,
      globalLoading,
      authenticated,
      handleLogin,
      handleLogout,
      accessToken,
      refreshToken,
      permissions,
      currentProject,
      handleRefreshToken,
      handleChangeProject,
      allowedProjects,
      loadingProject
    }}>
      {children}
    </Context.Provider>
  )
}

export { Context, AuthProvider }