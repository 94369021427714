import React from 'react'
import Status from './Status'

const Configurations = () => {
  return (
    <div className='mx-[2rem] h-full w-full flex lg:flex-nowrap flex-wrap'>
      <div className='flex w-96 h-96'>
        <Status />
      </div>
    </div>
  )
}

export default Configurations