import React, { useEffect, useRef, useState } from 'react'
import I18n from '../../../components/I18n'
import useApi from '../../../context/hooks/useApi'
import WSecrets from '../../../components/WSecrets'
import { v4 as uuidv4 } from 'uuid'
import WEnvironmentVariable, { IEnv, WEnvironmentVariableForwardRefProps } from '../../../components/WEnvironmentVariable'
import WGsutil, { GsUtilForwardRefProps, IGsutil } from '../../../components/WGsutil'
import api from '../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../helper'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import WSelectFieldV2 from '../../../components/WSelectField/V2'
import WHorizontalLinearStepper from '../../../components/WHorinzontalLinearStepper'
import WTextFieldV2 from '../../../components/WTextField/V2'
import WCheckbox from '../../../components/WCheckbox'
import { Icon } from '@mui/material'

interface Isecret {
  id: string
}

interface ISecrets {
  data: Isecret[]
  count: number
}

interface IRepository {
  id: string
  name: string
}

interface IRepositories {
  data: IRepository[]
  count: number
}

interface IAppEngine {
  name: string
  prefix: string
  coreRequired: boolean
  repository: IRepository
  envs: IEnv[]
  gsutil: IGsutil[]
}

interface IOptionsSelect {
  value: string
  label: string | JSX.Element
}

const AppEnginePage = () => {

  const [name, setName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [coreRequired, setCoreRequired] = useState(false)
  const [loadSecrets, setLoadSecrets] = useState<IEnv[]>([])
  const [oldListSecrets, setOldListSecrets] = useState<IEnv[]>([])
  const [loadEnvs, setLoadEnvs] = useState<IEnv[]>([])
  const [listGsutil, setListGsutil] = useState<IGsutil[]>([])
  const [isLoading, setIsloading] = useState(false)
  const [repository, setRepository] = useState<IOptionsSelect>()
  const [repositories, setRepositories] = useState<IOptionsSelect[]>([])
  const [errorFieldReporistory, setErrorFieldReporistory] = useState(false)

  const { data: dataAppEngine, isLoading: isLoadingAppEngine } = useApi<IAppEngine>({ url: 'app-engine' })
  const { data: dataSecrets } = useApi<ISecrets>({ url: 'secrets' })
  const { data: dataRepositories } = useApi<IRepositories>({ url: 'repositories'})
  
  const envsRef = useRef<WEnvironmentVariableForwardRefProps>(null)
  const gsutilRef = useRef<GsUtilForwardRefProps>(null)

  useEffect(() => {
    setName(dataAppEngine?.name ?? '')
    setPrefix(dataAppEngine?.prefix ?? '')
    setCoreRequired(dataAppEngine?.coreRequired ?? false)
    setLoadSecrets(dataAppEngine?.envs?.filter(env => env.secrets) ?? [])
    setOldListSecrets(dataAppEngine?.envs?.filter(env => env.secrets) ?? [])
    setLoadEnvs(dataAppEngine?.envs?.filter(env => !env.secrets) ?? [])
    setListGsutil(dataAppEngine?.gsutil ?? [])
    setRepository(repositories.find(i => i.value === dataAppEngine?.repository?.id))
  }, [dataAppEngine, repositories])

  useEffect(() => {
    if (dataRepositories) {
      const newRepos = dataRepositories.data?.map( i => ({ value: i.id, label: i.name }))
      setRepositories(newRepos)
    }
  }, [dataRepositories])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setName(value)
  }

  const onChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setPrefix(value)
  }

  const onChangeCoreRquired = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setCoreRequired(checked)
  }

  const onChnageRepository = (item: IOptionsSelect) => {
    setRepository(item)
  }

  const handlePushMoreSecret = () => {
    const newList = loadSecrets ? [...loadSecrets] : []
    setLoadSecrets([...newList, { id: uuidv4() }])
  }

  const handleSliceSecret = (item: IEnv) => {
    setLoadSecrets([...loadSecrets.filter(secret => item.id !== secret.id)])
  }

  const onUpdateSecrets = (item: IEnv) => {
    const newLoadSecrets = loadSecrets.map(i => ({
      ...item.id === i.id ? {
        ...i, ...item
      }
        :
        i
    }))

    setLoadSecrets(newLoadSecrets)
  }

  const handleSaveForm = async () => {
    setErrorFieldReporistory(!repository?.value.length)

    if (!repository?.value.length)
      return

    setIsloading(true)
    const envs = envsRef.current?.getEnvs() ?? []
    const gsutil = gsutilRef.current?.getGsutil() ?? []

    await api({
      method: 'PUT',
      url: 'app-engine',
      data: {
        name,
        prefix,
        coreRequired,
        gsutil,
        envs: [...loadSecrets, ...envs],
        repository: { id: repository.value }
      }
    })
      .then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => showResponseError(error))
      .finally(() => setIsloading(false))
  }

  const classNameReview = 'flex flex-col break-words'
  const classNameSpan = 'ml-2 bg-grey-900 bg-opacity-20'

  return (
    <div className='flex w-[70%] h-[90vh] bg-grey p-4'>
      <WHorizontalLinearStepper
        isLoading={isLoading}
        childrens={[
          {
            stepName: '',
            element:
              <div className='1st step flex flex-col w-full max-h-[70vh] text-white overflow-auto'>

                { (isLoading || isLoadingAppEngine) && <Loading /> }

                <div className='flex flex-col gap-y-10 p-4'>
                  <WTextFieldV2
                    label={<I18n path='Admin.APP_ENGINE.NAME' />}
                    value={name}
                    helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                    required
                    onChange={onChangeName}
                    variant='standard'
                  />
                  <WTextFieldV2
                    label={<I18n path='Admin.APP_ENGINE.PREFIX' />}
                    value={prefix}
                    helperText={<I18n path='Global.REQUIRED_FIELD'/>}
                    required
                    onChange={onChangePrefix}
                    variant='standard'
                  />
                  <WSelectFieldV2
                    label={<I18n path='Admin.APP_ENGINE.REPOSITORY'/>}
                    value={repository}
                    options={repositories}
                    errorText={errorFieldReporistory}
                    className={'w-full'}
                    onChange={onChnageRepository}
                    variant='standard'
                  />
                  <WCheckbox
                    label={<I18n path='Admin.APP_ENGINE.CORE_REQUIRED'/>}
                    checked={coreRequired}
                    onChange={onChangeCoreRquired}
                    defaultChecked
                    id='appEngineRequired'
                  />
                </div>
              </div>
          },
          {
            stepName: '',
            element:
              <div className='2nd step flex flex-col w-full max-h-[70vh] p-4 text-white overflow-auto'>
                <WSecrets
                  secrets={dataSecrets?.data}
                  loadSecrets={loadSecrets}
                  oldListSecrets={oldListSecrets}
                  onPushMoreSecret={handlePushMoreSecret}
                  onSliceSecret={handleSliceSecret}
                  onUpdate={onUpdateSecrets}
                />
              </div>
          },
          {
            stepName: '',
            element:
            <div className='3nd step flex flex-col w-full max-h-[70vh] p-4 text-white overflow-auto'>
              <WEnvironmentVariable
                ref={envsRef}
                loadEnvs={loadEnvs}
              />
            </div>
          },
          {
            stepName: '',
            element:
            <div className='4nd step flex flex-col w-full max-h-[70vh] p-4 text-white overflow-auto'>
              <WGsutil
                ref={gsutilRef}
                loadCopies={listGsutil}
                valuePrefix={prefix}
              />
            </div>
          }
        ]}
        finishStep={
          <div className='flex flex-col gap-y-4 overflow-auto'>
            <h1 className='text-center text-lg subpixel-antialiased	'>
              <I18n path='Global.CREATE'/>{}
            </h1>
            <p className={classNameReview}>
              {<I18n path='Admin.APP_ENGINE.NAME'/>}:
              <span className={classNameSpan}>{name}</span>
            </p>
            <p className={classNameReview}>
              {<I18n path='Admin.APP_ENGINE.PREFIX'/>}:
              <span className={classNameSpan}>{prefix}</span>
            </p>
            <p className={classNameReview}>
              {<I18n path='Admin.APP_ENGINE.REPOSITORY'/>}:
              <span className={classNameSpan}>{repository?.label}</span>
            </p>
            <p className='flex items-center'>
              {<I18n path='Application.PROJECTS.MODAL.IS_PRODUCTION'/>}:
              <Icon
                data-status={coreRequired}
                className={`
                        ml-2 
                      data-[status=true]:text-green
                      data-[status=false]:text-red
                    `}>
                {coreRequired ? 'check' : 'close'}</Icon>
            </p>
          </div>
        }
        onSaveClick={handleSaveForm}
        iconButton='done'
        type={'Save'}
      />
    </div>
  )
}

export default AppEnginePage