import React, { useEffect, useRef, useState } from 'react'
import I18n from '../../../../components/I18n'
import WSelectFieldV2 from '../../../../components/WSelectField/V2'
import useApi from '../../../../context/hooks/useApi'
import WSecrets from '../../../../components/WSecrets'
import { v4 as uuidv4 } from 'uuid'
import WEnvironmentVariable from '../../../../components/WEnvironmentVariable'
import WGsutil from '../../../../components/WGsutil'
import WRadioGroupAuthentication from '../../../../components/WRadioGroupAuthentication'
import WHorizontalLinearStepper from '../../../../components/WHorinzontalLinearStepper'
import WTextFieldV2 from '../../../../components/WTextField/V2'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import api from '../../../../context/apiContext'
import WCheckbox from '../../../../components/WCheckbox'

const listMemory = [
  { label: '128Mi', value: '128Mi' },
  { label: '256Mi', value: '256Mi' },
  { label: '512Mi', value: '512Mi' },
  { label: '1Gi', value: '1Gi' },
  { label: '2Gi', value: '2Gi' },
  { label: '4Gi', value: '4Gi' },
  { label: '8Gi', value: '8Gi' },
  { label: '16Gi', value: '16Gi' },
  { label: '32Gi', value: '32Gi' },
]

const listCpu = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '4', value: 4 },
  { label: '6', value: 6 },
  { label: '8', value: 8 },
]

const listRegions = [
  { label: 'us-east1', value: 'us-east1' },
  { label: 'us-central1', value: 'us-central1' }
]

const FormData = (props) => {
  const {
    id,
    isLoading,
    onClose
  } = props

  const [newCloudRun, setNewCloudRun] = useState('')
  const [newMemory, setNewMemory] = useState(listMemory[0])
  const [repository, setRepository] = useState({})
  const [repositories, setRepositories] = useState([])
  const [newTimeout, setNewTimeout] = useState(60)
  const [errorFieldCloudRun, setErrorFieldCloudRun] = useState('')
  const [errorFieldMemory, setErrorFieldMemory] = useState('')
  const [errorFieldRegion, setErrorFieldRegion] = useState('')
  const [errorFieldCpu, setErrorFieldCpu] = useState('')
  const [errorFieldTimeout, setErrorFieldTimeout] = useState('')
  const [errorFieldReporistory, setErrorFieldReporistory] = useState('')
  const [region, setRegion] = useState(listRegions[0])
  const [maxInstances, setMaxInstances] = useState(100)
  const [loadSecrets, setLoadSecrets] = useState([])
  const [oldListSecrets, setOldListSecrets] = useState([])
  const [loadEnvs, setLoadEnvs] = useState([])
  const [newConcurrency, setNewConcurrency] = useState(80)
  const [minInstances, setMinInstances] = useState(0)
  const [newCpu, setNewCpu] = useState(listCpu[0])
  const [authenticationRequired, setAuthenticationRequired] = useState(false)
  const [listGsutil, setListGsutil] = useState([])
  const [prefix, setPrefix] = useState('')
  const [cloudSqlInstances, setCloudSqlInstances] = useState('')
  const [isLoadingButton, setIsLoadingButton] = useState()
  const [coreRequired, setCoreRequired] = useState(false)
  const [defaultDeployment, setDefaultDeployment] = useState(false)
  const [vpcConnector, setVpcConnector] = useState('')


  const gsutilRef = useRef()
  const envsRef = useRef()
  const authenticationRef = useRef()

  const { data: dataSecrets } = useApi({ url: 'secrets' })
  const { data: dataRepositories } = useApi({ url: 'repositories' })
  const {data: dataCloudRunById} = useApi({url: id ? `cloud-run/${id}` : ''})

  const clearErrors = () => {
    setErrorFieldCloudRun('')
    setErrorFieldMemory('')
    setErrorFieldRegion('')
    setErrorFieldCpu('')
    setErrorFieldTimeout('')
    setErrorFieldReporistory('')
  }

  useEffect(() => {
    if (dataRepositories) {
      const newRepos = dataRepositories.data?.map( i => ({ value: i.id, label: i.name }))
      setRepositories(newRepos)
    }
  }, [dataRepositories])

  useEffect(() => {
    if (dataCloudRunById) {
      setNewCloudRun(dataCloudRunById.name)
      setMinInstances(dataCloudRunById.minInstances)
      setMaxInstances(dataCloudRunById.maxInstances)
      setNewMemory(listMemory.find(i => i.value === dataCloudRunById.memory))
      setRegion(listRegions.find(i => i.value === dataCloudRunById.region))
      setAuthenticationRequired(dataCloudRunById.authenticationRequired)
      setLoadSecrets(dataCloudRunById.envs.filter(env => env.secrets))
      setOldListSecrets(dataCloudRunById.envs.filter(env => env.secrets))
      setLoadEnvs(dataCloudRunById.envs.filter(env => !env.secrets))
      setNewTimeout(dataCloudRunById.timeout)
      setNewConcurrency(dataCloudRunById.concurrency)
      setNewCpu(listCpu.find(i => i.value === dataCloudRunById.cpu))
      setListGsutil(dataCloudRunById.gsutil)
      setPrefix(dataCloudRunById.prefix)
      setCloudSqlInstances(dataCloudRunById.cloudSqlInstances)
      setCoreRequired(dataCloudRunById.coreRequired)
      setDefaultDeployment(dataCloudRunById.defaultDeployment)
      setVpcConnector(dataCloudRunById.vpcConnector)

      if (dataCloudRunById.repository)
        setRepository({ value: dataCloudRunById.repository.id, label: dataCloudRunById.repository.name })
    }
  }, [dataCloudRunById])

  const validateFields = () => {
    clearErrors()

    let errorCloudRun = ''
    let errorMemory = ''
    let errorTimeout = ''
    let errorProject = ''
    let errorRegion = ''
    let errorMinInstances = ''
    let errorCpu = ''
    let errorRepository = ''

    if (!newCloudRun?.length)
      errorCloudRun = 'Required Name'

    if (!newMemory || !listMemory.find(i => i.value === newMemory.value))
      errorMemory = 'Memory invalid'

    if (!newTimeout)
      errorTimeout= 'Timeout required'

    if (!region?.value)
      errorRegion = 'Region required'

    if (!newCpu?.value)
      errorCpu = 'CPU required'

    if (!repository)
      errorRepository = 'Repository required'

    setErrorFieldCloudRun(errorCloudRun)
    setErrorFieldMemory(errorMemory)
    setErrorFieldRegion(errorRegion)
    setErrorFieldCpu(errorCpu)
    setErrorFieldTimeout(errorTimeout)
    setErrorFieldReporistory(errorRepository)

    const containsError = (
      !errorCloudRun.length &&
      !errorMemory.length &&
      !errorTimeout.length &&
      !errorProject.length &&
      !errorRegion.length &&
      !errorCpu.length &&
      !errorRepository.length
    )

    return containsError
  }

  const handleInputTimeout = (ev) => {
    const target = ev.target
    const newValue = Number(target?.value)
    setNewTimeout(newValue)
  }

  const handleSaveForm = () => {
    if (!validateFields())
      return

    const envs = envsRef.current?.getEnvs() ?? []
    const gsutil = gsutilRef.current.getGsutil() ?? []

    const data = {
      name: newCloudRun,
      concurrency: newConcurrency,
      memory: newMemory?.value,
      timeout: newTimeout,
      repository: repository?.value,
      region: region?.value,
      minInstances: minInstances,
      maxInstances: maxInstances,
      cpu: newCpu?.value,
      authenticationRequired: !!authenticationRef.current?.getValue(),
      envs: [...loadSecrets, ...envs],
      gsutil: gsutil,
      prefix: prefix,
      cloudSqlInstances,
      coreRequired,
      defaultDeployment,
      vpcConnector,
    }

    if(id){
      handleUpdateCloudRun(data)
    } else {
      handleCreateCloudRun(data)
    }
    setOldListSecrets(data.envs)
  }

  const handleCreateCloudRun = (data) => {

    setIsLoadingButton(true)
    const {
      name,
      memory,
      timeout,
      repository,
      region,
      minInstances,
      maxInstances,
      envs,
      concurrency,
      cpu,
      authenticationRequired,
      gsutil,
      prefix,
      cloudSqlInstances,
      coreRequired,
      defaultDeployment,
      vpcConnector
    } = data

    api({
      method: 'POST',
      url: 'cloud-run',
      data: {
        name,
        memory,
        timeout,
        repository,
        region,
        minInstances,
        maxInstances,
        envs,
        concurrency,
        cpu,
        authenticationRequired,
        gsutil,
        prefix,
        cloudSqlInstances,
        coreRequired,
        defaultDeployment,
        vpcConnector
      }
    }).then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => showResponseError(error))
      .finally(() => {
        onClose(false)
        setIsLoadingButton(false)
      })
  }

  const handleUpdateCloudRun = (data) => {

    setIsLoadingButton(true)
    const {
      name,
      concurrency,
      memory,
      timeout,
      repository,
      region,
      minInstances,
      maxInstances,
      cpu,
      authenticationRequired,
      envs,
      gsutil,
      prefix,
      cloudSqlInstances,
      coreRequired,
      defaultDeployment,
      vpcConnector
    } = data

    api({
      method: 'PUT',
      url: `cloud-run/${id}`,
      data: {
        name,
        memory,
        timeout,
        repository,
        region,
        minInstances,
        maxInstances,
        envs,
        concurrency,
        cpu,
        authenticationRequired,
        gsutil,
        prefix,
        cloudSqlInstances,
        coreRequired,
        defaultDeployment,
        vpcConnector
      }
    }).then(({ data }) => showResponseSuccess(data?.message))
      .catch(error => showResponseError(error))
      .finally(() => {
        onClose(false)
        setIsLoadingButton(false)
      })
  }

  const onUpdateSecrets = (data) => {
    const newLoadSecrets = loadSecrets.map(i => ({
      ...data.id === i.id ? {
        ...i, ...data
      }
        :
        i
    }))

    setLoadSecrets(newLoadSecrets)
  }

  return (
    <div>
      <>
        <WHorizontalLinearStepper
          isLoading={isLoading}
          childrens={[
            {
              stepName: '',
              element:
            <div className='1st step h-[30em]'>
              <div className='flex items-center justify-center text-white overflow-auto'>
                <div className='container'>

                  <div className='flex flex-col h-full'>
                    <p className='text-orange text-xl'>Capacity</p>
                    <div className='flex flex-row items-center space-x-4 mt-5'>
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={newCloudRun}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.NAME'/>}
                        required
                        error={newCloudRun.length === 0}
                        helperText={'Invalid Value' + errorFieldCloudRun}
                        onChange={ev => setNewCloudRun(ev.target.value)}
                        variant='standard'
                      />
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={newConcurrency}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.CONCURRENCY'/>}
                        required
                        error={newConcurrency.length === 0 || isNaN(newConcurrency) || newConcurrency > 1000 }
                        helperText={newConcurrency.length === 0 || isNaN(newConcurrency) ? 'Invalid value' : 'Maximum Value 1000'}
                        onChange={ev => setNewConcurrency(ev.target.value)}
                        variant='standard'
                      />
                    </div>
                  </div>

                  <div className='flex flex-row items-center space-x-4 mt-10'>
                    <WSelectFieldV2
                      label={<I18n path='Admin.CLOUD_RUN.TABLE.MEMORY'/>}
                      className='w-full border-b border-transparent border-b-[#a73804] text-white'
                      required
                      placeholder=''
                      errorText={errorFieldMemory}
                      value={newMemory}
                      onChange={ev => setNewMemory(ev)}
                      options={listMemory}
                    />
                    <WSelectFieldV2
                      label={<I18n path='Admin.CLOUD_RUN.TABLE.CPU'/>}
                      className='w-full border-b border-transparent border-b-[#a73804] text-white'
                      required
                      placeholder=''
                      errorText={errorFieldCpu}
                      value={newCpu}
                      onChange={ev => setNewCpu(ev)}
                      options={listCpu}
                    />
                  </div>

                  <div className='flex flex-column space-x-4 mt-10'>
                    <div className='flex flex-row space-x-4'>
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={minInstances}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.MIN_INSTANCES'/>}
                        required
                        error={minInstances > 2147483647 || isNaN(minInstances)}
                        helperText={isNaN(minInstances) ? 'Invalid Value': 'Minimum instances required'}
                        onChange={ev => setMinInstances(parseInt(ev.target.value))}
                        variant='standard'
                      />
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={maxInstances}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.MAX_INSTANCES'/>}
                        required
                        error={maxInstances === 0 || maxInstances > 2147483647 || isNaN(maxInstances)}
                        helperText={isNaN(maxInstances) ? 'Invalid Value' : 'Maximum instances required'}
                        onChange={ev => setMaxInstances(parseInt(ev.target.value))}
                        variant='standard'
                      />
                    </div>
                  </div>
                  <div className='flex flex-column space-x-4 mt-10'>
                    <div className='flex flex-row space-x-4'>
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={vpcConnector}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.VPC_CONNECTOR'/>}
                        onChange={ev => setVpcConnector(ev.target.value)}
                        variant='standard'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            },
            {
              stepName: '',
              element:
                <div className='2nd step h-[30em]'>
                  <div className='text-white'>

                    <div className='flex w-full'>
                      <WRadioGroupAuthentication
                        ref={authenticationRef}
                        defaultValue={authenticationRequired}
                      />

                    </div>
                    <WCheckbox
                      i18n={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.CORE_REQUIRED'/>}
                      defaultChecked
                      checked={coreRequired}
                      onChange={ev => setCoreRequired(ev.target.checked)}
                    />
                    <WCheckbox
                      i18n={<I18n path='Admin.CLOUD_FUNCTIONS.TABLE.DEFAULT_DEPLOYMENT'/>}
                      checked={defaultDeployment}
                      onChange={ev => setDefaultDeployment(ev.target.checked)}
                    />

                    <div className='flex flex-row items-center space-x-4 mt-4'>
                      <WSelectFieldV2
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.REGION'/>}
                        className='w-full border-b border-transparent border-b-[#a73804] text-white'
                        required
                        placeholder=''
                        errorText={errorFieldRegion}
                        value={region}
                        onChange={ev => setRegion(ev)}
                        options={listRegions}
                      />
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={newTimeout}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.TIMEOUT'/>}
                        required
                        error={newTimeout === 0 ||newTimeout > 3600 || isNaN(newTimeout)}
                        helperText={isNaN(newTimeout) ? 'Invalid Value' : errorFieldTimeout}
                        onChange={handleInputTimeout}
                        variant='standard'
                      />
                    </div>

                    <div className='flex flex-row items-center space-x-4 mt-4'>
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={prefix}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.PREFIX'/>}
                        onChange={ev => setPrefix(ev.target.value)}
                        variant='standard'
                      />
                      <WSelectFieldV2
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.REPOSITORY'/>}
                        className='w-full border-b border-transparent border-b-[#a73804] text-white'
                        required
                        placeholder=''
                        errorText={errorFieldReporistory}
                        value={repository}
                        onChange={ev => setRepository(ev)}
                        options={repositories}
                      />
                    </div>

                    <div className='flex flex-row items-center space-x-4 mt-4'>
                      <WTextFieldV2
                        className='border-b border-transparent border-b-[#fc673d] text-white w-full'
                        value={cloudSqlInstances}
                        margin={'dense'}
                        label={<I18n path='Admin.CLOUD_RUN.TABLE.CLOUD_SQL'/>}
                        onChange={ev => setCloudSqlInstances(ev.target.value)}
                        variant='standard'
                      />
                    </div>
                  </div>
                </div>
            },
            {
              stepName: '',
              element:
                <div className='3nd step min-h-[30em]'>
                  <div className='overflow-auto text-white'>
                    <WSecrets
                      secrets={dataSecrets?.data}
                      loadSecrets={loadSecrets}
                      oldListSecrets={oldListSecrets}
                      onPushMoreSecret={() => setLoadSecrets([...loadSecrets, { id: uuidv4() }])}
                      onSliceSecret={item => setLoadSecrets([...loadSecrets.filter(secret => item.id !== secret.id)])}
                      onUpdate={onUpdateSecrets}
                    />
                  </div>
                </div>
            },
            {
              stepName: '',
              element:
                <div className='4nd step min-h-[30em]'>
                  <div className='overflow-auto text-white'>
                    <WEnvironmentVariable
                      ref={envsRef}
                      loadEnvs={loadEnvs}
                    />
                  </div>
                </div>
            },
            {
              stepName: '',
              element:
                <div className='5nd step min-h-[30em]'>
                  <div className='overflow-auto text-white'>
                    <WGsutil
                      ref={gsutilRef}
                      loadCopies={listGsutil}
                      valuePrefix={prefix}
                    />
                  </div>
                </div>
            },
          ]}
          finishStep={
            <div className='h-[31em]'>
              <h1 className='text-center text-lg subpixel-antialiased	mb-2'>
                <I18n path='Global.CREATE'/>
              </h1>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.NAME'/>: {newCloudRun}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.CONCURRENCY'/>: {newConcurrency}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.MEMORY'/>: {newMemory?.label}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.CPU'/>: {newCpu?.label}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.MIN_INSTANCES'/>: {minInstances}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.MAX_INSTANCES'/>: {maxInstances}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.REGION'/>: {region?.label}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.TIMEOUT'/>: Max {newTimeout}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.PREFIX'/>: {prefix}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.REPOSITORY'/>: {repository?.label}
              </p>
              <p className='flex-col'>
                <I18n path='Admin.CLOUD_RUN.TABLE.CLOUD_SQL'/>: {cloudSqlInstances}
              </p>

            </div>
          }
          onSaveClick={handleSaveForm}
          isLoadingButton={isLoadingButton}
          iconButton={id ? 'edit' : 'add_circle'}
          type={id ? 'Update' : 'Create' }
        />
      </>
    </div>

  )
}

export default FormData