import React, { useState } from 'react'
import { IProject } from './FormData'
import api from '../../../../context/apiContext'
import { KeyedMutator } from 'swr'
import { IProjects } from '..'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'

interface IDelProject {
  onCloseModal: () => void
  projects: IProject[]
  mutate: KeyedMutator<IProjects>
}

const DelProject = (props: IDelProject) => {
  const {
    onCloseModal,
    projects,
    mutate
  } = props

  const [isLoading, setIsloading] = useState(false)

  const handleSave = async () => {
    setIsloading(true)
    await api({
      method: 'DELETE',
      url: 'project',
      params: {
        ids: projects.map(i => i.id).join(',')
      }
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
      .finally(() => setIsloading(false))
  }

  return (
    <WCustomModal
      buttonLabel={<I18n path='Application.PROJECTS.MODAL.DELETE.BTN_SAVE'/>}
      title={<I18n path='Application.PROJECTS.MODAL.DELETE.TITLE' variables={{ count: projects.length }}/>}
      open={true}
      handleClose={onCloseModal}
      loading={isLoading}
      buttonOnConfirm={handleSave}
      icon={'delete'}
      element={
        <div className='flex flex-col w-80 h-72 justify-center items-center gap-4 p-2 text-white'>
          {
            projects?.map((i, index) => (
              <p key={index} className='border-b border-orange'>{i.name}</p>
            ))
          }
        </div>
      }
    />
  )
}

export default DelProject