import React from 'react'
import Icon from '@mui/material/Icon'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'

const DebugUrl = ({ url, tooltip, disabled }) => {
  const style = {
    ...url ?
      {
        cursor: 'pointer'
      }
      :
      {
        opacity: 0.4
      }
  }

  const redirect = () => {
    if (!url)
      return

    window.open(url, '_blank')
  }

  return (
    <div className='d-flex align-items-center'>
      <Tooltip
        tooltip={tooltip}
        tooltipStyle={{ fontSize: '12px' }}
        className='flex text-white transition hover:text-orange hover:delay-50'
      >
        <Icon
          style={style}
          onClick={redirect}
          fontSize='small'
          className={disabled ? 'text-grey-500' : 'transition hover:text-orange hover:delay-50'}
        >pest_control</Icon>
      </Tooltip>
    </div>
  )
}

export default DebugUrl