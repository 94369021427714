import React from 'react'
import { Avatar, AvatarGroup, Tooltip, styled } from '@mui/material'

interface WGroupAvatarProps {
  max: number
  avatar: []
  label: []
  className: string
  size?: number | string
  spacing?: 'small' | 'medium' | number
  variant?: 'circular' | 'rounded' | 'square'
}

const CssGroupAvatar = styled(AvatarGroup)({
  '.MuiAvatarGroup-root': {
    dispay: 'flex',
    alignItems: 'center',
  },
  
  '.MuiAvatarGroup-avatar': {
    dispay: 'flex',
    alignItems: 'center',
    width: '2rem',
    height: '2rem'
  },

  '.MuiAvatar-root': {
    dispay: 'flex',
    alignItems: 'center',
  },
})

const WGroupAvatar = (props: WGroupAvatarProps) => {

  return (
    <div className=''>
      <div className=''>
        <Tooltip
          disableHoverListener={props.label?.length > 3 ? false : true}
          arrow={true}
          enterDelay={300}
          enterNextDelay={300}
          enterTouchDelay={300}
          title={
            <>
              {props.label?.slice(2).map(name => (
                <p key={name}>
                  {name}
                </p>
              ))}
            </>
          }
        >
          <div>
            <CssGroupAvatar
              max={3}
              total={props.label?.length}
              spacing={props.spacing || 'medium'}
              variant={props.variant || 'circular'}
              className='flex justify-center items-center text-sm'
            >
              {
                props.label?.map((i, index) => (
                  <div key={`${i}-${index}`} className='flex justify-center items-center'>
                    <Tooltip
                      title={index <= 3 ? props.label?.[index] : ''}
                      enterDelay={400}
                      id={i}
                    >
                      <Avatar
                        key={index}
                        src={props.avatar?.[index]}
                        sx={{width: props.size , height: props.size }}
                      />
                    </Tooltip>
                  </div>
                ))
              }
            </CssGroupAvatar>
          </div>
        </Tooltip>
      </div>

    </div>
  )
}

export default WGroupAvatar