import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import WButton from '../WButton/Icon'
import WKeyValue from './WKeyValue'
import { v4 as uuidv4 } from 'uuid'

export interface IListItems {
  id: string
  key?: string
  value?: string
}

interface IListWKeyValue {
  loadKeyValue: IListItems[]
  title: string | JSX.Element
  btnLabelMore: string | JSX.Element
  keyLabel: string | JSX.Element
  valueLabel: string | JSX.Element
  keyPrefix?: string | JSX.Element,
  valuePrefix?: string | JSX.Element,
}

export type WListKeyValueForwardRef = {
  getListItems: () => IListItems[]
}

const WListkeyValue = forwardRef<WListKeyValueForwardRef, IListWKeyValue>((props, ref) => {
  const {
    loadKeyValue,
    title,
    keyLabel,
    valueLabel,
    keyPrefix,
    valuePrefix,
  } = props

  const [listItems, setListItems] = useState<IListItems[]>([])

  useEffect(() => {
    setListItems(loadKeyValue)
  }, [loadKeyValue])

  const onChangeProps = (index: number , data: IListItems) => {
    const newList = listItems.map((i, _index) => ({
      ...index === _index ? {
        ...i, ...data
      }
        :
        i
    }))

    setListItems(newList)
  }

  const handlePushMoreEnv = () => {
    setListItems([...listItems].concat({ id: uuidv4(), key: '', value: '' }))
  }

  const handleSliceEnv = (index: number) => {
    setListItems([...listItems.filter((_, _index) => index !== _index)])
  }

  const getListItems = () => listItems

  useImperativeHandle(ref, () => ({
    getListItems
  }), [listItems])

  return (
    <div>
      <div>{title}</div>
      {
        listItems?.map((i, index) => (
          <WKeyValue
            id={i.id}
            keyLabel={keyLabel}
            valueLabel={valueLabel}
            keyPrefix={keyPrefix}
            valuePrefix={valuePrefix}
            key={i.id}
            defaultKey={i.key}
            defaultValue={i.value}
            onChangeProps={(data) => onChangeProps(index, data)}
            onSlice={() => handleSliceEnv(index)}
          />
        ))
      }
      <WButton
        icon='add'
        iconColor='#fc673d'
        onClick={() => handlePushMoreEnv()}
      />
    </div>
  )
})

WListkeyValue.displayName = 'WListkeyValue'
export default WListkeyValue