
import { Checkbox, Collapse, Icon, IconButton, Table, TableBody, TableHead } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect, Fragment } from 'react'
import { CONSTANTS } from '../../../js/res/CONSTANTS'
import I18n from '../../I18n'
import { StyledTableCell, StyledTablePagination, StyledTableRow, StyledTableRowPagination } from './commons'
import { WCell } from './WCell'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import WCellSearchable from './WCellSearchable'
import WIconButton from '../../WButton/Icon'

export const WRow = props => {
  const {
    row,
    headers,
    isCollapse,
    collapseHeaders,
    isSelected,
    selected,
    onChangeSelecteds,
    onCollapse,
    handleChangePageRow,
    handleChangeRowsPerPageRow,
    limitCollapse,
    orderCollapse,
    orderByCollapse,
    onChangeSortCollapse,
    onChangeFiltersCollapse,
    onEditCollapseRow,
    onDeleteCollapseRow
  } = props

  const [open, setOpen] = useState(false)
  const isItemSelected = isSelected(row.key)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(limitCollapse ?? 10)
  const [filters, setFilters] = useState([])

  const onSearch = search => {
    const newFilters = filters?.filter(i => i.key !== search.key).concat({
      key: search.key,
      operator: search.operator,
      value: search.value,
    }).filter(i => typeof i.value !== 'undefined' && i.value !== null)

    setFilters(newFilters)
  }

  useEffect(() => {
    if (typeof onChangeFiltersCollapse === 'function')
      onChangeFiltersCollapse(filters)

  }, [filters])

  const onCollapseRow = () => {
    const collapseRowId = row?.key || row?.cells[0]?.value
    if (typeof onCollapse === 'function')
      onCollapse(collapseRowId, open)
  }

  useEffect(() => {
    onCollapseRow()
  }, [open])

  const handleClick = (event, key) => {
    if (!row?.isSelectable)
      return

    const selectedIndex = selected.indexOf(key)

    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    onChangeSelecteds(newSelected)
  }

  const colSpan = (headers?.length + (row.isSelectable ? 1 : 0 ) + (isCollapse ? 1 : 0))

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleEditRow = (value) => {
    if (typeof onEditCollapseRow === 'function')
      onEditCollapseRow(value)
  }

  const handleDeleteRow = (value) => {
    if (typeof onDeleteCollapseRow === 'function')
      onDeleteCollapseRow(value)
  }

  useEffect(() => {
    if (open && typeof handleChangePageRow === 'function')
      handleChangeRowsPerPageRow(row.key, rowsPerPage)
  }, [rowsPerPage])

  useEffect(() => {
    if (open && typeof handleChangePageRow === 'function')
      handleChangePageRow(row.key, page)
  }, [page, open])

  return (
    <Fragment>
      <StyledTableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        hover={false}
        onClick={(event) => handleClick(event, row.key)}
        role='checkbox'
        tabIndex={-1}
        key={row.key}
        selected={isItemSelected}
      >
        {
          row.isSelectable ?
            <StyledTableCell animationClass={row?.animationClass} padding='checkbox'>
              <Checkbox
                style={{
                  color: CONSTANTS.Style.White
                }}
                checked={isItemSelected}
              />
            </StyledTableCell>
            :
            <></>
        }
        {
          isCollapse ?
            <StyledTableCell animationClass={row?.animationClass}>
              <IconButton
                size='small'
                style={{
                  color: CONSTANTS.Style.White
                }}
                onClick={() => {
                  setOpen(!open)
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            :
            <></>
        }
        {
          headers?.filter(i => !i.hidden).map(header => (
            <>
              <WCell
                animationClass={row?.animationClass}
                key={header.key}
                cell={row?.cells.find(cell => cell.key === header.key)}
              />
            </>
          ))
        }
      </StyledTableRow>
      {
        isCollapse ?
          <StyledTableRow>
            <StyledTableCell
              animationClass={row?.animationClass}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={colSpan}
            >
              <Collapse in={open} timeout={open ? 'auto' : 0} unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size='small' stickyHeader>
                    <TableHead>
                      <StyledTableRow hover={false}>
                        {
                          collapseHeaders?.filter(i => !i.hidden).map((header, index) => (
                            <>
                              {
                                header.key ?
                                  <>
                                    {
                                      header.key ?
                                        <WCell
                                          animationClass={row?.animationClass}
                                          key={`${header.key}_${index}`}
                                          cell={header}
                                          order={orderCollapse}
                                          orderBy={orderByCollapse}
                                          onRequestSort={onChangeSortCollapse}
                                        />
                                        :
                                        <StyledTableCell animationClass={row?.animationClass} />
                                    }
                                  </>
                                  :
                                  <StyledTableCell animationClass={row?.animationClass}/>
                              }
                            </>
                          ))
                        }
                        {
                          (onEditCollapseRow || onDeleteCollapseRow) &&
                          <WCell
                            cell={{
                              key: 'actions',
                              value: <div className='flex items-center'>
                                <Icon color={'white'} fontSize='small'>settings_suggest</Icon>
                                <span className='ml-2'>
                                  {<I18n path='Global.ACTIONS' />}
                                </span>
                              </div>
                            }}
                          />
                        }
                      </StyledTableRow>
                      {
                        collapseHeaders?.some(i => i.searchable) &&
                        <StyledTableRow>
                          {
                            collapseHeaders?.filter(i => !i.hidden).map(header => (
                              <>
                                {
                                  header.searchable ?
                                    <WCellSearchable
                                      cell={header}
                                      onSearch={onSearch}
                                    />
                                    :
                                    <StyledTableCell animationClass={row?.animationClass} />
                                }
                              </>
                            ))
                          }
                          <WCell
                            cell={{
                              key: 'actions',
                              value: 'actions'
                            }}
                          />
                        </StyledTableRow>
                      }
                    </TableHead>
                    <TableBody>
                      {
                        row.collapseValues?.map((collapseValue, index) => (
                          <>
                            <StyledTableRow key={index} hover={true}>
                              {
                                collapseHeaders?.filter(i => !i.hidden).map((header, index) => (
                                  <WCell
                                    className={'bg-none hover:bg-blue'}
                                    animationClass={row?.animationClass}
                                    key={`${header.key}_${index}`}
                                    cell={collapseValue.find(cell => cell.key === header.key)}
                                  />
                                ))
                              }
                              {
                                (onEditCollapseRow || onDeleteCollapseRow) &&
                                <WCell
                                  animationClass={row?.animationClass}
                                  cell={{
                                    align: 'center',
                                    key: 'actions',
                                    sortable: false,
                                    value: <>
                                      {
                                        onEditCollapseRow &&
                                        <WIconButton
                                          iconColor='#a73804'
                                          onClick={() => handleEditRow(collapseValue.find(i => i.key === 'id')?.value)}
                                          icon='edit'
                                          size='small'
                                        />
                                      }
                                      {
                                        onDeleteCollapseRow &&
                                        <WIconButton
                                          iconColor='#a73804'
                                          onClick={() => handleDeleteRow(collapseValue.find(i => i.key === 'id')?.value)}
                                          icon='delete'
                                          size='small'
                                        />
                                      }
                                    </>
                                  }}
                                />
                              }
                            </StyledTableRow>
                          </>
                        ))
                      }
                      <StyledTableRowPagination>
                        <StyledTablePagination
                          rowsPerPageOptions={row?.optionsRowsPerPage ?? [10, 25, 100]}
                          labelRowsPerPage={<I18n path='COMPONENTS.WTABLE.FOOTER.ROWS_PER_PAGE'/>}
                          count={row?.totalRows}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          showFirstButton={true}
                          showLastButton={true}
                        />
                      </StyledTableRowPagination>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </StyledTableCell>
          </StyledTableRow>
          :
          <></>
      }
    </Fragment>
  )
}