import React from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import FormDataProject, { ISaveFormData } from './FormData'
import { KeyedMutator } from 'swr'
import { IProjects } from '..'

interface IAddProject {
  onCloseModal: () => void
  mutate: KeyedMutator<IProjects>
}

const AddProject = (props: IAddProject) => {
  const {
    onCloseModal,
    mutate,
  } = props

  const handleSave = async (data: ISaveFormData) => {

    await api({
      method: 'POST',
      url: 'project',
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
  }

  return (
    <FormDataProject
      onCloseModal={onCloseModal}
      onSaveForm={handleSave}
    />
  )
}

export default AddProject