import React from 'react'
import { showInfo } from '../../../helper'
import Icon from '@mui/material/Icon'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'

const ButtonUrlBuild = props => {
  const {
    isAppEngine,
    disabled,
    url
  } = props

  const redirect = () => {
    if(!url)
      return

    navigator.clipboard.writeText(url)
    showInfo('Global.COPIED_LINK')
  }

  return (
    <div className='d-flex align-items-center'>

      {isAppEngine?
        <Tooltip
          tooltip={url ? isAppEngine[0].toUpperCase() + isAppEngine.slice(1) : ''}
          tooltipStyle={{ fontSize: '12px' }}
          className='flex text-white transition hover:text-orange hover:delay-50 cursor-pointer'
        >
          <Icon
            className={disabled ? 'text-grey-500' : 'transition hover:text-orange hover:delay-50'}
            iconColor='var(--White)'
            type='icon'
            tooltipLabel={isAppEngine}
            onClick={redirect}
            fontSize='small'
          >content_copy</Icon>
        </Tooltip>
        :
        <></>
      }
      
    </div>
  )
}

export default ButtonUrlBuild