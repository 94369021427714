import React from 'react'
import { Icon } from '@mui/material'

export const WCellValue = props => {
  const {
    cell
  } = props

  if (!cell)
    return

  return (

    <div
      className={`flex items-center justify-${cell.align ?? 'center'} h-6`}
    >
      {
        cell.startIcon &&
        <>
          {
            typeof cell.startIcon === 'object' ?
              <>{ cell.startIcon }</>
              :
              <Icon>{cell.startIcon}</Icon>
          }
        </>
      }
      <>{cell.value}</>
      {
        cell.endIcon &&
        <>
          {
            typeof cell.endIcon === 'object' ?
              <>{ cell.endIcon }</>
              :
              <Icon>{cell.endIcon}</Icon>
          }
        </>
      }
    </div>
  )
}