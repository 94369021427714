import React from 'react'
import api from '../../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../../helper'
import FormDataPermission, { ISaveFormData } from './FormData'
import { KeyedMutator } from 'swr'
import { IPermissions } from '..'

interface IAddPermission {
  onCloseModal: () => void
  mutate: KeyedMutator<IPermissions>
}

const AddPermission = (props: IAddPermission) => {
  const {
    onCloseModal,
    mutate,
  } = props

  const handleSave = async (data: ISaveFormData) => {

    await api({
      method: 'POST',
      url: 'roles/permission',
      data
    })
      .then(({ data }) => {
        showResponseSuccess(data?.message)
        mutate()
        onCloseModal()
      })
      .catch(error => showResponseError(error))
  }

  return (
    <FormDataPermission
      onCloseModal={onCloseModal}
      onSaveForm={handleSave}
    />
  )
}

export default AddPermission