import React, { useRef, useState } from 'react'
import {
  Icon,
  IconButton,
  Input,
  InputAdornment,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  Select,
  ListItemText,
  FormControl
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledTableCell } from './commons'
import I18n from '../../I18n'

const getIconByOperator = (operator) => {
  switch (operator) {
  case 'EQUAL':
    return ['fas fa-equals']
  case 'NOT_EQUAL':
    return ['fas fa-not-equal']
  case 'LESS_THAN_EQUAL':
    return ['fas fa-less-than-equal']
  case 'LESS':
    return ['fas fa-less-than']
  case 'MORE':
    return ['fas fa-greater-than']
  case 'MORE_THAN_EQUAL':
    return ['fas fa-greater-than-equal']
  case 'NOT_LIKE':
    return ['fas fa-exclamation', 'fas fa-percent']
  case 'LIKE':
  default:
    return ['fas fa-percent']
  }
}

const options = [
  {
    icons: getIconByOperator('LIKE'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.LIKE'/>,
    operator: 'LIKE',
  },
  {
    icons: getIconByOperator('NOT_LIKE'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.NOT_LIKE'/>,
    operator: 'NOT_LIKE',
  },
  {
    icons: getIconByOperator('EQUAL'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.EQUAL'/>,
    operator: 'EQUAL',
  },
  {
    icons: getIconByOperator('NOT_EQUAL'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.NOT_EQUAL'/>,
    operator: 'NOT_EQUAL',
  },
  {
    icons: getIconByOperator('LESS_THAN_EQUAL'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.LESS_THAN_EQUAL'/>,
    operator: 'LESS_THAN_EQUAL',
  },
  {
    icons: getIconByOperator('LESS'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.LESS'/>,
    operator: 'LESS',
  },
  {
    icons: getIconByOperator('MORE'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.MORE'/>,
    operator: 'MORE',
  },
  {
    icons: getIconByOperator('MORE_THAN_EQUAL'),
    name: <I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.MORE_THAN_EQUAL'/>,
    operator: 'MORE_THAN_EQUAL',
  },
]

const WCellSearchable = props => {
  const {
    cell,
    onSearch,
    iconSerchable,
  } = props

  const [valueInput, setValueInput] = useState()
  const [operator, setOperator] = useState(cell.searchable?.operator ?? 'LIKE')
  const [open, setOpen] = useState(false)

  const anchorRef = useRef(null)

  const onSearchable = () => {
    const currentValue = (typeof valueInput === 'string' || !valueInput || !isNaN(valueInput)) ? valueInput : valueInput.join(',')

    onSearch({
      key: cell.key,
      operator,
      value: currentValue,
    })
  }

  const onClear = () => {
    setValueInput('')
    onSearch({
      key: cell.key,
      operator,
    })
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleMenuItemClick = newOperator => {
    setOperator(newOperator)
    setOpen(false)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleChange = event => {
    const {
      target: { value },
    } = event

    setValueInput((value && typeof value === 'string') ? value.split(',') : value)
  }

  if (!cell)
    return <StyledTableCell />

  return (
    <StyledTableCell
      align={cell.align || 'center'}
      className='p-0 h-10 w-52'
    >
      <div
        className={`relative flex items-center justify-${cell.align || 'center'}`}
      >
        {
          cell.searchable?.options
            ?
            <FormControl sx={{
              width: 100
            }}
            >
              <Select
                id={`select-option-${cell.key}`}
                multiple={cell.searchable.multiple}
                value={valueInput}
                onChange={handleChange}
                variant='standard'
              >
                <MenuItem
                  value={null}
                >
                  <div className='flex items-center justify-center w-full'>
                    <Tooltip disableInteractive title={<I18n path='COMPONENTS.WTABLE.HEADER.FILTERS.CLEAN'/>}>
                      <FontAwesomeIcon icon='fas fa-backspace' />
                    </Tooltip>
                  </div>
                </MenuItem>
                {
                  cell.searchable.options.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            :
            <div className='flex flex-row items-center'>
              <Icon color='#fff' fontSize='small'>{iconSerchable}</Icon>
              <ButtonGroup variant='text' ref={anchorRef} color='inherit'>
                <Button
                  size='small'
                  onClick={handleToggle}
                >
                  {
                    options.filter(option => option.operator === operator).map(option => (
                      option.icons?.map(icon => (
                        <Tooltip
                          key={icon}
                          title={option.name}
                          disableInteractive
                        >
                          <FontAwesomeIcon
                            key={icon}
                            icon={icon}
                          />
                        </Tooltip>
                      ))
                    ))
                  }
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id='split-button-menu' autoFocusItem>
                          {
                            options.map(option => (
                              <MenuItem
                                key={option.operator}
                                selected={operator === option.operator}
                                onClick={() => handleMenuItemClick(option.operator)}
                              >
                                {
                                  option.icons?.map(icon => (
                                    <Tooltip
                                      key={icon}
                                      title={option.name}
                                      disableInteractive
                                    >
                                      <FontAwesomeIcon
                                        key={icon}
                                        icon={icon}
                                      />
                                    </Tooltip>
                                  ))
                                }
                              </MenuItem>
                            ))
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <Input
                id={`${cell.key}_searchable`}
                value={valueInput}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment disablePointerEvents={!valueInput} position='end'>
                    <IconButton
                      onClick={onClear}
                    >
                      <Icon fontSize='small'>{valueInput ? 'clear' : ''}</Icon>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
        }
        <IconButton
          onClick={onSearchable}
        >
          <Icon fontSize='small'>search</Icon>
        </IconButton>
      </div>
    </StyledTableCell>
  )
}


export default WCellSearchable