import React, { useState, useEffect, useRef } from 'react'
import { Loading } from '@clinicorp/designsystem.components.basic.loadings.loading'
import { showError, showResponseError, showResponseSuccess } from '../../../../helper'
import WTable from '../../../../components/WTable'
import api from '../../../../context/apiContext'
import WModal from '../../../../components/WModal/V2'
import I18n from '../../../../components/I18n'
import useApi from '../../../../context/hooks/useApi'
import FormData from './FormData'
import WCustomModal from '../../../../components/WModal/V3'


const headerCells = [
      
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.NAME'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.MEMORY'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.RUNTIME'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.TIMEOUT'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.GENERATION'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REPOSITORY'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.REGION'/> },
  { label: <I18n path='Admin.CLOUD_FUNCTIONS.MAX_INSTANCES'/> }
]

const CloudFunctions = () => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [showDelCloudFunctionModal, setShowDelCloudFunctionModal] = useState(false)
  const [showCreateCloudFunctionModal, setShowCreateCloudFunctionModal] = useState(false)
  const [showEditCloudFunctionModal, setShowEditCloudFunctionModal] = useState(false)
  const [cloudFunctions, setCloudFunctions] = useState([])
  const [cloudFunctionsSelected, setCloudFunctionsSelected] = useState([])
  const [cloudFunctionById, setCloudFunctionById] = useState()
  const WtableRef = useRef()

  const { data: dataCloudFunction, isLoading: isLoadingCloudFunction, mutate: mutateCloudFunctions } = useApi({ url: 'cloud-function' })

  useEffect(() => {
    if (dataCloudFunction) {
      const newCloudFunctions = dataCloudFunction.data?.map(i => (
        { cells: [
          { id: i.id, hidden: true},
          { label: i.name },
          { label: i.memory},
          { label: i.runtime },
          { label: i.timeout },
          { label: i.gen },
          { label: i.repository?.name },
          { label: i.region },
          { label: i.maxInstances }
        ]}
      ))

      setCloudFunctions(newCloudFunctions ?? [{ cells: [] }])
    }

  }, [dataCloudFunction])

  const getCloudFunctionsSelected = value => {
    setCloudFunctionsSelected(value)
    setCloudFunctionById(value[0]?.cells[0]?.id)
  }

  const handleCloseModal = () => {
    WtableRef.current?.clearCheckedValues()
    setShowDelCloudFunctionModal(false)
    setShowCreateCloudFunctionModal(false)
    setShowEditCloudFunctionModal(false)
  }

  const handleDelCloudFunctions = async () => {
    const ids = WtableRef.current.getIdCheckedValues().join(',')

    if (!ids?.length) {
      showError('Admin.ENVIRONMENTS.CLOUD_FUNCTION_NOT_SELECTED')
      return
    }

    setIsLoadingModal(true)
    await api({
      method: 'DELETE',
      url: 'cloud-function',
      params: {
        ids: ids
      }
    }).then(async ({ data }) => {
      mutateCloudFunctions()
      showResponseSuccess(data?.message)
    }).catch(error => showResponseError(error))
      .finally(() => {
        handleCloseModal()
        setIsLoadingModal(false)
      })
  }

  const buttons = [
    {
      type: 'icon',
      icon: 'add',
      onClick: () => {
        setCloudFunctionById('')
        setShowCreateCloudFunctionModal(true)
      },
      tooltipLabel: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.ADD' />
    },
    {
      type: 'icon',
      icon: 'edit',
      onClick: () => setShowEditCloudFunctionModal(true),
      disabled: cloudFunctionsSelected.length !== 1,
      tooltipLabel: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.EDIT' />
    },
    {
      type: 'icon',
      icon: 'delete',
      onClick: () => setShowDelCloudFunctionModal(true),
      disabled: !cloudFunctionsSelected.length,
      tooltipLabel: <I18n path='Admin.CLOUD_FUNCTIONS.TABLE.DELETE' />
    }
  ]

  return (
    <div className='flex flex-col p-1 m-0 h-screen'>
      {isLoadingCloudFunction && <Loading />}
      {showDelCloudFunctionModal &&
        <WCustomModal
          buttonLabel={'Remove'}
          title={<I18n path='Admin.CLOUD_FUNCTIONS.REMOVE_CLOUD_FUNCTION'/>}
          open={showDelCloudFunctionModal}
          handleClose={handleCloseModal}
          buttonOnConfirm={handleDelCloudFunctions}
          icon={'delete'}
          element={
            <div className='text-white'>
              <p>
                Cloud Function: <span>{cloudFunctionsSelected?.[0].cells[1].label}</span>
              </p>
            </div>
          }
        />
      }
      {
        (showCreateCloudFunctionModal || showEditCloudFunctionModal) &&
        <WModal
          openModal={(showCreateCloudFunctionModal || showEditCloudFunctionModal)}
          onCloseModal={handleCloseModal}
          style={{ backdropFilter: 'blur(5px)' }}
        >
          <FormData
            onSave={handleCloseModal}
            id={cloudFunctionById}
            onClose={handleCloseModal}
            isloading={isLoadingModal}
          />
        </WModal>
      }

      <div className='p-0 m-0 row 2xl:w-full xl:w-[850px]'>
        <WTable
          ref={WtableRef}
          fullWidth={true}
          tableHeader={true}
          checkboxAllRows={true}
          checkboxRows
          headerCells={headerCells}
          tableFooter={false}
          footerPagination={false}
          rows={cloudFunctions}
          getCheckedValues={getCloudFunctionsSelected}
          topMenu
          buttonsTopMenu={buttons}
        />
      </div>
    </div>
  )
}

export default CloudFunctions