import React from 'react'
import api from '../../../context/apiContext'
import { showResponseError, showResponseSuccess } from '../../../helper'
import I18n from '../../../components/I18n'
import WCustomModal from '../../../components/WModal/V3'

const modalPromoteVersion = (props) => {
  const {
    pipelineId,
    openModal,
    isLoading,
    loading,
    onCloseModal,
    reloadBuilds
  } = props

  const promoteVersion = async () => {
    loading(true)

    await api({
      method: 'POST',
      url: 'pipelines/promote',
      data: {
        pipelineId
      }
    }).then(({ data }) => {
      showResponseSuccess(data?.message)
      reloadBuilds()
    }).catch(error => showResponseError(error))
      .finally(() => {
        loading(false)
        onCloseModal()
      })
  }

  return (
    <WCustomModal
      buttonLabel='Confirm'
      open={openModal}
      handleClose={onCloseModal}
      buttonOnConfirm={promoteVersion}
      icon={'check_circle'}
      alignModal={'start'}
      loading={isLoading}
      element={
        <div className='container text-white'>
          <div className='p-2'>
            <I18n path='Build.CONFIRM_PROMOTE_VERSION'/>
          </div>
        </div>
      }
    />
  )
}

export default modalPromoteVersion