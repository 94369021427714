import React from 'react'
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  styled,
  DialogTitle,
  Icon
} from '@mui/material'
import WButton from '../../WButton'
import WIconButton from '../../WButton/Icon'

interface IWCustomModal extends Omit<DialogProps, 'title'> {
  title?: string | JSX.Element
  open: boolean
  handleClose: () => void
  buttonOnConfirm?: () => void
  element: JSX.Element
  width?: string
  buttonLabel?: string | JSX.Element
  icon?: string
  alignModal?: string
  loading?: boolean
  disabled?: boolean
  disableEscapeKeyDown?: boolean
}

const DialogStyled = styled(Dialog)(() => ({
  '.MuiDialog-container': {
    display: 'flex',
    alignItems: 'center'
  },
  '.MuiDialog-paper': {
    backgroundColor: 'var(--NewGreyBlack)',
  }
}),
)

const WCustomModal = (props: IWCustomModal ) => {

  return (
    <DialogStyled
      scroll='paper'
      maxWidth='lg'
      open={props.open}
      onClose={props.handleClose}
      className='flex justify-center backdrop-blur-[2px]'
      disableEscapeKeyDown={props.disableEscapeKeyDown}
    >
      <div className='m-0 p-0'>
        <div className='flex justify-between'>
          <div className='p-0'>
            <DialogTitle className='text-white text-xl'>
              {props.title}
            </DialogTitle>
          </div>
          <div>
            <WIconButton icon='close' iconColor='white' onClick={props.handleClose}/>
          </div>
        </div>
        <DialogContent className='flex justify-center items-center min-w-full min-h-full p-4 m-0'>
          {props.element}
        </DialogContent>
        <DialogActions className='flex w-full mt-auto px-4 pb-4'>
          {props.buttonLabel && (
            <WButton
              label={props.buttonLabel}
              onClick={props.buttonOnConfirm}
              startIcon={<Icon fontSize='small'>{props.icon}</Icon>}
              loading={props.loading}
              disabled={props.disabled}
            />
          )}
        </DialogActions>
      </div>

    </DialogStyled>
  )
}

export default WCustomModal
