import React, { useState, useEffect } from 'react'
import api from '../../../../context/apiContext'
import WSelectFieldMulti from '../../../../components/WSelectFieldMulti'
import { showError, showResponseError, showResponseSuccess } from '../../../../helper'
import I18n from '../../../../components/I18n'
import useApi from '../../../../context/hooks/useApi'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

const ModalEditUser = props => {
  const {
    title,
    ids,
    handleCloseModal,
    environments,
    roles,
    openModal
  } = props

  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedEnvironments, setSelectedEnvironments] = useState([])
  const [email, setEmail] = useState('')

  const { data: userData, isLoading: loadingUserData } = useApi({ url: `users/${ids[0]}` })

  useEffect(() => {
    if (userData) {
      const userEnvironments = userData.environments?.map(i => ({ value: i.id, label: i.name}))
      const userRoles = userData.roles?.map(i => ({ value: i.id, label: i.name}))
      setSelectedEnvironments(userEnvironments)
      setSelectedRoles(userRoles)
      setEmail(userData.email)
    }
  }, [userData])

  const handleEditUser = () => {
    if (!ids.length)
      showError('Admin.USERS.NO_USER_IDENTIFIED')
    else {
      const data = {
        roles: selectedRoles.map(i => ({ id: i.value })),
        environments: selectedEnvironments.map(i => ({ id: i.value }))
      }

      api({
        method: 'PATCH',
        url: `users/${ids[0]}`,
        data
      }).then(async ({ data }) => {
        showResponseSuccess(data?.message)
        const isGetUsers = true
        await handleCloseModal(isGetUsers)
      }).catch(error => showResponseError(error))
    }
  }

  return (
    <WCustomModal
      buttonLabel={'Save'}
      title={title}
      open={openModal}
      handleClose={handleCloseModal}
      buttonOnConfirm={handleEditUser}
      icon={'edit'}
      loading={loadingUserData}
      element={
        <div className='flex flex-col justify-center w-96 h-96'>
          <div className='flex flex-col gap-y-10'>
            <div className='w-full'>
              <WTextFieldV2
                label={'email'}
                value={email}
                disabled={false}
                variant='standard'
              />
            </div>
            <div className='w-full'>
              <WSelectFieldMulti
                label='Role'
                required
                value={selectedRoles}
                onChange={ev => setSelectedRoles(ev)}
                options={roles}
                errorText={!selectedRoles?.length ? <I18n path='Global.REQUIRED_FIELD'/> : ''}
                className='w-full'
              />
            </div>
            <div className='w-full'>
              <WSelectFieldMulti
                label={<><I18n path='Admin.USERS.ENVIRONMENTS'/></>}
                placeholder=''
                options={environments}
                value={selectedEnvironments}
                onChange={(ev) => setSelectedEnvironments(ev)}
                className='w-full'
              />
            </div>
          </div>
        </div>
      }
    />
  )
}

export default ModalEditUser