import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import WSelectFieldV2 from '../WSelectField/V2'
import WTextFieldV2 from '../WTextField/V2'
import { Icon, Tooltip } from '@mui/material'
import WButton from '../WButton'
import I18n from '../I18n'
import { CONSTANTS } from '../../js/res/CONSTANTS'
import WIconButton from '../WButton/Icon'

const Accordion = ((props) => (
  <MuiAccordion
    disableGutters
    expanded
    elevation={0}
    square
    className='border-[1px] border-orange text-white my-0.5'
    {...props} />
))

const AccordionSummary = ((props) => (
  <MuiAccordionSummary
    className='flex-row-reverse m-0 bg-grey'
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'white', rotate: '90deg' }} />}
    {...props}
  />
))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))


const AccordionSecret = (props) => {
  const {
    secrets,
    expanded,
    handleChange,
    onSliceSecret,
    id,
    selected,
    envVar,
    envValue,
    oldListSecrets,
    envId,
    onDone,
  } = props

  const defaultValueList = { value: 'latest', label: 'latest' }

  const [selectedSecret, setSelectedSecret] = useState()
  const [listSecrets, setListSecret] = useState([])
  const [listVersions, setListVersions] = useState([])
  const [env, setEnv] = useState(envVar)
  const [version, setVersion] = useState({})
  const [requireSave, setRequireSave] = useState(false)
  const [contentError, setContentError] = useState([])

  useEffect(() => {
    if (secrets) {
      const newList = secrets.map(i => ({ value: i.id, label: i.name }))
      setListSecret(newList)
    }
  }, [secrets])

  useEffect(()=> {
    const errors = [
      ...!env?.length ? ['COMPONENTS.WSECRETS.ERRORS.ENV_REQUIRED'] : [],
      ...!version?.value ? ['COMPONENTS.WSECRETS.ERRORS.VERSION_REQUIRED']: [],
      ...!selectedSecret?.value ? ['COMPONENTS.WSECRETS.ERRORS.SECRET_REQUIRED'] : '',
    ]

    setContentError(errors)
  }, [expanded, env, version, selectedSecret])

  useEffect(() => {
    if (selected && listSecrets) {
      setSelectedSecret(listSecrets.find(i => i.value === selected))
    }
  }, [selected, listSecrets])

  useEffect(() => {
    const newListVersions = secrets?.find(i => i.id === selectedSecret?.value)?.versions?.map(i => ({ value: i.version, label: i.version })).concat(defaultValueList) ?? [defaultValueList]
    setListVersions(newListVersions)
  }, [selectedSecret])

  useEffect(() => {
    setVersion(listVersions.find(i => i.value === envValue) ?? defaultValueList)
  }, [envValue])

  useEffect(() => {
    const oldObj = oldListSecrets.find(i => i.id === envId)

    if (oldListSecrets) {
      const checkRequiredSave = (oldObj?.env !== env || oldObj?.secrets?.id !== selectedSecret?.value || oldObj?.value !== version.value)
      setRequireSave(checkRequiredSave)
    }

  }, [oldListSecrets, selectedSecret, expanded])

  const handleDone = () => {
    const data = {
      id: envId,
      env,
      value: version?.value,
      secrets: {
        id: selectedSecret?.value
      }
    }

    onDone(data)
  }

  const isNewEnv = !oldListSecrets.some(i => i.id === envId)

  return (
    <div>
      <Accordion expanded={expanded === `panel-${id}`} onChange={handleChange(`panel-${id}`)}>
        <AccordionSummary id={`panel-${id}d-header`}
        >

          <Typography
            className='w-full'
          >
            {
              expanded === `panel-${id}` ?
                isNewEnv ? <I18n path='COMPONENTS.WSECRETS.ACCORDION_EXPANDED.NEW'/> : <I18n path='COMPONENTS.WSECRETS.ACCORDION_EXPANDED.EDIT'/>
                :
                <div className=' w-full flex justify-between'>
                  {
                    !!contentError.length &&
                    <Tooltip
                      title={
                        contentError.map((i, index) => (<><I18n key={`error-${index}`} path={i} /><br/></>))
                      }
                    >
                      <Icon
                        sx={{ color: CONSTANTS.Style.RedStatus }}
                      >
                        error
                      </Icon>
                    </Tooltip>
                  }
                  <div className='ml-2'>
                    {selectedSecret?.label}
                  </div>
                  <div>
                    {
                      requireSave ?
                        <p className='text-sm'>(<I18n path='COMPONENTS.WSECRETS.NOT_SAVED'/>)</p>
                        :
                        <></>
                    }
                  </div>
                  <WIconButton
                    className=' p-0 m-0'
                    onClick={onSliceSecret}
                    icon='delete'
                    iconColor='#a73804'
                    size='small'
                  />
                </div>
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className='bg-grey'
        >
          <Typography>
            <WSelectFieldV2
              label={<I18n path='COMPONENTS.WSECRETS.LABEL.SECRET'/>}
              value={selectedSecret}
              options={listSecrets}
              onChange={ev => setSelectedSecret(ev)}
              className='border-b border-transparent border-b-[#a73804] text-white mt-[5%]'
              variant='standard'
            />
            <div className='grid grid-cols-2 gap-2 items-center mt-2'>
              <WTextFieldV2
                label={<I18n path='COMPONENTS.WSECRETS.LABEL.NAME'/>}
                value={env}
                onChange={ev => setEnv(ev.target.value)}
                variant='standard'
              />
              <WSelectFieldV2
                label={<I18n path='COMPONENTS.WSECRETS.LABEL.VERSION'/>}
                className='border-b border-transparent border-b-[#a73804] text-white'
                value={version}
                options={listVersions}
                onChange={ev => setVersion(ev)}
                variant='standard'
              />
            </div>
          </Typography>
        </AccordionDetails>
        <WButton
          className='w-full rounded-none'
          label={<I18n path='COMPONENTS.WSECRETS.LABEL.DONE'/>}
          onClick={handleDone}
        />
      </Accordion>
    </div>
  )
}

export default AccordionSecret