import React from 'react'

const Application = () => {
  return (
    <div className='mx-[2rem] h-full w-full flex lg:flex-nowrap flex-wrap space-x-4'>

    </div>
  )
}

export default Application
