import React, { useState, useContext, useEffect } from 'react'
import Icon from '@mui/material/Icon'
import { FaTooth } from 'react-icons/fa'
import { routes } from '../../../pages/routes'
import { Link } from 'react-router-dom'
import I18n from '../../I18n'
import Location from '../../../routes/Location'
import { Context } from '../../../context/authContext'
import { Tooltip } from '@clinicorp/designsystem.components.basic.tooltips.tooltip'
import './style.scss'
import { CONSTANTS } from '../../../js/res/CONSTANTS'
const { version } = CONSTANTS


const WSidebar = () => {
  const cardsHome = routes.home.subPaths

  const { handleChangeProject, currentProject, allowedProjects, permissions } = useContext(Context)
  const [projects, setProjects] = useState([])

  useEffect(() => {
    if (allowedProjects) {
      const newProjects = allowedProjects?.map(i => ({ value: i.id, label: i.name, icon: i.icon }))
      setProjects(newProjects)
    }

  }, [allowedProjects])

  const onChangeProject = project => handleChangeProject(project.value)

  return (
    <div className='flex flex-row fixed h-screen w-54 top-0 left-0 xl:w-70'>
      <div className='flex flex-row'>
        <div className='menu  justify-between flex flex-col align-items-center mt-[3.125rem] bg-[var(--GreyBlack)] sidebar-body-first'>
          <ul className='flex-col justify-center my-0 list-item'>
            <li className='w-40 text-base font-bold xl:w-56'>
              <Link
                to={{ pathname: routes.home.getRoute() }}
                className='grid grid-cols-3 h-12 border-b-[1px] border-[#ffffff0c] text-[var(--White)]  hover:text-[var(--NewOrangeClinicorp)]'
                style={{ justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' }}
              >
                <div className='flex justify-center items-center'>
                  <Icon>{routes.home.icon}</Icon>
                </div>
                <div className='col-span-2 flex items-center'>
                  <span >
                    <I18n path={`Routes.${routes.home.name}`} />
                  </span>
                </div>
              </Link>
            </li>

            {Object.keys(cardsHome).filter(key => cardsHome[key].sideBar).map((key, index) => {
              const contentPermission = cardsHome[key].permissions?.some(i => permissions?.includes(i))
              if (!contentPermission)
                return
              return (
                <li key={index} className='w-40 text-base font-bold xl:w-56'>
                  <Link
                    style={{boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'}}
                    id={index}
                    to={{ pathname: cardsHome[key].getRoute() }}
                    className={
                      `${Location().pathname.startsWith(cardsHome[key].path)
                        ?
                        'text-[var(--NewOrangeClinicorp)] shadow hover:shadow-inner'
                        :
                        'text-[var(--White)]'}
                           transition grid grid-cols-3 h-12 border-b-[1px] border-[#ffffff0c] hover:text-[var(--NewOrangeClinicorp)] cursor-pointer`
                    }
                  >
                    <div className='flex justify-center items-center'>
                      <Icon>{cardsHome[key].icon}</Icon>
                    </div>
                    <div className='col-span-2 flex items-center'>
                      <span>
                        <I18n path={`Routes.${cardsHome[key].name}`} />
                      </span>
                    </div>
                  </Link>
                </li>
              )
            })}

          </ul>
          <div className='w-full ml-5'>
            <Tooltip
              tooltip={version}
              className='text-base'
              tooltipStyle={{ fontSize: '12px' }}
            >
              <Icon style={{ color: '#fc673d' }} fontSize='small' className='text-[var(--NewOrangeClinicorp)] h-auto'>cloud_circle</Icon>
            </Tooltip>
          </div>
        </div>

        <div className='flex flex-col bg-[var(--NewOrangeClinicorp)] mt-[3.125rem] h-screen align-middle'>
          <div className='flex-column h-[60px] w-[40px]'></div>
          <ul className='flex-column m-1 h-auto w-auto'>
            {Object.keys(projects).filter(key => projects[key]).map(key => {
              return (
                <li key={projects[key].value} className='m-1 h-auto w-auto my-5'>
                  <Tooltip
                    tooltip={projects[key].label}
                    tooltipStyle={{ fontSize: '12px' }}
                  >
                    <Link
                      id={projects[key].value}
                      className={
                        `${currentProject === projects[key].value ? 'text-[var(--White)]' : 'text-[var(--GreyBlack)]'} 
                        flex py-2 justify-center align-middle transition hover:scale-110 hover:delay-100`
                      }
                      onClick={() => onChangeProject(projects[key])}
                    >
                      <div className='flex'>
                        {projects[key].icon ?
                          <Icon >{projects[key].icon}</Icon>
                          :
                          <FaTooth style={{ height: '22px', width: '22px' }} />
                        }
                      </div>
                    </Link>
                  </Tooltip>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WSidebar