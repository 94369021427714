import React, { useEffect, useState } from 'react'
import I18n from '../../../../components/I18n'
import WCustomModal from '../../../../components/WModal/V3'
import WTextFieldV2 from '../../../../components/WTextField/V2'

export interface ISaveFormData {
  permission: string
}

export interface IPermission extends ISaveFormData {
  id: string
}

interface IPermissionProps {
  permission?: IPermission
  onCloseModal: () => void
  onSaveForm: (props: ISaveFormData) => Promise<void>
}

const FormDataPermission = (props: IPermissionProps) => {
  const {
    permission,
    onCloseModal,
    onSaveForm,
  } = props

  const [assignedPermission, setAssignedPermisison] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!permission)
      return

    setAssignedPermisison(permission?.permission)
  }, [permission])

  const onChangeAssignedPermission = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setAssignedPermisison(value)
  }

  const handleSave = async () => {
    setErrorName(!assignedPermission.length)

    if (!assignedPermission.length)
      return

    setIsLoading(true)

    const data ={
      permission: assignedPermission,
    }

    onSaveForm(data)
      .finally(() => setIsLoading(false))
  }

  return (
    <div>
      <WCustomModal
        buttonLabel={permission ?
          <I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.EDIT.BTN_SAVE' />
          :
          <I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.ADD.BTN_SAVE' />}
        title={permission ?
          <I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.EDIT.TITLE' />
          :
          <I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.ADD.TITLE' />}
        open={true}
        handleClose={onCloseModal}
        buttonOnConfirm={handleSave}
        icon={permission ? 'edit' : 'check_circle'}
        loading={isLoading}
        disabled={false}
        element={
          <div className='flex flex-col w-96 h-52 p-2 justify-center'>
            <WTextFieldV2
              label={<I18n path='Application.ASSIGNED_PERMISSIONS.MODAL.PERMISSION'/>}
              value={assignedPermission}
              error={errorName}
              helperText={<I18n path='Global.REQUIRED_FIELD'/>}
              required
              onChange={onChangeAssignedPermission}
              variant='standard'
            />
          </div>
        }
      />
    </div>
  )
}

export default FormDataPermission