import React from 'react'
import {
  Dialog,
  DialogContent
} from '@mui/material'

const WDialog = (props) =>  {
  const {
    onCloseModal,
    open,
    children
  } = props

  const handleClose = () => {
    if (typeof onCloseModal === 'function')
      onCloseModal()
  }
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      className='bg-none rounded-none'
      fullScreen={false}
      fullWidth={true}
      maxWidth={'sm'}
      style={{ backdropFilter: 'blur(2px)'}}
      PaperProps={{ style:{borderRadius: '10px', backgroundColor: '#111111', width: '35%'}}}
    >
      <DialogContent style={{minHeight:'39rem'}}>
        {
          children
        }
      </DialogContent>
    </Dialog>
  )
}

export default WDialog